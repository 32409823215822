import {
  Text,
  Box,
  Card,
  HStack,
  Button,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";

import {
  FiChevronDown,
  FiChevronUp,
  FiCloudLightning,
  FiRefreshCcw,
} from "react-icons/fi";
import {
  useRefreshSummaryMutation,
  useSummarizeQuestionsMutation,
} from "../../store/services/mediaApi";

const OpenEnded = ({ question, answers, mediaItemdId, mediaItemData }) => {
  const { isOpen, onOpen, onToggle } = useDisclosure();
  const [postOpenEndSummary, res] = useSummarizeQuestionsMutation();
  const [refreshSummary, sumRefresh] = useRefreshSummaryMutation();

  return (
    <Box>
      <HStack
        py="3"
        fontSize="sm"
        fontWeight="medium"
        justifyContent="space-between"
      >
        <Text cursor="pointer" onClick={() => onToggle()}>
          {question}
        </Text>

        <HStack>
          <IconButton
            color="purple.400"
            size="xs"
            variant="outline"
            icon={<FiRefreshCcw />}
            isLoading={sumRefresh?.isLoading}
            onClick={() =>
              refreshSummary({
                value: "open-ended",
                question: question,
                responses: answers,
                media_item_id: mediaItemdId,
                title: mediaItemData?.title,
              })
            }
          />
          <Button
            leftIcon={<FiCloudLightning />}
            color="purple.400"
            size="xs"
            variant="outline"
            onClick={() =>
              postOpenEndSummary({
                question: question,
                responses: answers,
                media_item_id: mediaItemdId,
                title: mediaItemData?.title,
              })
            }
            isLoading={res?.isLoading}
          >
            Summarize
          </Button>

          <IconButton
            icon={isOpen ? <FiChevronUp /> : <FiChevronDown />}
            onClick={() => onToggle()}
            variant="outline"
          />
        </HStack>
      </HStack>

      {!res?.isLoading && res?.data && (
        <Card p="4">
          <HStack fontSize="sm" color="purple.400" mb="3">
            <FiCloudLightning />
            <Text>Generative Summary</Text>
          </HStack>
          <Text fontSize="xs">{sumRefresh?.data?.data || res?.data?.data}</Text>
        </Card>
      )}

      {isOpen && (
        <Box>
          {answers?.map((ithComment) => (
            <Text my="2" fontSize="xs">
              {ithComment}
            </Text>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default OpenEnded;

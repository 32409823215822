import { Box, Flex, HStack } from "@chakra-ui/react";
import {
  Property,
  PropertyLabel,
  PropertyList,
  PropertyValue,
  useFormContext,
} from "@saas-ui/react";
import { QuestionDisplay } from "./SurveyForm";
import ReactPlayer from "react-player";
import { useRef } from "react";

const ConfirmDetails = () => {
  const { watch } = useFormContext();

  const scenes = watch("scenes");
  const video_id = watch("yotube_video_id");

  const playerRef = useRef(null);

  return (
    <HStack
      alignItems="flex-start"
      flex="1"
      bg="white"
      borderRadius="md"
      shadow="sm"
      border={"1px solid"}
      borderColor={"gray.200"}
      spacing="5"
    >
      <Box p="4">
        <PropertyList>
          <Property>
            <PropertyLabel>Scenes</PropertyLabel>
            <PropertyValue>{scenes?.length}</PropertyValue>
          </Property>
          <Property>
            <PropertyLabel>Video ID</PropertyLabel>
            <PropertyValue>{video_id?.value}</PropertyValue>
          </Property>
        </PropertyList>
      </Box>
      <Box flex={1}>
        <ReactPlayer
          ref={playerRef}
          url={`https://www.youtube.com/watch?v=${video_id?.value || video_id}`}
          width="100%"
        />
        <QuestionDisplay />
      </Box>
    </HStack>
  );
};

export default ConfirmDetails;

import React from "react";
import {
  Box,
  Radio,
  RadioGroup,
  Text,
  useRadio,
  useRadioGroup,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { FaFilm, FaYoutube, FaImdb, FaPhotoVideo } from "react-icons/fa";
import { useFormContext, useController } from "react-hook-form";
import { DisplayIf, Field, FormLayout } from "@saas-ui/react";
import ImdbSearchField from "./ImdbSearchField";

// Custom Radio Card Component
export function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        color="red.900"
        _checked={{
          borderColor: "red.500",
          color: "red.500",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        <Box bg="red.50" p="4" borderRadius="lg">
          <Icon as={props.icon} w={6} h={6} mr={2} />
        </Box>
        <Text mt="3" display="inline-block">
          {props.children}
        </Text>
      </Box>
    </Box>
  );
}

// Main Component with React Hook Form
export function MediaOptions() {
  const { control } = useFormContext();

  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name: "media_type", // Name of the field
    control,
  });

  const options = [
    { value: "custom_media", label: "Custom Media", icon: FaPhotoVideo },
    { value: "imdb_media", label: "IMDb Media", icon: FaImdb },
    { value: "youtube_channel", label: "YouTube Channel", icon: FaYoutube },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "mediaType",
    onChange, // Connect the RadioGroup to React Hook Form
    value,
  });

  const group = getRootProps();

  return (
    <Box>
      <RadioGroup name="mediaType" ref={ref} value={value} onChange={onChange}>
        <HStack {...group}>
          {options.map((option) => (
            <RadioCard
              key={option.value}
              {...getRadioProps({ value: option.value })}
              icon={option.icon}
            >
              {option.label}
            </RadioCard>
          ))}
        </HStack>
      </RadioGroup>
      <DisplayIf
        name="media_type"
        condition={(value) => value === "custom_media"}
      >
        <Field
          name="title"
          type="text"
          label="Title"
          placeholder="Interstellar"
          isRequired
        />
      </DisplayIf>
      <DisplayIf
        name="media_type"
        condition={(value) => value === "imdb_media"}
      >
        <ImdbSearchField />
      </DisplayIf>
      <DisplayIf
        name="media_type"
        condition={(value) => value === "youtube_channel"}
      >
        <Field
          name="media_name"
          type="text"
          label="Project Title"
          placeholder="How to become a millionaire video"
          isRequired
        />
        <Field
          name="youtube_channel_url"
          type="text"
          label="Youtube Channel URL"
          placeholder="https://www.youtube.com/@channelName"
          isRequired
        />
      </DisplayIf>

      <DisplayIf
        name="media_type"
        condition={(value) =>
          value === "youtube_channel" || value === "custom_media"
        }
      >
        <FormLayout>
          <HStack mt="2">
            <Field
              name="genres"
              type="select"
              label="Genres"
              multiple
              options={[
                { value: 2, label: "action" },
                { value: 12, label: "adventure" },
                { value: 22, label: "animation" },
                { value: 32, label: "biography" },
                { value: 42, label: "comedy" },
                { value: 52, label: "crime" },
                { value: 62, label: "documentary" },
                { value: 72, label: "drama" },
                { value: 82, label: "family" },
                { value: 92, label: "fantasy" },
                { value: 102, label: "film-noir" },
                { value: 112, label: "history" },
                { value: 122, label: "horror" },
                { value: 132, label: "music" },
                { value: 142, label: "musical" },
                { value: 152, label: "mystery" },
                { value: 162, label: "romance" },
                { value: 172, label: "sci-fi" },
                { value: 182, label: "sport" },
                { value: 192, label: "thriller" },
                { value: 202, label: "war" },
                { value: 212, label: "western" },
                { value: 465, label: "reality" },
              ]}
            />

            <Field
              name="media_meta_type"
              type="select"
              label="Media Type"
              isRequired
              options={["TV Show", "Movie", "MiniSeries", "Other"]}
            />
          </HStack>
        </FormLayout>
      </DisplayIf>
    </Box>
  );
}

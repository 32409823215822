import {
  Controller,
  LoadingSpinner,
  useController,
  useFieldArray,
  useFormContext,
  Field,
  Banner,
  Select,
  SelectList,
  SelectButton,
  useSnackbar,
} from "@saas-ui/react";
import { RadioCard } from "./IconSelector";
import { FaFileAlt } from "react-icons/fa";
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  Textarea,
  useRadioGroup,
} from "@chakra-ui/react";
import {
  useGetAiSurveyTemplateMutation,
  useGetSurveyTemplatesQuery,
} from "../../store/services/mediaApi";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

const AICreator = (props) => {
  const { selectedTemplate, setSelectedTemplate, replace, append } = props;
  const snack = useSnackbar();
  const [prompt, setPrompt] = useState("");
  const [postPrompt, { data, isLoading, error, isError, isSuccess }] =
    useGetAiSurveyTemplateMutation();

  useEffect(() => {
    if (data) {
      setSelectedTemplate({ name: "AI Survey", template: data });
      data?.forEach((question) => {
        append({
          ...question,
          options: question?.options?.map((option) => ({
            option: option?.message,
          })),
        });
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      console.log(error);
      snack({
        title: "Error",
        description: error?.message || "Error generating survey.",
        status: "error",
      });
    }
  }, [isError]);

  const submit = async () => {
    const values = {
      prompt: prompt,
    };

    await postPrompt(values);
  };

  return (
    <Box>
      <Banner my="2" status="warning" borderRadius="md">
        Note: AI Survey is currently in Beta. Please use it with caution.
      </Banner>
      <Textarea
        onChange={(e) => setPrompt(e.target.value)}
        value={prompt}
        label="Instructions"
        name="instructions"
        type="textarea"
        placeholder="Enter instructions for the survey"
      />
      <HStack my="2" alignContent={"end"}>
        <Button
          variant="outline"
          onClick={() => submit()}
          isLoading={isLoading}
        >
          Generate Survey
        </Button>
      </HStack>
    </Box>
  );
};

const SurveyTemplateOptions = (props) => {
  const { selectedTemplate, setSelectedTemplate, replace, append } = props;
  const { client } = useParams();
  const { watch } = useFormContext();
  const survey_type = watch("survey_selection_type");

  const { data: template_options, isLoading } = useGetSurveyTemplatesQuery(
    client,
    { skip: survey_type !== "template_survey" }
  );

  const handleTemplateChange = (value) => {
    replace([]);

    const localSelectedTemplate = template_options?.find(
      (template) => template.name === value
    );

    setSelectedTemplate(localSelectedTemplate);

    localSelectedTemplate?.template?.forEach((question) => {
      append({
        ...question,
        options: question?.options?.map((option) => ({
          option: option?.message,
        })),
      });
    });
  };

  return (
    <Box my={4}>
      {isLoading ? (
        <Flex>
          <LoadingSpinner />
        </Flex>
      ) : (
        <Select
          type="select"
          label="Template"
          onChange={(val) => handleTemplateChange(val)}
          value={selectedTemplate?.name}
          options={template_options?.map((option) => ({
            value: option.name,
            label: option.name,
          }))}
        >
          <SelectList />
          <SelectButton />
        </Select>
      )}
    </Box>
  );
};

const SurveySelector = (props) => {
  const { control, watch, setValue } = useFormContext();

  const {
    field: { onChange, value: survey_value_type, ref },
  } = useController({
    name: "survey_selection_type",
    control,
  });

  const options = [
    { value: "custom_survey", label: "Custom Survey", icon: FaFileAlt },
    { value: "ai_survey", label: "AI Survey", icon: FaFileAlt },
    { value: "template_survey", label: "Template Survey", icon: FaFileAlt },
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "surveyType",
    onChange, // Connect the RadioGroup to React Hook Form
    survey_value_type,
  });

  return (
    <Box
      bg="white"
      p={4}
      borderRadius="md"
      boxShadow="md"
      border="1px solid"
      borderColor="gray.200"
    >
      <HStack justifyContent="space-between" mb="4">
        <Text fontWeight="bold">Select Survey Type</Text>
      </HStack>

      <HStack {...getRootProps()}>
        {options.map((option) => (
          <RadioCard
            key={option.value}
            {...getRadioProps({ value: option.value })}
            icon={option.icon}
          >
            {option.label}
          </RadioCard>
        ))}
      </HStack>

      {survey_value_type === "ai_survey" && <AICreator {...props} />}

      {survey_value_type === "template_survey" && (
        <SurveyTemplateOptions {...props} />
      )}
    </Box>
  );
};
export default SurveySelector;

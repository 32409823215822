import {
  Text,
  Box,
  Card,
  Heading,
  Flex,
  HStack,
  useToken,
  CardHeader,
  VStack,
} from "@chakra-ui/react";
import {
  useGetMediaEmotionQuery,
  useGetMediaEmotionTimeseriesQuery,
} from "../../../store/services/mediaApi.js";
import { useParams } from "react-router";
import { EmptyState, ErrorBoundary, LoadingSpinner } from "@saas-ui/react";
import { FiAlertCircle } from "react-icons/fi";

import {
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

import { useMemo, useRef, useState } from "react";
import FeatureFlag from "../../util/FeatureFlag.js";
import { EMOTION_COLOR_MAP, NetEmotions } from "./Emotion.js";
import ReactPlayer from "react-player";

export const CustomTooltip = ({ active, payload, label }) => {
  const sortedPayload = useMemo(() => {
    return [...payload]?.sort((a, b) => b.value - a.value) || [];
  }, [payload]);
  if (active && payload && payload.length) {
    return (
      <Box
        zIndex={99999999}
        bg="whiteAlpha.800"
        boxShadow="md"
        border="1px solid"
        borderColor="gray.300"
        p="2"
      >
        <Text fontWeight="bold">{`Timestamp ${label} `}</Text>
        <VStack>
          {sortedPayload.map((pld) => (
            <HStack fontSize="xs">
              <Box
                borderRadius="full"
                height="2"
                width="2"
                bg={EMOTION_COLOR_MAP?.[pld?.dataKey]}
              ></Box>
              <Text color="gray.600">{pld.dataKey}</Text>
              <Text style={{ color: pld.fill }}>{pld.value}</Text>
            </HStack>
          ))}
        </VStack>
      </Box>
    );
  }

  return null;
};

const Gradient = ({ color, name }) => {
  return (
    <linearGradient id={`color${name}`} x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor={color} stopOpacity={0.8} />
      <stop offset="95%" stopColor={color} stopOpacity={0} />
    </linearGradient>
  );
};

const Video = ({ mediaItemData }) => {
  const { id } = useParams();
  const player = useRef();
  const { data, isLoading } = useGetMediaEmotionTimeseriesQuery(id);

  const { data: emotionality, isLoading: emotionalityLoading } =
    useGetMediaEmotionQuery({ value: id, norm: "default" });

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const indexTime = useMemo(() => {
    if (currentTime < duration) {
      return Math.ceil(currentTime);
    } else {
      return duration;
    }
  }, [currentTime, duration]);

  const media_item_data = mediaItemData?.media_items?.find(
    (item) => String(item.media_item_id) === id
  );

  return (
    <ErrorBoundary>
      <Flex gap={4}>
        <Card alignItems="center">
          <CardHeader width="100%">
            <HStack justifyContent="space-between">
              <Heading size="md">Emotion</Heading>
              <FeatureFlag description="Shows the count of emotions for the timestamp during the trailer" />
            </HStack>
          </CardHeader>
          {isLoading ? (
            <Flex height="300px" alignItems="center">
              <LoadingSpinner />
            </Flex>
          ) : (
            <Box>
              <NetEmotions data={emotionality} totalTimeseries={data} />
            </Box>
          )}
        </Card>

        <Box flex={2}>
          <ErrorBoundary
            fallback={
              <EmptyState
                icon={FiAlertCircle}
                title="Oops, this doesn't look good."
                description={
                  "Please try to refresh the browser and if this doesn't work, then contact VolKno Support"
                }
              />
            }
          >
            <ReactPlayer
              width="100%"
              ref={player}
              url={media_item_data?.url_1}
              onDuration={setDuration}
              onProgress={({ playedSeconds }) => setCurrentTime(playedSeconds)}
              controls
            />
          </ErrorBoundary>
          <Card
            mb="80px"
            style={{ "border-radius": "10px" }}
            maxWidth="100%"
            mt="3"
          >
            <Box height="100px" width="100%">
              {isLoading ? (
                <Flex justifyContent="center" alignItems="center">
                  <LoadingSpinner />
                </Flex>
              ) : (
                <ResponsiveContainer>
                  <AreaChart height={200} data={data.data}>
                    <defs>
                      {data?.emotions?.map((ithEmotion, idx) => (
                        <Gradient
                          key={`${idx}-gradient`}
                          color={EMOTION_COLOR_MAP[ithEmotion]}
                          name={ithEmotion}
                        />
                      ))}
                    </defs>

                    <Tooltip content={CustomTooltip} />
                    <ReferenceLine x={indexTime} stroke="red" />
                    {data?.emotions?.map((ithEmotion, idx) => (
                      <Area
                        key={`${ithEmotion}-color`}
                        type="monotone"
                        dataKey={ithEmotion}
                        color={EMOTION_COLOR_MAP[ithEmotion]}
                        fillOpacity={1}
                        fill={`url(#color${ithEmotion})`}
                      />
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </Box>
          </Card>
        </Box>
      </Flex>
    </ErrorBoundary>
  );
};
export default Video;

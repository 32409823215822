import React from "react";

const YouTubeVideo = ({ videoId }) => {
  const video_id = videoId?.value || videoId;

  const videoSrc = `https://www.youtube.com/embed/${video_id}`;

  return (
    <iframe
      width="560"
      height="315"
      src={videoSrc}
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      title="Embedded youtube"
    />
  );
};

export default YouTubeVideo;

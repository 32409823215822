import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/AuthProviderHistory";
import { Provider } from "react-redux";
import store from "./store/store";
import { H } from "highlight.run";
import { NormSelectorProvider } from "./components/media/NormContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);
// H.init("5g5wm4pg", {
//   serviceName: "frontend-app",
//   tracingOrigins: true,
//   networkRecording: {
//     enabled: true,
//     recordHeadersAndBody: true,
//   },
// });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
  VStack,
  Text,
  HStack,
  Box,
  Progress,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Badge,
} from "@chakra-ui/react";

import { useMemo } from "react";
import FeatureFlag from "../../util/FeatureFlag";
import { FiChevronRight } from "react-icons/fi";

export const MetricBadge = ({ value }) => {
  const isNumber = typeof value === "number";

  const bgColor = useMemo(() => {
    if (isNumber) {
      if (value < 30) return "red.100";
      if (value >= 30 && value < 75) return "yellow.100";
      if (value >= 75) return "green.100";
    }
    return "black";
  }, [value, isNumber]);

  const textColor = useMemo(() => {
    if (isNumber) {
      if (value < 30) return "red.500";
      if (value >= 30 && value < 75) return "yellow.500";
      if (value >= 75) return "green.500";
    }
    return "black";
  }, [value, isNumber]);

  const badgeText = useMemo(() => {
    if (isNumber) {
      if (value < 30) return "LOW";
      if (value >= 75) return "HIGH";
    }
    return "NORMAL";
  }, [value, isNumber]);

  return (
    <Box
      borderRadius="md"
      fontSize="xs"
      color={textColor}
      bg={bgColor}
      border={"1px solid"}
      borderColor={textColor}
      fontWeight="bold"
      px="1"
    >
      {badgeText}
    </Box>
  );
};

export const MetricCircle = ({
  name,
  value,
  description,
  maxNumIs100 = true,
  size = "sm",
  ...props
}) => {
  const isNumber = typeof value === "number";

  const cleanedValue = useMemo(() => {
    if (isNumber) {
      if (value >= 100 && maxNumIs100) {
        return 99;
      }

      return Math.round(value);
    }
    return value;
  }, [value, isNumber, maxNumIs100]);

  const bgColor = useMemo(() => {
    if (isNumber) {
      if (value < 50) return "red.400";
      if (value >= 50 && value < 75) return "yellow.400";
      if (value >= 75) return "green.400";
    }
    return "black";
  }, [value, isNumber]);
  return (
    <Box
      color={bgColor}
      p="0.5"
      bg="gray.100"
      borderRadius="full"
      boxSize={size === "sm" ? "1.6rem" : "2rem"}
      fontWeight={"bold"}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Text noOfLines={1}>{cleanedValue}</Text>
    </Box>
  );
};

export const MetricProperty = ({
  name,
  value,
  description,
  maxNumIs100 = true,
  ...props
}) => {
  return (
    <HStack
      {...props}
      fontSize={props?.fontSize || "sm"}
      gap={2}
      justifyContent="space-between"
      w="100%"
      _hover={props?.onClick ? { bg: "gray.100", cursor: "pointer" } : null}
      px="0.5"
      borderRadius={"md"}
    >
      <HStack>
        <Text fontWeight="medium" color="gray.500">
          {name}
        </Text>
        {description && <FeatureFlag description={description} />}
      </HStack>
      <HStack>
        {props?.rawValue && (
          <Badge borderRadius="md" px="1" py="0.5" fontSize="xs">
            Avg {Math.round(props?.rawValue * 10) / 10}
          </Badge>
        )}

        <MetricCircle
          name={name}
          value={value}
          maxNumIs100={maxNumIs100}
          {...props}
        />

        {props?.onClick && <FiChevronRight color="gray" />}
      </HStack>
    </HStack>
  );
};

export const MetricBar = ({ name, value, description, ...props }) => {
  const color = useMemo(() => {
    if (value < 50) return "linear-gradient(to right, orange.200, red.400)";
    if (value >= 50 && value < 75)
      return "linear-gradient(to right, yellow.200, yellow.500)";
    if (value >= 75) return "linear-gradient(to right, green.200, green.500)";
    return "linear-gradient(to right, red, orange)";
  }, [value]);

  return (
    <Box w="100%" {...props}>
      <Text mb={2} fontWeight="bold">
        {name}
      </Text>
      <Popover trigger="hover" placement="top">
        <PopoverTrigger>
          <Box>
            <Progress
              value={value}
              size="md"
              borderRadius="md"
              bg="gray.100"
              hasStripe
              sx={{
                "& > div": {
                  backgroundImage: color,
                },
              }}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />

          <PopoverBody>Exact value: {value}</PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const MetricLister = ({ name, children }) => {
  return (
    <VStack w="100%" justify={"start"} align={"start"} gap={2}>
      <Text fontWeight={"bold"} fontSize={"sm"} color={"gray.700"}>
        {name}
      </Text>
      <VStack w={"100%"}>{children}</VStack>
    </VStack>
  );
};

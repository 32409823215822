import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";

export const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);

  addMeta(itemRank);

  return itemRank.passed;
};

export const isDateLessThanFilter = (row, columnId, value) => {
  try {
    const date = new Date(row.getValue(columnId));

    const start = new Date(value);
    const end = new Date();

    if ((start || end) && !date) return false;
    if (start && !end) {
      return date.getTime() >= start.getTime();
    } else if (!start && end) {
      return date.getTime() <= end.getTime();
    } else if (start && end) {
      return (
        date.getTime() >= start.getTime() && date.getTime() <= end.getTime()
      );
    } else return true;
  } catch {
    return false;
  }

  //If one filter defined and date is null filter it
};

export const userFilter = (row, columnId, value) => {
  const email = row.getValue(columnId)?.email;
  if (value === email) {
    return true;
  } else {
    return false;
  }
};

export const arrayFilter = (row, columnId, value) => {
  const val = row.getValue(columnId);
  if (Array.isArray(val)) {
    return val?.includes(value);
  } else {
    return false;
  }
};

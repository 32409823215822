import {
  Text,
  Box,
  Card,
  Heading,
  Flex,
  SimpleGrid,
  HStack,
  Tag,
  Spinner,
  useToken,
  CardHeader,
  CardBody,
  Button,
  CardFooter,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Label,
} from "recharts";

import { findObjectWithHighestValue } from "../../utils/filter.js";
import PivotTable from "../util/PivotTable.js";
import PercentageIndicator from "../util/PercentageIndicator.js";
import { Select, SelectButton, SelectList } from "@saas-ui/react";
const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <Box as="g" transform={`translate(${x},${y})`}>
      <Text fontSize="sm" textAnchor="middle" fill="#666">
        {payload.value}
      </Text>
    </Box>
  );
};

const CustomYAxisTick = ({ x, y, payload }) => {
  console.log(payload);
  return (
    <Box bg="red">
      <Text fontSize="sm">{payload.value}</Text>
    </Box>
  );
};
export const CustomBarLabel = (props) => {
  const { x, y, width, height, value } = props;

  const middleX = x + width / 2;
  const middleY = y + height / 2;

  return (
    <g>
      <text
        x={middleX}
        y={middleY}
        fill="#fff"
        fontSize={10}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value} %
      </text>
    </g>
  );
};

const formatter = (value) => {
  const stringValue = value.toString();
  const trimmedValue = stringValue.substring(0, 50);
  return stringValue.length > 50 ? `${trimmedValue}...` : trimmedValue;
};

const QuestionDisplay = ({
  questionData,
  ithQuestion,
  idx,
  target_data_value,
}) => {
  const groups = [
    { value: "overall", label: "Overall" },
    { value: "agegroup", label: "Age" },
    { value: "ethnicity", label: "Ethnicity" },
    { value: "sex", label: "Sex" },
    { value: "parent", label: "Parent" },
    { value: "income", label: "Income" },
    { value: "audience_type", label: "Haters, Lovers, Casuals" },
  ];
  const [selectedGroup, setSelectedGroup] = useState("overall");
  const [red] = useToken("colors", ["red.500"]);

  const [questionDataActive, setQuestionDataActive] = useState({});
  const [highestData, setHighestData] = useState({});

  useEffect(() => {
    setQuestionDataActive(
      questionData?.[selectedGroup]
        ?.map((ithQuestionData) => ({
          ...ithQuestionData.data,
          value: ithQuestionData.value,
        }))
        .sort((a, b) => b.percentage_of_answer - a.percentage_of_answer)
    );

    setHighestData(
      findObjectWithHighestValue(
        questionData?.[selectedGroup]?.map((ithQuestionData) => ({
          ...ithQuestionData?.data,
          value: ithQuestionData.value,
        })),
        "answer_percent_delta"
      )
    );
  }, [questionData, selectedGroup]);

  return (
    <Card key={ithQuestion}>
      {/* <pre>{JSON.stringify(questionDataActive, null, 2)}</pre> */}
      <CardHeader borderBottom="1px solid" borderColor="gray.200">
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Box maxWidth="50%">
            <HStack pb="2">
              <Text fontWeight="medium" size="sm">
                {questionData?.question}
              </Text>
            </HStack>
          </Box>
          <Box>
            <Select
              size="xs"
              defaultValue="Overall"
              options={groups}
              onChange={(i) => setSelectedGroup(i)}
            >
              <SelectButton />
              <SelectList />{" "}
            </Select>
          </Box>
        </HStack>
      </CardHeader>
      <Box>
        {selectedGroup !== "overall" ? (
          <>
            <PivotTable
              data={questionData?.[selectedGroup]}
              valueKey="percentage_of_demographic"
              color="red"
            />
          </>
        ) : (
          <Box>
            <Box height="400px" p="3">
              <ResponsiveContainer>
                <BarChart data={questionDataActive} layout="vertical">
                  <Tooltip />
                  <XAxis type="number" axisLine={false} tick={false} />
                  <YAxis
                    type="category"
                    dataKey="value"
                    width={75}
                    style={{
                      fontSize: "0.7rem",
                      textAlign: "left",
                    }}
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={formatter}
                  ></YAxis>

                  <Bar
                    radius={6}
                    barCategoryGap={50}
                    dataKey="percentage_of_answer"
                    fill={red}
                    label={<CustomBarLabel />}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default QuestionDisplay;

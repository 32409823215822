import {
  Box,
  Card,
  Flex,
  HStack,
  Spinner,
  Text,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  NProgress,
  Select,
  SelectButton,
  SelectList,
  EmptyState,
} from "@saas-ui/react";
import { useState, useEffect, useMemo } from "react";
import { useCompareSurveysMutation } from "../../../store/services/mediaApi";
import PivotTable from "../../util/PivotTable";
import useFormSnackbar from "../../util/FormSnackbar";
import { useCompareContext } from "./CompareMedia";
const createDefaultAnswerObject = (sample, answer) => {
  const defaultObject = { answer }; // Start with the answer property
  for (const key in sample) {
    if (key !== "answer") {
      // Assign a sensible default based on the data type
      defaultObject[key] = typeof sample[key] === "number" ? 0 : "";
    }
  }
  return defaultObject;
};
const IndividualQuestion = ({ questionData }) => {
  const newData = useMemo(() => {
    if (!questionData?.data) {
      return [];
    }

    const allAnswers = new Set();
    questionData.data.forEach((row) => {
      row.data.forEach((item) => {
        allAnswers.add(item.answer);
      });
    });

    const sampleObject = questionData.data[0]?.data[0] || {};

    const processedData = questionData.data.map((ithRow) => {
      const currentAnswers = new Map(
        ithRow.data.map((item) => [item.answer, item])
      );

      //   make sure all answers are accounted and if not should be zero
      const completeData = Array.from(allAnswers).map(
        (answer) =>
          currentAnswers.get(answer) ||
          createDefaultAnswerObject(sampleObject, answer)
      );

      // sort to ensure a consistent order
      const sortedData = completeData.sort((a, b) =>
        a.answer.localeCompare(b.answer)
      );

      return {
        value: ithRow.value,
        data: sortedData,
      };
    });

    return processedData;
  }, [questionData]);
  return (
    <Card p="4">
      <Text mb={4}>{questionData.question}</Text>
      <PivotTable
        data={newData}
        valueKey="percentage_of_answer"
        otherKey="answer"
        color="red"
      />
    </Card>
  );
};

const CompareSurveys = () => {
  const [selectedSurvey, setSelectedSurvey] = useState();
  const { getFormattedBody, selectedValues } = useCompareContext();
  const [postSurveyData, surveyResponse] = useCompareSurveysMutation();
  const { data: surveyData, isLoading } = surveyResponse;
  useFormSnackbar(surveyResponse, "Error Fetching Surveys");

  useEffect(() => {
    if (selectedValues.length > 1) {
      postSurveyData(getFormattedBody());
    }
  }, [selectedValues, postSurveyData]);

  useEffect(() => {
    if (surveyData && surveyData.surveys.length > 0) {
      setSelectedSurvey(surveyData.surveys[0]);
    }
  }, [surveyData]);

  const showEmptyState =
    !isLoading &&
    (!surveyData ||
      surveyData.surveys.length === 0 ||
      !(surveyData[selectedSurvey]?.questions?.length > 0));

  const hasSelectedItems = selectedValues.length > 0;
  const noSurveysAvailable =
    hasSelectedItems && (!surveyData || surveyData.surveys.length === 0);

  return (
    <>
      {isLoading ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NProgress colorScheme="red" isAnimating position="absolute" />
          <Spinner size="lg" />
        </Flex>
      ) : showEmptyState ? (
        <Flex width="100%" flexDirection="column" color="gray.600">
          <EmptyState
            title={
              noSurveysAvailable
                ? "No Matching Surveys Found"
                : "Awaiting Selection"
            }
            description={
              noSurveysAvailable
                ? "Your selection unfortunately did not match any available surveys. This could be due to a lack of common survey questions among the selected titles, or there are no surveys available for them at this moment. Please adjust your selection criteria or try selecting different titles."
                : "Please select a set of titles to begin comparing survey data. Once selected, you'll be able to view and compare survey results here. Ensure your selections share common survey questions for the best comparison experience."
            }
          />
        </Flex>
      ) : (
        <Flex width="100%" flexDirection="column">
          <HStack width="100%" justifyContent="space-between" p="2">
            <Text>Survey Results</Text>
            <Box>
              <Select
                name="country"
                value={selectedSurvey}
                onChange={(e) => setSelectedSurvey(e)}
                options={surveyData?.surveys?.map((ithSurvey) => ({
                  value: ithSurvey,
                  label: ithSurvey,
                }))}
              >
                <SelectButton />
                <SelectList />
              </Select>
            </Box>
          </HStack>

          <SimpleGrid columns={2} gap={4}>
            {surveyData?.[selectedSurvey]?.questions?.map(
              (ithQuestion, idx) => (
                <IndividualQuestion
                  key={idx}
                  questionData={surveyData[selectedSurvey][ithQuestion]}
                />
              )
            )}
          </SimpleGrid>
        </Flex>
      )}
    </>
  );
};

export default CompareSurveys;

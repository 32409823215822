export function getTagColor(tagName) {
  // Define color groups
  const redGroup = ["Hate It", "Boring", "Stupid", "Weak", "Annoying", "Flop"];
  const grayGroup = [
    "Like It",
    "Ok",
    "Mediocre",
    "Interesting",
    "Silly",
    "Basic",
  ];
  const greenGroup = [
    "Love It",
    "Awesome",
    "Funny",
    "Inspiring",
    "Creative",
    "Hit",
  ];

  // Match the tag name to its color group
  if (redGroup.includes(tagName)) {
    return "red.500"; // Reddish pink color
  } else if (grayGroup.includes(tagName)) {
    return "gray.800"; // Light orange color
  } else if (greenGroup.includes(tagName)) {
    return "green.500"; // Teal color
  } else {
    return "gray.500"; // Tag name not found
  }
}

import {
  Text,
  Spacer,
  ButtonGroup,
  HStack,
  Box,
  Button,
  Flex,
} from "@chakra-ui/react";
import {
  FormLayout,
  PrevButton,
  NextButton,
  FormStepper,
  StepsCompleted,
  FormValue,
  LoadingOverlay,
  LoadingSpinner,
  LoadingText,
  PropertyList,
  Property,
  DisplayIf,
} from "@saas-ui/react";

import { StepForm } from "@saas-ui/forms/yup";
import * as yup from "yup";
import { MediaOptions } from "./IconSelector";
import { VideoSelector } from "./VideoSelector";
import VideoLabeler from "./VideoLabeler";
import SurveyForm, { SurveyStep } from "./SurveyForm";
import CampaignDetails from "./CampaignDetails";
import ConfirmDetails from "./ConfirmDetails";
import axios from "axios";
import { useRef, useState } from "react";
import { useCreateMediaMutation } from "../../store/services/mediaApi";
import { useParams } from "react-router";

const CreateMedia = () => {
  const { client } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [submitCreate, { isError, isSuccess }] = useCreateMediaMutation();
  const formRef = useRef(null);

  const steps = [
    {
      name: "project",
      schema: yup.object({
        description: yup.string().label("Description"),
      }),
    },
  ];

  const onSubmit = async (formOptions) => {
    setIsLoading(true);
    console.log(formOptions);
    const payload = {
      ...formOptions,
      client_id: client,
    };

    // const res = await submitCreate(payload);

    // if (formOptions.video) {
    //   try {

    //     const fields = res.data.video.fields;

    //     const formData = new FormData();
    //     const form_d = {
    //       ...fields,
    //       "Content-Type": "video/mp4",
    //     };
    //     for (const name in form_d) {
    //       formData.append(name, form_d[name]);
    //     }

    //     formData.append("file", formOptions.video);

    //     const config = {
    //       onUploadProgress: (event) => {
    //         const percent = Math.round((event.loaded / event.total) * 100);
    //         setProgress(percent);
    //       },
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     };
    //     await axios.post(res.data.video.url, formData, config);
    //   } catch (error) {
    //     setError(true);
    //   }
    //   setIsLoading(false);
    //   setProgress(0);
    // }
  };
  return (
    <Box p="6" bg="gray.50" minH="100vh">
      <HStack mb="5">
        <Text>Create Project</Text>
      </HStack>
      <StepForm
        steps={steps}
        ref={formRef}
        defaultValues={{
          name: "",
          email: "",
          password: "",
        }}
        onSubmit={onSubmit}
      >
        {({ Field, FormStep }) => (
          <FormLayout>
            <FormStepper orientation="vertical">
              <FormStep name="project" title="Project details">
                <Box p="5" bg="white" borderRadius="md" shadow="md">
                  <FormLayout>
                    <MediaOptions />

                    <VideoSelector />

                    <NextButton />
                  </FormLayout>
                </Box>
              </FormStep>

              <FormStep name="scene" title="Scene Labeling (Optional)">
                <VideoLabeler />

                <FormLayout>
                  <ButtonGroup>
                    <NextButton />
                    <PrevButton variant="ghost" />
                  </ButtonGroup>
                </FormLayout>
              </FormStep>
              <FormStep name="survey" title="Survey">
                <SurveyStep />

                <ButtonGroup mt="4">
                  <NextButton />
                  <PrevButton variant="ghost" />
                </ButtonGroup>
              </FormStep>
              <FormStep name="campaign" title="Campaign Options">
                <CampaignDetails />

                <ButtonGroup>
                  <NextButton />
                  <PrevButton variant="ghost" />
                </ButtonGroup>
              </FormStep>
              <FormStep name="confirm" title="Confirm">
                <ConfirmDetails />

                <ButtonGroup>
                  <NextButton />
                  <PrevButton variant="ghost" />
                </ButtonGroup>
              </FormStep>

              <StepsCompleted>
                <LoadingOverlay>
                  <LoadingSpinner />
                  <LoadingText>
                    We are setting up your project, just a moment...
                  </LoadingText>
                </LoadingOverlay>
              </StepsCompleted>
            </FormStepper>
          </FormLayout>
        )}
      </StepForm>
    </Box>
  );
};
export default CreateMedia;

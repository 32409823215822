import {
  Text,
  Spacer,
  ButtonGroup,
  HStack,
  Box,
  Button,
  useRadioGroup,
  RadioGroup,
  Radio,
  Stack,
} from "@chakra-ui/react";
import {
  DisplayIf,
  Field,
  SearchInput,
  Select,
  SelectButton,
  SelectList,
  createField,
  useController,
  useFormContext,
} from "@saas-ui/react";

import {
  FileUpload,
  FileUploadTrigger,
  FileUploadDropzone,
} from "@saas-ui/file-upload";
import {
  useGetChannelDetailsQuery,
  useGetTMDBVideoQuery,
} from "../../store/services/mediaApi";

import YouTubeVideo from "./YoutubeVideo";
import { ReactMultiSelect } from "../util/AsyncSelect";
import { forwardRef, useMemo, useState } from "react";
import debounce from "lodash.debounce";

export const LargeOptionsSelect = ({ options, isLoading }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [search, setSearch] = useState("");
  const { control } = useFormContext();

  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name: "yotube_video_id", // Name of the field
    control,
  });

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce((searchTerm) => {
        if (searchTerm) {
          // Ensure searchTerm is not empty
          const filtered = options?.filter((option) =>
            option.label.toLowerCase().includes(searchTerm.toLowerCase())
          );

          setFilteredOptions(filtered);
        } else {
          setFilteredOptions([]); // Reset options when search is cleared
        }
      }, 500), // Adjusted debounce time
    [options]
  );

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearch(value);
    debouncedSearch(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "yotube_video_id",
    onChange,
    value,
  });

  return (
    <Box py={4}>
      {options?.length < 200 ? (
        <Box minH="300px">
          <Text fontSize="sm" fontWeight="medium" mb="2">
            Select a video
          </Text>
          <ReactMultiSelect
            name="yotube_video_id"
            isRequired
            options={options}
          />
        </Box>
      ) : (
        <>
          <SearchInput
            placeholder="Search options..."
            value={search}
            onChange={handleSearchChange}
          />
          {search && ( // Only render RadioGroup if search is not empty
            <RadioGroup onChange={onChange} value={value} ref={ref} mt={4}>
              <Stack direction="column">
                {filteredOptions?.map((option) => (
                  <Radio
                    key={option.value}
                    {...getRadioProps({ value: option.value })}
                  >
                    {option.label}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          )}
          {options && filteredOptions?.length === 0 && (
            <Text>No options found</Text>
          )}
          {isLoading && <Text>Loading...</Text>}
        </>
      )}
    </Box>
  );
};

const VideoUploadField = createField(
  forwardRef((props, ref) => {
    const { onChange, ...rest } = props;
    return (
      <FileUpload
        accept="video/mp4"
        {...rest}
        onFilesChange={(files) => {
          onChange(files.acceptedFiles[0]);
        }}
        maxFiles={1}
        inputRef={ref}
      >
        {({ files, deleteFile }) => (
          <FileUploadDropzone>
            <Text fontSize="sm">Drag your image here</Text>
            {!files?.length ? (
              <FileUploadTrigger as={Button}>Select files</FileUploadTrigger>
            ) : (
              <HStack>
                <Text fontSize="sm">{files[0].name}</Text>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteFile(files[0]);
                  }}
                >
                  Clear
                </Button>
              </HStack>
            )}
          </FileUploadDropzone>
        )}
      </FileUpload>
    );
  }),
  {
    isControlled: true,
  }
);

export const VideoSelector = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const selected_media = watch("selected_media");
  const media_type = watch("media_type");
  const video_id = watch("yotube_video_id");
  const channel_url = watch("youtube_channel_url");

  const {
    data: tmdb_video_options,
    isLoading: tmdb_loading,
    error,
  } = useGetTMDBVideoQuery(selected_media, {
    skip: !selected_media,
  });

  const {
    data: channel_details,
    isLoading: channel_loading,
    error: channel_error,
  } = useGetChannelDetailsQuery(channel_url);

  const chanel_options = useMemo(() => {
    return channel_details?.map((ith) => ({
      value: ith?.id,
      label: ith?.title,
    }));
  }, [channel_details]);

  const tmdb_video_options_select = useMemo(() => {
    return tmdb_video_options?.results?.map((ith) => ({
      value: ith?.key,
      label: `${ith?.name} (${ith?.type})`,
    }));
  }, [tmdb_video_options]);

  return (
    <HStack justifyContent="space-between" alignItems="top">
      <Box flex="1">
        <DisplayIf
          name="media_type"
          condition={(value) => value === "imdb_media"}
        >
          <Field
            name="imdb_upload_type"
            type="select"
            label="Asset Type"
            isRequired
            options={[
              { value: "associated", label: "Associated Media" },
              { value: "upload", label: "New Upload" },
            ]}
          />

          <DisplayIf
            name="imdb_upload_type"
            condition={(value) => value === "upload"}
          >
            <VideoUploadField name="video" label="Upload Video" />
          </DisplayIf>
          <DisplayIf
            name="imdb_upload_type"
            condition={(value) => value === "associated"}
          >
            <LargeOptionsSelect
              isLoading={tmdb_loading}
              options={tmdb_video_options_select}
            />
          </DisplayIf>
        </DisplayIf>

        <DisplayIf
          name="media_type"
          condition={(value) => value === "youtube_channel"}
        >
          <Field
            name="subtitle"
            type="text"
            label="Asset Title"
            defaultValue={video_id?.label}
            isRequired
          />
          <LargeOptionsSelect
            isLoading={channel_loading}
            options={chanel_options}
          />
        </DisplayIf>

        <DisplayIf
          name="media_type"
          condition={(value) => value === "custom_media"}
        >
          <Text fontWeight="semibold" fontSize="sm" my="3">
            Upload Assets
          </Text>

          <Field
            my="3"
            name="subtitle"
            type="text"
            label="Asset Title"
            isRequired
          />
          <VideoUploadField name="video" isRequired />
        </DisplayIf>
      </Box>
      <Box borderRadius="lg" alignContent={"center"}>
        {video_id && media_type !== "custom_media" && (
          <YouTubeVideo videoId={video_id} />
        )}
      </Box>
    </HStack>
  );
};

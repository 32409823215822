import {
  Box,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { sortAlphabetically } from "../../utils/sort";

// Sample data (replace with your data)

// Define color scales for percentage color-coding
function flattenKey(data, key) {
  return data
    ?.flat() // Flatten the outer array
    ?.flatMap((item) => item.data) // Extract the "data" arrays
    ?.map((obj) => obj[key]); // Extract the values associated with the specified key
}

const PivotTable = ({
  data,
  valueKey,
  otherKey = "demographic_value",
  color = "blue",
  nColors = 5,
}) => {
  // Extract the values based on the provided valueKey
  const values = flattenKey(data, valueKey);
  // // Determine the min and max values from the data
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  // Define color tokens based on the min and max values
  const colorTokens = Array.from({ length: values.length }, (_, index) => {
    const percentage = (values[index] - minValue) / (maxValue - minValue);
    const colorIndex = Math.min(Math.floor(percentage * nColors), nColors); // Adjusted to match the provided 9 color tokens
    return `${color}.${100 + colorIndex * 100}`;
  });

  const uniqueDemoItems = sortAlphabetically({
    array: data[0],
    key: otherKey,
  });

  // Calculate legend items
  const legendItems = Array.from({ length: nColors + 1 }, (_, index) => {
    const rangeMin = minValue + (index / nColors) * (maxValue - minValue);
    const rangeMax = minValue + ((index + 1) / nColors) * (maxValue - minValue);
    return { rangeMin, rangeMax, color: `${color}.${100 + index * 100}` };
  });

  const getColorForValue = (ranges, value) => {
    const range = ranges.find(
      (range) => value >= range.rangeMin && value < range.rangeMax
    );
    return range ? range.color : `${color}.100`; // You can specify a default color here
  };

  const renderPercentageBox = (percentage, value) => {
    const roundedPercentage = Math.round(percentage);

    return (
      <Tooltip label={`${value}: ${roundedPercentage}%`} hasArrow>
        <Flex
          bg={getColorForValue(legendItems, roundedPercentage)}
          p="3"
          fontSize="xs"
          textAlign="center"
          borderRadius="sm"
          justifyContent="center"
          alignItems="center"
        >
          <Text>{roundedPercentage}%</Text>
        </Flex>
      </Tooltip>
    );
  };

  return (
    <Box overflow="auto">
      <Flex justifyContent="flex-end" width="100%" mb="3">
        <HStack width="100%" justifyContent="flex-end" spacing={0}>
          {legendItems.map((item, index) => (
            <Tooltip
              key={index}
              label={`${item.rangeMin.toFixed(2)} - ${item.rangeMax.toFixed(
                2
              )}`}
              hasArrow
            >
              <Box>
                <Box
                  bg={item.color}
                  p="2"
                  width="100%"
                  textAlign="center"
                  fontWeight="bold"
                ></Box>
              </Box>
            </Tooltip>
          ))}
        </HStack>
      </Flex>
      <Box p="3">
        <Table size="sm" variant="unstyled">
          <Tbody>
            {data.map((row) => {
              const sortedRow = sortAlphabetically({
                array: row,
                key: otherKey,
              });
              return (
                <Tr p={1} m={0} key={sortedRow.value}>
                  <Td p={1} m={1}>
                    {sortedRow.value}
                  </Td>
                  {sortedRow.data.map((item) => (
                    <Td p={1} m={1} key={item.demographic_value}>
                      {renderPercentageBox(item[valueKey], item[otherKey])}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th></Th>
              {uniqueDemoItems?.data?.map((item) => {
                return (
                  <Th textAlign="center" key={item[otherKey]}>
                    {item[otherKey]}
                  </Th>
                );
              })}
            </Tr>
          </Tfoot>
        </Table>
      </Box>
    </Box>
  );
};

export default PivotTable;

import { createContext, useContext, useMemo, useState } from "react";
import {
  useOpenEndedQuery,
  useTopicSizingQuery,
} from "../../store/services/mediaApi";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, HStack } from "@chakra-ui/react";
import { FiVideo, FiYoutube } from "react-icons/fi";

const OpenEndedSourceContext = createContext();

export function OpenEndedSourceProvider({ id, children }) {
  const { isLoading: userLoading } = useAuth0();
  const [selectedSource, setSelectedSource] = useState("VolKno");

  const { data: topicSizing, isLoading: topicSizingLoading } =
    useTopicSizingQuery(id, {
      skip: userLoading || !id,
    });

  const { data: openEndedData, isLoading: openEndedLoading } =
    useOpenEndedQuery(id, {
      skip: userLoading || !id,
    });

  const sortedTopicSizing = useMemo(() => {
    const sourceData = topicSizing?.[selectedSource];

    if (!Array.isArray(sourceData)) {
      return [];
    }

    return [...sourceData].sort(
      (a, b) => (b?.positive_percentage || 0) - (a?.positive_percentage || 0)
    );
  }, [topicSizing, selectedSource]);

  const value = {
    selectedSource,
    setSelectedSource,
    options: Object.keys(topicSizing || {}) || [],
    topicSizing: sortedTopicSizing,
    openEnded: openEndedData?.[selectedSource] || [],
    isLoading: openEndedLoading || topicSizingLoading,
  };

  return (
    <OpenEndedSourceContext.Provider value={value}>
      {children}
    </OpenEndedSourceContext.Provider>
  );
}

export function useOpenEndedSource() {
  const context = useContext(OpenEndedSourceContext);
  if (context === undefined) {
    throw new Error(
      "useOpenEndedSource must be used within an OpenEndedSourceProvider"
    );
  }
  return context;
}

export const OptionButtons = () => {
  const { options, setSelectedSource, selectedSource } = useOpenEndedSource();

  const getIcon = (source) => {
    switch (source) {
      case "VolKno":
        return <FiVideo />;
      case "topic_sizing":
      default:
        return <FiYoutube />;
    }
  };

  if (!options) return null;

  return (
    <HStack w="100%" overflowX="auto">
      {options.map((option) => {
        const icon = getIcon(option);
        const isSelected = selectedSource === option;

        return (
          <Button
            key={option}
            size="sm"
            colorScheme={isSelected ? "red" : "gray"}
            onClick={() => setSelectedSource(option)}
            leftIcon={icon}
          >
            {option}
          </Button>
        );
      })}
    </HStack>
  );
};

import {
  Text,
  Box,
  Card,
  Heading,
  Flex,
  HStack,
  Tag,
  Spinner,
  CardHeader,
  Select,
  useToken,
  Grid,
  useDisclosure,
  Button,
  IconButton,
} from "@chakra-ui/react";
import CircularProgressBar from "../../util/CircularProgressBar.tsx";
import {
  useGetAudienceGenreQuery,
  useGetMediaAudienceQuery,
  useSummarizeAudienceMutation,
  useGetRawAudienceCommentsQuery,
  useRefreshSummaryMutation,
} from "../../../store/services/mediaApi";
import { useNavigate, useParams } from "react-router";
import {
  Banner,
  BannerActions,
  BannerCloseButton,
  BannerContent,
  BannerIcon,
  BannerTitle,
  EmptyState,
  ErrorBoundary,
  LoadingOverlay,
  LoadingSpinner,
  NProgress,
  useModals,
} from "@saas-ui/react";
import {
  FiBarChart,
  FiUser,
  FiDollarSign,
  FiChevronDown,
  FiCloudLightning,
  FiRefreshCcw,
  FiX,
  FiExternalLink,
} from "react-icons/fi";

import {
  BsEmojiLaughing,
  BsEmojiAngry,
  BsEmojiExpressionless,
} from "react-icons/bs";

import { PiGenderMaleBold } from "react-icons/pi";
import PercentageIndicator from "../../util/PercentageIndicator.js";
import {
  findObjectWithHighestValue,
  findTopNObjects,
} from "../../../utils/filter";
import { useEffect, useMemo, useState } from "react";

import HorizontalBar from "../../util/HorizontalSingleBar.js";
import DataTable from "../../../Table/DataTable.js";
import { createColumnHelper } from "@tanstack/react-table";
import { sortedData } from "../../../utils/sort.js";
import { useNormSelector } from "../NormContext.js";
import FeatureFlag from "../../util/FeatureFlag.js";

const GroupDemographics = ({ data, title, selectedNorm }) => {
  const modal = useModals();

  // State to keep track of the selected option

  const [tableData, setTableData] = useState([]);
  const columnHelper = createColumnHelper();

  // Function to handle select change
  const handleSelectChange = (event) => {
    if (data[event.target.value]) {
      setTableData(sortedData(data[event.target.value], "demographic_value"));
    }
  };

  // Dynamically create options from the data keys
  const options = Object.keys(data)
    .filter((key) => key !== "overall")
    .map((key) => ({
      label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
      value: key,
    }));

  const normDeltaAudience =
    selectedNorm === "default"
      ? "audience_type_census_percent_delta"
      : "audience_type_agg_percent_delta";

  const demoNormDelta =
    selectedNorm === "default"
      ? "demographic_census_percent_delta"
      : "demographic_agg_percent_delta";

  const columns = [
    columnHelper.accessor("demographic_value", {
      header: "Group",
    }),
    columnHelper.accessor("count", {
      header: "Count",
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("percentage_of_audience_type", {
      header: `% of ${title}`,
      cell: (info) => Math.round(info.getValue() * 10) / 10,
    }),
    columnHelper.accessor(normDeltaAudience, {
      header: (
        <HStack>
          <Text>{title} Norm</Text>
          <FeatureFlag
            description={
              "The lover norm represents the percentage difference of a specific demographic group’s representation within a particular audience, relative to the same subgroup across the selected norm base. This helps to understand how a particular demographic is represented compared to its representation in the norm base."
            }
          />
        </HStack>
      ),
      cell: (info) => <PercentageIndicator number={info.getValue()} />,
    }),
    columnHelper.accessor(demoNormDelta, {
      header: (
        <HStack>
          <Text>Demo Norm</Text>
          <FeatureFlag
            description={
              "The demo norm represents the percentage of a specific demographic group’s representation across any audience type, relative to the currently selected norm base. This helps to understand how well a particular demographic is represented compared to a standard or baseline."
            }
          />
        </HStack>
      ),
      cell: (info) => <PercentageIndicator number={info.getValue()} />,
    }),
  ];

  return (
    <Box p="0">
      <HStack justifyContent={"space-between"}>
        <Heading size="md">{title} Demographics</Heading>
        <HStack>
          <Select
            placeholder="Select option"
            size="sm"
            onChange={handleSelectChange}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>

          <IconButton
            size="sm"
            variant="ghost"
            icon={<FiX />}
            onClick={() => modal.closeAll()}
          />
        </HStack>
      </HStack>
      <Box my="4">
        <Banner status="info" size="xs">
          <BannerIcon />
          <BannerContent>
            <BannerTitle>Need help understanding this data?</BannerTitle>
          </BannerContent>
          <BannerActions>
            <Button
              colorScheme="white"
              variant="solid"
              rightIcon={<FiExternalLink />}
              onClick={() =>
                window.open(
                  "https://majestic-crowley-a1c.notion.site/Norms-2641b577ba9b4ea0ae7959db892a2612",
                  "_blank"
                )
              }
            >
              Norms Guide
            </Button>
          </BannerActions>
          <BannerCloseButton />
        </Banner>
        {!tableData ? (
          <EmptyState
            mb="8"
            title="Select a Demographic"
            description="Select a demographic from the dropdown to see its data."
          />
        ) : (
          <DataTable
            data={tableData}
            simplify={true}
            columns={columns}
            columnsToFilter={[]}
            title={`${title} Demographics`}
            isSearchable={false}
          />
        )}{" "}
      </Box>
    </Box>
  );
};

const DemographicTag = ({
  demographic,
  data,
  selectedNorm,
  item_to_select = "percentage_of_audience_type",
}) => {
  const topItem = useMemo(() => {
    return findObjectWithHighestValue(data, item_to_select);
  }, [selectedNorm, data]);

  return (
    <>
      <Tag m="1">
        <HStack>
          <demographic.icon />
          <Text>{topItem?.demographic_value}</Text>
          <Text color="purple.400">
            {Math.round(topItem?.[item_to_select] * 10) / 10}%
          </Text>
        </HStack>
      </Tag>
    </>
  );
};

const AudienceGenre = ({ audienceType }) => {
  const { id } = useParams();

  const { data: genreData, isLoading } = useGetAudienceGenreQuery(id);
  const datas = findTopNObjects(genreData?.[audienceType] || [], "count", 4);

  return (
    !!datas?.length && (
      <Box>
        <HStack
          py="3"
          color="gray.500"
          fontSize="sm"
          justifyContent="space-between"
        >
          <Text>Top Genres for {audienceType}'s</Text>
        </HStack>
        {datas?.map((ithGenre) => {
          return (
            <Tag key={`${audienceType}-${ithGenre.name}`} m="1">
              {ithGenre.name}
            </Tag>
          );
        })}
      </Box>
    )
  );
};

export const TotalAudienceCard = ({ mediaItemData }) => {
  const { id } = useParams();
  const { selectedNorm } = useNormSelector();

  const { data: audienceData, isLoading } = useGetMediaAudienceQuery({
    value: id,
    norm: selectedNorm,
  });

  const [selectedDemo, setSelectedDemo] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (audienceData) {
      setSelectOptions(
        Object.keys(audienceData?.["overall"] || {})?.filter(
          (ithII) => ithII !== "overall"
        )
      );
      setSelectedDemo("ethnicity");
    }
  }, [audienceData]);

  return (
    <Card flex="1">
      {isLoading ? (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      ) : (
        <>
          <CardHeader borderBottom="1px solid" borderColor="gray.200">
            <HStack justifyContent="space-between">
              <Box>
                <Heading size="md">Respondent Information</Heading>

                <Text size="sm" color="gray.600">
                  {audienceData?.overall?.overall?.total_by_demographic} unique
                  respondents
                </Text>
              </Box>
              <Box>
                <Select
                  value={selectedDemo}
                  onChange={(i) => setSelectedDemo(i.target.value)}
                >
                  {selectOptions?.map((ithObjectItem) => (
                    <option key={ithObjectItem} value={ithObjectItem}>
                      {ithObjectItem}
                    </option>
                  ))}
                </Select>
              </Box>
            </HStack>
          </CardHeader>

          <Box p="5">
            <ErrorBoundary>
              <HorizontalBar
                data={audienceData?.overall?.[selectedDemo]?.map(
                  (ithDemoGraphItem, idx) => ({
                    label: ithDemoGraphItem?.demographic_value,
                    value: ithDemoGraphItem?.percentage_of_demographic,
                    children: (
                      <HStack
                        alignContent="center"
                        mb="3"
                        mt="1"
                        ml="4"
                        fontSize="xs"
                        fontWeight="bold"
                        color="gray.500"
                      >
                        <Text>Count: {ithDemoGraphItem?.count}</Text>
                        <HStack>
                          <PercentageIndicator
                            pt={0}
                            number={
                              ithDemoGraphItem?.demographic_census_percent_delta
                            }
                          />
                        </HStack>
                      </HStack>
                    ),
                  })
                )}
              />
            </ErrorBoundary>
          </Box>
        </>
      )}
    </Card>
  );
};

const RawAudienceCommentDisclosure = ({ data }) => {
  const { isOpen, onOpen, onToggle } = useDisclosure();

  return (
    !!data?.length && (
      <Box>
        <HStack
          cursor="pointer"
          py="3"
          color="gray.500"
          fontSize="sm"
          justifyContent="space-between"
          onClick={() => onToggle()}
        >
          <Text>Raw Comments</Text>

          <FiChevronDown />
        </HStack>
        {isOpen && (
          <Box>
            {data?.map((ithComment) => (
              <Text my="2" fontSize="xs">
                {ithComment}
              </Text>
            ))}
          </Box>
        )}
      </Box>
    )
  );
};

const demos = [
  { title: "ethnicity", icon: FiUser },
  { title: "agegroup", icon: FiBarChart },
  { title: "sex", icon: PiGenderMaleBold },
  { title: "income", icon: FiDollarSign },
];

const groups = [
  { title: "Lover", icon: BsEmojiLaughing },
  { title: "Casual", icon: BsEmojiExpressionless },
  { title: "Hater", icon: BsEmojiAngry },
];

const GroupComponent = ({ groupTitle, audienceData, mediaItemData }) => {
  const groupInfo = groups.find((group) => group.title === groupTitle);
  const Icon = groupInfo.icon; // Icon for the group
  const [summarize, sumResponse] = useSummarizeAudienceMutation();
  const { selectedNorm } = useNormSelector();
  const { id } = useParams();

  const { data: rawComments } = useGetRawAudienceCommentsQuery(id);
  const [refreshSummary, sumRefresh] = useRefreshSummaryMutation();
  const modal = useModals();

  return (
    <Card key={groupTitle} p="4">
      <HStack justifyContent="space-between">
        <HStack color="gray.800" fontSize="xl" fontWeight="bold">
          <Icon />
          <Text>{groupTitle}</Text>
          <Text color="gray.500" fontSize="xs">
            {audienceData[groupTitle]?.overall?.count} Respondents
          </Text>
        </HStack>

        {rawComments?.[groupTitle]?.length > 0 && (
          <HStack>
            <IconButton
              color="purple.400"
              size="xs"
              variant="outline"
              icon={<FiRefreshCcw />}
              isLoading={sumRefresh?.isLoading}
              onClick={() =>
                refreshSummary({
                  value: "audience",
                  audience_type: groupTitle,
                  responses: rawComments?.[groupTitle],
                  title: mediaItemData?.title,
                })
              }
            />
            <Button
              leftIcon={<FiCloudLightning />}
              color="purple.400"
              size="xs"
              variant="outline"
              onClick={() =>
                summarize({
                  audience_type: groupTitle,
                  responses: rawComments?.[groupTitle],
                  title: mediaItemData?.title,
                })
              }
              isLoading={sumResponse?.isLoading}
            >
              Summarize
            </Button>
          </HStack>
        )}
      </HStack>
      {!sumResponse?.isLoading && (sumResponse?.data || sumRefresh?.data) && (
        <Box my="3">
          <Text fontSize="xs" color="gray.600">
            {sumRefresh?.data?.data || sumResponse?.data?.data}
          </Text>
        </Box>
      )}
      <Box pt="5">
        <Text pb="3" color="gray.500" fontSize="sm">
          Segment Size
        </Text>
        <Heading>
          <CircularProgressBar
            selectedValue={
              audienceData[groupTitle]?.overall?.percentage_of_audience_type
            }
            maxValue={25}
            radius={22}
            strokeWidth={7}
            label={true}
          />
        </Heading>
        <PercentageIndicator
          number={
            selectedNorm === "default"
              ? audienceData[groupTitle]?.overall?.audience_type_percent_delta
              : audienceData[groupTitle]?.overall
                  ?.audience_type_agg_percent_delta
          }
          text=" than average"
        />
      </Box>
      <Box py="3">
        <HStack
          justifyContent="space-between"
          color="gray.500"
          fontSize="sm"
          fontWeight="normal"
        >
          <Text pb="3" color="gray.500" fontSize="sm">
            Sample Characteristics
          </Text>
          <Button
            size="xs"
            variant="ghost"
            onClick={() =>
              modal.open({
                size: "4xl",
                hideCloseButton: true,

                body: (
                  <GroupDemographics
                    data={audienceData[groupTitle]}
                    title={groupTitle}
                    selectedNorm={selectedNorm}
                  />
                ),
              })
            }
          >
            Show More
          </Button>
        </HStack>
        {demos.map(
          (demo) =>
            audienceData[groupTitle][demo.title] && (
              <DemographicTag
                key={`${demo.title}-${audienceData[groupTitle][demo.title]}`}
                demographic={demo}
                data={audienceData[groupTitle][demo.title]}
                selectedNorm={selectedNorm}
              />
            )
        )}
      </Box>
      <Box py="3">
        <HStack
          justifyContent="space-between"
          color="gray.500"
          fontSize="sm"
          fontWeight="normal"
        >
          <Text pb="3" color="gray.500" fontSize="sm">
            Target Audience
          </Text>
        </HStack>
        {demos.map(
          (demo) =>
            audienceData[groupTitle][demo.title] && (
              <DemographicTag
                key={`${demo.title}-${audienceData[groupTitle][demo.title]}`}
                demographic={demo}
                data={audienceData[groupTitle][demo.title]}
                selectedNorm={selectedNorm}
                item_to_select="audience_type_census_percent_delta"
              />
            )
        )}
      </Box>
      <Box py="3">
        <AudienceGenre audienceType={groupTitle} />
      </Box>
      <Box py="3">
        <RawAudienceCommentDisclosure data={rawComments?.[groupTitle]} />
      </Box>
    </Card>
  );
};

export const AudienceBase = (props) => {
  const { isLoading, audienceData } = props;

  return (
    <>
      {isLoading ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NProgress colorScheme="red" isAnimating position="absolute" />
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Grid templateColumns="repeat(3, 1fr)" gap={2}>
          {groups?.map(
            (ithGroup) =>
              audienceData?.[ithGroup.title] && (
                <GroupComponent
                  key={ithGroup.title}
                  groupTitle={ithGroup.title}
                  audienceData={audienceData}
                  {...props}
                />
              )
          )}
        </Grid>
      )}
    </>
  );
};

const Audience = ({ mediaItemData }) => {
  const { id } = useParams();
  const { selectedNorm } = useNormSelector();

  const { data: audienceData, isLoading } = useGetMediaAudienceQuery({
    value: id,
    norm: selectedNorm,
  });

  return (
    <AudienceBase
      mediaItemData={mediaItemData}
      audienceData={audienceData}
      standalone={true}
      isLoading={isLoading}
    />
  );
};
export default Audience;

import { configureStore } from "@reduxjs/toolkit";
import { turk } from "./services/turkApi";
import { media } from "./services/mediaApi";
import { appApi } from "./services/appAPI";
import authReducer from "./authSlice";

export default configureStore({
  reducer: {
    [turk.reducerPath]: turk.reducer,
    [media.reducerPath]: media.reducer,
    [appApi.reducerPath]: appApi.reducer,
    auth: authReducer,
  },
  // middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      turk.middleware,
      media.middleware,
      appApi.middleware,
    ]),
});

import Portal from "./components/Portal";
import { SaasProvider, ModalsProvider } from "@saas-ui/react";
import { Route, Routes } from "react-router-dom";
import Media from "./components/media/Media";

import MediaItem from "./components/media/MediaItem";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { sec } from "./auth/security";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useGetPermissionsQuery } from "./store/services/appAPI";
import CreateMedia from "./components/create/CreateMedia";
import Compare from "./components/media/compare/CompareMedia";

const HomeView = () => {
  return <Portal element={<Media />} />;
};

const MediaItemView = () => {
  return <Portal element={<MediaItem />} />;
};
const MediaView = () => {
  return <Portal element={<Media />} />;
};
const CompareView = () => {
  return <Portal element={<Compare />} />;
};
const CreateView = () => {
  return <Portal element={<CreateMedia />} />;
};

function App() {
  const { getAccessTokenSilently, user, isLoading } = useAuth0();
  const { data: permissions } = useGetPermissionsQuery({ skip: isLoading });

  useEffect(() => {
    if (!isLoading && !!user) {
      sec.setAccessTokenSilently(getAccessTokenSilently);

      // if (user) {
      //   // login logic...
      //   H.identify(user?.name, {
      //     id: user?.sub,
      //     email: user?.email,
      //     avatar: user?.picture,
      //   });
      // }
    }
  }, [user, permissions]);

  return (
    <SaasProvider>
      <ModalsProvider>
        <Routes>
          <Route
            path="/"
            exact
            element={<ProtectedRoute component={HomeView} />}
          />
          <Route
            path="/:client/home"
            exact
            element={<ProtectedRoute component={HomeView} />}
          />

          <Route
            path="/:client/compare/:tab"
            exact
            element={<ProtectedRoute component={CompareView} />}
          />
          <Route
            path="/:client/create-media"
            exact
            element={<ProtectedRoute component={CreateView} />}
          />

          <Route
            path="/:client/media"
            exact
            element={<ProtectedRoute component={MediaView} />}
          />
          <Route
            path="/:client/media/:id/:tab"
            exact
            element={<ProtectedRoute component={MediaItemView} />}
          />
        </Routes>
      </ModalsProvider>
    </SaasProvider>
  );
}

export default App;

// src/auth/protected-route.js

import React from "react";
import { Navigate } from "react-router-dom";
import { Box, Skeleton } from "@chakra-ui/react";

import {
  ErrorBoundary,
  Sidebar as LoadingSidebar,
  AppShell,
} from "@saas-ui/react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

export const LoadingPortal = ({}) => (
  <AppShell variant="fullscreen" height="100vh" sidebar={<LoadingSidebar />}>
    <Skeleton minHeight="100%" pt={5} />
  </AppShell>
);

export const ProtectedRoute = ({ component, roleRequired, ...args }) => {
  // const { data: roles, isLoading } = useGetUserPermissionQuery();
  const roles = ["admin"];
  const isLoading = false;

  const Component = withAuthenticationRequired(component, args);

  if (roleRequired) {
    return isLoading ? (
      <LoadingPortal />
    ) : roleRequired?.includes(roles?.role) ? (
      <Component />
    ) : (
      <Navigate to="/unauthorized" replace />
    );
  } else {
    return <Component />;
  }
};

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../auth/security";

export const media = createApi({
  reducerPath: "media",
  tagTypes: ["media"],
  keepUnusedDataFor: 900,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) {
        headers.set("Authorization", `Bearer ${access_token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMedia: builder.query({
      query: (value) => ({
        url: `/media/${value}`,
        method: "GET",
      }),
      providesTags: ["Media"],
    }),
    getCastCrew: builder.query({
      query: (value) => ({
        url: `/tmdb/cast_crew/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaByIMDB: builder.query({
      query: (value) => ({
        url: `/imdb/${value}`,
        method: "GET",
      }),
      providesTags: ["IMDB"],
    }),
    getTMDBVideo: builder.query({
      query: (value) => ({
        url: `/tmdb_videos/${value}`,
        method: "GET",
      }),
      providesTags: ["TMDB"],
    }),
    getChannelDetails: builder.query({
      query: (value) => ({
        url: `/youtube_videos/${value}`,
        method: "GET",
      }),
      providesTags: ["YouTube"],
    }),
    getAiSurveyTemplate: builder.mutation({
      query: (value) => ({
        url: `/survey/ai-templates`,
        method: "POST",
        body: value,
      }),
    }),
    clearMediaItemCache: builder.mutation({
      query: (value) => ({
        url: `/cache/clear`,
        method: "POST",
        body: { media_item_id: value },
      }),
      invalidatesTags: ["MediaItem"],
    }),
    clearAllCache: builder.mutation({
      query: () => ({
        url: `/cache/clear/all`,
        method: "POST",
      }),
      invalidatesTags: ["MediaItem"],
    }),
    getMediaComments: builder.query({
      query: (value) => ({
        url: `/asset/comments-rollup/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),

    getSurveyTemplates: builder.query({
      query: (client_id) => ({
        url: `/survey/templates/${client_id}`,
        method: "GET",
      }),
      providesTags: ["Survey"],
    }),
    refreshMediaComments: builder.mutation({
      query: (value) => {
        const mid = value?.media_item_id;
        return {
          url: `/asset/comments-rollup/${mid}`,
          method: "PATCH",
          body: value,
        };
      },
    }),
    refreshSummary: builder.mutation({
      query: (body) => {
        const { value } = body;
        return {
          url: `/summarize/${value}`,
          method: "PATCH",
          body: body,
        };
      },
    }),
    getRawAudienceComments: builder.query({
      query: (value) => ({
        url: `/asset/raw-comments/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getAudienceGenre: builder.query({
      query: (value) => ({
        url: `/asset/audience_genre/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaItemMeta: builder.query({
      query: (value) => ({
        url: `/asset/meta/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaItemPosters: builder.query({
      query: (value) => ({
        url: `/asset/posters/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaItemRatings: builder.query({
      query: ({ value, norm }) => ({
        url: `/asset/rating/${value}?agg_norm_id=${norm}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaItemDemandRating: builder.query({
      query: ({ value, norm }) => ({
        url: `/asset/demand/${value}?agg_norm_id=${norm}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaItemTags: builder.query({
      query: ({ value, norm }) => ({
        url: `/asset/tags/${value}?agg_norm_id=${norm}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaItemPLatform: builder.query({
      query: (value) => ({
        url: `/asset/platforms/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaAudience: builder.query({
      query: ({ value, norm }) => {
        console.log(`value: ${value}, norm: ${norm}`);
        return {
          url: `/asset/audience/${value}?agg_norm_id=${norm}`,
          method: "GET",
        };
      },
      providesTags: ["MediaItem"],
    }),
    getMediaInterests: builder.query({
      query: ({ value, norm }) => ({
        url: `/asset/interests/${value}?agg_norm_id=${norm}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaTopicSizing: builder.query({
      query: (value) => ({
        url: `/asset/oe-sizing/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),

    openEnded: builder.query({
      query: (value) => ({
        url: `/asset/open-ended/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    topicSizing: builder.query({
      query: (value) => ({
        url: `/asset/sizing/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaEmotion: builder.query({
      query: ({ value, norm }) => ({
        url: `/asset/emotions/${value}?agg_norm_id=${norm}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getCompositeSentiment: builder.query({
      query: ({ value, norm }) => ({
        url: `/asset/sentiment-composite/${value}?agg_norm_id=${norm}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    compareCompositeSentiment: builder.mutation({
      query: (data) => ({
        url: `/compare/sentiment-composite?agg_norm_id=${data.norm}`,
        method: "POST",
        body: data,
      }),
    }),
    getMediaEmotionTimeseries: builder.query({
      query: (value) => ({
        url: `/asset/emotion-timeseries/${value}?orientation=records`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    getMediaSurveys: builder.query({
      query: (value) => ({
        url: `/asset/surveys/${value}`,
        method: "GET",
      }),
      providesTags: ["MediaItem"],
    }),
    compareSurveys: builder.mutation({
      query: (data) => ({
        url: `/compare/surveys?agg_norm_id=${data.norm}`,
        method: "POST",
        body: data,
      }),
    }),
    compareRatings: builder.mutation({
      query: (data) => ({
        url: `/compare/ratings?agg_norm_id=${data.norm}`,
        method: "POST",
        body: data,
      }),
    }),
    compareDemand: builder.mutation({
      query: (data) => ({
        url: `/compare/demand?agg_norm_id=${data.norm}`,
        method: "POST",
        body: data,
      }),
    }),
    compareEmotion: builder.mutation({
      query: (data) => ({
        url: `/compare/emotions?agg_norm_id=${data.norm}`,
        method: "POST",
        body: data,
      }),
    }),
    compareInterests: builder.mutation({
      query: (data) => ({
        url: `/compare/interests?agg_norm_id=${data.norm}`,
        method: "POST",
        body: data,
      }),
    }),
    compareAudience: builder.mutation({
      query: (data) => ({
        url: `/compare/audience?agg_norm_id=${data.norm}`,
        method: "POST",
        body: data,
      }),
    }),
    summarizeQuestions: builder.mutation({
      query: (data) => ({
        url: `/summarize/open-ended`,
        method: "POST",
        body: data,
      }),
    }),
    getNorms: builder.query({
      query: (client) => ({
        url: `/norms/${client}`,
        method: "GET",
      }),
      providesTags: ["Norms"],
    }),

    createMedia: builder.mutation({
      query: (data) => ({
        url: `/create-media`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Media"],
    }),

    summarizeAudience: builder.mutation({
      query: (data) => ({
        url: `/summarize/audience`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
// export elements
export const {
  useCompareCompositeSentimentMutation,
  useTopicSizingQuery,
  useOpenEndedQuery,
  useGetCompositeSentimentQuery,
  useClearAllCacheMutation,
  useClearMediaItemCacheMutation,
  useCreateMediaMutation,
  useGetCastCrewQuery,
  useGetAiSurveyTemplateMutation,
  useGetSurveyTemplatesQuery,
  useGetChannelDetailsQuery,
  useGetTMDBVideoQuery,
  useGetMediaByIMDBQuery,
  useGetNormsQuery,
  useRefreshSummaryMutation,
  useSummarizeAudienceMutation,
  useSummarizeQuestionsMutation,
  useCompareAudienceMutation,
  useCompareInterestsMutation,
  useCompareRatingsMutation,
  useCompareDemandMutation,
  useCompareEmotionMutation,
  useGetMediaItemPostersQuery,
  useGetAudienceGenreQuery,
  useGetRawAudienceCommentsQuery,
  useGetMediaQuery,
  useGetMediaItemMetaQuery,
  useGetMediaItemRatingsQuery,
  useGetMediaItemDemandRatingQuery,
  useGetMediaAudienceQuery,
  useGetMediaItemPLatformQuery,
  useGetMediaInterestsQuery,
  useGetMediaTopicSizingQuery,
  useGetMediaEmotionQuery,
  useGetMediaEmotionTimeseriesQuery,
  useGetMediaSurveysQuery,
  useGetMediaItemTagsQuery,
  useGetMediaCommentsQuery,
  useCompareSurveysMutation,
  useRefreshMediaCommentsMutation,
} = media;

import {
  Text,
  Box,
  Card,
  Heading,
  Flex,
  SimpleGrid,
  HStack,
  CardBody,
  CardHeader,
  Tag,
  Spinner,
  Divider,
  List,
  ListItem,
  UnorderedList,
  useToken,
} from "@chakra-ui/react";
import CircularProgressBar from "../../util/CircularProgressBar.tsx";
import {
  useGetMediaInterestsQuery,
  useGetMediaItemTagsQuery,
  useGetMediaTopicSizingQuery,
} from "../../../store/services/mediaApi";
import { useNavigate, useParams } from "react-router";
import { LoadingOverlay, LoadingSpinner, NProgress } from "@saas-ui/react";
import {
  FiArrowDownLeft,
  FiArrowUpRight,
  FiBarChart,
  FiUser,
  FiDollarSign,
  FiCloudLightning,
  FiHash,
  FiFile,
  FiFilm,
} from "react-icons/fi";

import {
  BsEmojiLaughing,
  BsEmojiAngry,
  BsEmojiExpressionless,
  BsFillLightbulbFill,
} from "react-icons/bs";

import { PiGenderMaleBold } from "react-icons/pi";
import PercentageIndicator from "../../util/PercentageIndicator.js";
import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../../Table/DataTable";
import { useEffect, useState } from "react";
import {
  findObjectWithHighestValue,
  findTopNObjects,
} from "../../../utils/filter.js";
import FeatureFlag from "../../util/FeatureFlag.js";
import { BarChart, ResponsiveContainer, XAxis, Bar, YAxis } from "recharts";
import HorizontalBar from "../../util/HorizontalSingleBar.js";
import { getTagColor } from "../../../utils/util.js";
import { useNormSelector } from "../NormContext.js";

export const InterestDriverCard = () => {
  const { id } = useParams();
  const [topInterest, setTopInterest] = useState([]);

  const { selectedNorm } = useNormSelector();

  const { data, isLoading } = useGetMediaInterestsQuery({
    value: id,
    norm: selectedNorm,
  });
  useEffect(() => {
    if (data) {
      setTopInterest(
        findTopNObjects(
          data?.data?.map((ithPlatform) => ({
            ...ithPlatform?.overall,
            name: ithPlatform?.value,
          })),
          "interest_percent_delta",
          4
        )
      );
    }
  }, [data]);
  if (topInterest?.length === 0) {
    return <Box></Box>;
  }

  return (
    <Box mt="3" mb="5">
      <HStack color="gray.400" fontSize="md" justifyContent="space-between">
        <HStack size="sm">
          <FiFilm />
          <Text>Top Interest Drivers</Text>
        </HStack>
        <FeatureFlag
          beta={false}
          description="Users are asked what they are interested in when they're watching the video and this is the most popular response."
        />
      </HStack>

      {isLoading ? (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      ) : (
        <Box>
          {topInterest?.map((ithInter) => (
            <HStack key={ithInter?.name} alignItems="flex-end" mt="2" gap="3">
              <Text fontSize="sm">
                {(
                  Math.round(ithInter?.percentage_of_interest * 100) / 100
                ).toFixed(2)}
                %{" "}
              </Text>
              <Text fontSize="sm">{ithInter.name}</Text>
            </HStack>
          ))}
          {/* <pre>{JSON.stringify(topInterest, null, 2)}</pre> */}
        </Box>
      )}
    </Box>
  );
};

const CreativeAssesment = () => {
  const { id } = useParams();
  const { selectedNorm } = useNormSelector();
  const { data: tagData, isLoading: tagLoading } = useGetMediaItemTagsQuery({
    value: id,
    norm: selectedNorm,
  });

  const simpleData = tagData?.polarity?.options?.map((ithGroup) => ({
    label: ithGroup.toUpperCase(),
    value: tagData?.polarity?.data[ithGroup]?.overall.count,
    children: (
      <HStack
        alignContent="center"
        mb="3"
        mt="1"
        ml="4"
        fontSize="xs"
        fontWeight="bold"
        color="gray.500"
      >
        <Text>Count: {tagData?.polarity?.data[ithGroup]?.overall.count}</Text>
        <PercentageIndicator
          pt={0}
          number={
            tagData?.polarity?.data[ithGroup]?.overall.polarity_percent_delta
          }
          color={{ positve: "gray.500", negative: "gray.500" }}
        />
      </HStack>
    ),
    fill:
      ithGroup === "neutral"
        ? "yellow.300"
        : ithGroup === "positive"
        ? "green.400"
        : "red.300",
  }));

  const flattenedTags = tagData?.tags
    ?.map((ithTag) => ({
      value: ithTag?.value,
      ...ithTag?.overall,
    }))
    ?.sort((first, second) => {
      return second.tag_percent_delta - first.tag_percent_delta;
    });

  const negativeSentiment = [
    "Stupid",
    "Hate It",
    "Boring",
    "Weak",
    "Annoying",
    "Flop",
  ];
  const neutralSentiment = [
    "Mediocre",
    "Silly",
    "Basic",
    "Ok",
    "Like It",
    "Interesting",
  ];
  const positiveSentiment = [
    "Love It",
    "Creative",
    "Inspiring",
    "Hit",
    "Awesome",
    "Funny",
  ];

  return (
    <Box>
      {tagLoading ? (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      ) : (
        <>
          <HStack
            borderBottom="1px solid"
            borderColor="gray.100"
            justifyContent="space-between"
          >
            <Heading p="3" size="sm">
              Creative Tags
            </Heading>

            <FeatureFlag
              description={
                "Users are prompted to select from 18 tags, indicating their feelings and creative interest in the title. They have the flexibility to choose as many tags as they find relevant."
              }
            />
          </HStack>
          <Box p="5">
            <Box pb="4" mb="3" borderBottom="1px solid" borderColor="gray.100">
              <HStack fontSize="md" fontWeight="medium">
                <Text>Polarity</Text>
                <FeatureFlag description="Among all tags, each is categorized into positive, negative, or neutral sentiment. We present the proportion of tags within each sentiment group and the normalized count for each group. For instance, if the negative sentiment has a count of 70 and a -1.73 percent, it signifies that 63 tags with a negative sentiment were selected. This count is 1.73 percent lower than the proportion observed for other titles on Volkno." />
              </HStack>
              <HorizontalBar data={simpleData} />
            </Box>
            <Box pb="4" mb="3">
              <Text fontSize="md" fontWeight="medium">
                Top Tags
              </Text>
              <Text color="gray.600" fontSize="sm" mt="3">
                The following list comprises all available tags on our platform.
                The percentages represent the normalized representation of each
                tag, indicating its count relative to the mean or average count
              </Text>

              <Box my="3">
                {flattenedTags?.map(
                  (ithFlatTag, idx) =>
                    positiveSentiment.includes(ithFlatTag?.value) && (
                      <Tag m="1">
                        <HStack fontSize="xs">
                          <Text color={getTagColor(ithFlatTag?.value)}>
                            {ithFlatTag?.value}
                          </Text>
                          <Box justifySelf="center">
                            <PercentageIndicator
                              pt="0"
                              number={ithFlatTag?.tag_percent_delta}
                              color={{
                                positve: "gray.500",
                                negative: "gray.500",
                              }}
                            />
                          </Box>
                        </HStack>
                      </Tag>
                    )
                )}
              </Box>
              <Box my="3">
                {flattenedTags?.map(
                  (ithFlatTag, idx) =>
                    neutralSentiment.includes(ithFlatTag?.value) && (
                      <Tag m="1">
                        <HStack fontSize="xs">
                          <Text color={getTagColor(ithFlatTag?.value)}>
                            {ithFlatTag?.value}
                          </Text>
                          <Box justifySelf="center">
                            <PercentageIndicator
                              pt="0"
                              number={ithFlatTag?.tag_percent_delta}
                              color={{
                                positve: "gray.500",
                                negative: "gray.500",
                              }}
                            />
                          </Box>
                        </HStack>
                      </Tag>
                    )
                )}
              </Box>
              <Box my="3">
                {flattenedTags?.map(
                  (ithFlatTag, idx) =>
                    negativeSentiment.includes(ithFlatTag?.value) && (
                      <Tag m="1">
                        <HStack fontSize="xs">
                          <Text color={getTagColor(ithFlatTag?.value)}>
                            {ithFlatTag?.value}
                          </Text>
                          <Box justifySelf="center">
                            <PercentageIndicator
                              pt="0"
                              number={ithFlatTag?.tag_percent_delta}
                              color={{
                                positve: "gray.500",
                                negative: "gray.500",
                              }}
                            />
                          </Box>
                        </HStack>
                      </Tag>
                    )
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

const TopicSizing = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetMediaTopicSizingQuery(id);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("topic", {
      header: "Topic",
    }),

    columnHelper.accessor("percentage", {
      cell: (info) => `${Math.round(info.getValue() * 10) / 10}%`,
    }),
  ];

  return (
    <>
      {!isLoading && !!data ? (
        <Card p={4}>
          <HStack
            borderBottom="1px solid"
            borderColor="gray.100"
            justifyContent="space-between"
          >
            <Heading p="3" size="sm">
              Intrest Drivers and Deterrents from Open-ended Feedback
            </Heading>
            <FeatureFlag description="The most frequent topics mentioned across all open-ended feedback are listed here, depending on whether they were mentioned positvely or negatively" />
          </HStack>
          <Flex width="100%">
            <Box flex="1" mr={4} p={4}>
              <Text>Interest Drivers</Text>
              <DataTable
                data={data?.positive.overall}
                columns={columns}
                columnsToFilter={[]}
                isLoading={isLoading}
                simplify={true}
              />
            </Box>
            <Box flex="1" p={4}>
              <Text>Deterrents</Text>
              <DataTable
                data={data?.negative.overall}
                columns={columns}
                columnsToFilter={[]}
                isLoading={isLoading}
                simplify={true}
              />
            </Box>
          </Flex>
        </Card>
      ) : (
        <Text textAlign="center" fontSize="xl" p={4}>
          Topic sizing is not available for this title.
        </Text>
      )}
    </>
  );
};

const Creative = ({ mediaItemData }) => {
  const { id } = useParams();

  const { selectedNorm } = useNormSelector();

  const { data, isLoading } = useGetMediaInterestsQuery({
    value: id,
    norm: selectedNorm,
  });
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor("value", {
      header: "Interest",
      cell: (info) => info.getValue(),
    }),

    columnHelper.accessor("percent", {
      cell: ({ info, row }) =>
        `${
          Math.round(row?.original?.overall?.percentage_of_interest * 100) / 100
        } %`,
    }),
    columnHelper.accessor("norm", {
      cell: ({ info, row }) => (
        <PercentageIndicator
          number={row?.original?.overall?.interest_percent_delta}
        />
      ),
    }),
  ];

  return (
    <>
      {isLoading ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NProgress colorScheme="red" isAnimating position="absolute" />
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Flex direction="column" gap="4" maxWidth="100vw">
          <Flex gap="5">
            <Card flex={1}>
              <CreativeAssesment />
            </Card>
            {!isLoading && data?.data?.length !== 0 && (
              <Card>
                <HStack>
                  <Heading p="3" size="sm">
                    Creative Elements
                  </Heading>
                </HStack>
                <Flex flexDirection="row">
                  <Box
                    p="6"
                    borderTop="1px solid"
                    borderRight="1px solid"
                    borderColor="gray.100"
                  >
                    <HStack
                      color="purple.800"
                      fontSize="xs"
                      justifyContent="space-between"
                    >
                      <HStack>
                        <FiCloudLightning />
                        <Text>Free Response Summary</Text>
                      </HStack>
                      <FeatureFlag
                        beta={true}
                        description="Description of the feature"
                      />
                    </HStack>
                    <UnorderedList mt="3" color="gray.600" fontSize="sm">
                      {data?.comments_summary?.map((ithComment, idx) => (
                        <ListItem key={`comment-${idx}`} py="1">
                          <Text maxWidth="xs">{ithComment}</Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Box>
                  <Box flex={1} pb="3">
                    <DataTable
                      data={data?.data}
                      columns={columns}
                      columnsToFilter={[]}
                      isLoading={isLoading}
                      simplify={true}
                    />
                  </Box>
                </Flex>
              </Card>
            )}
          </Flex>
          <Flex gap="5">
            <Card flex={1}>
              <TopicSizing />
            </Card>
          </Flex>
        </Flex>
      )}
    </>
  );
};
export default Creative;

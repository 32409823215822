import React, { useEffect, useState } from "react";
import { Box, HStack, Text, VStack, useToken } from "@chakra-ui/react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Legend,
  Tooltip,
} from "recharts";
import FeatureFlag from "./FeatureFlag";
import { sortedData } from "../../utils/sort";

const DEFAULT_COLORS = ["#264653", "#2a9d8f", "#e9c46a", "#f4a261", "#e76f51"];

export const CustomTooltip = ({ active, payload, label, data }) => {
  if (active && payload && payload.length) {
    const selectedData = payload[0];
    const otherData = data?.filter(
      (ithValue) => ithValue?.label === selectedData.dataKey
    )[0];

    return (
      <Box
        zIndex={99999999}
        bg="whiteAlpha.800"
        boxShadow="md"
        border="1px solid"
        borderColor="gray.300"
        p="2"
      >
        <VStack>
          <HStack fontSize="xs">
            <Box
              borderRadius="full"
              height="2"
              width="2"
              bg={selectedData.fill}
            ></Box>
            <Text color="gray.600">{selectedData.dataKey}</Text>
            {otherData?.children}
          </HStack>
          <Box></Box>
        </VStack>
      </Box>
    );
  }

  return null;
};
const HorizontalBar = ({
  data,
  barHeight,
  legend = false,
  children,
  layout = "vertical", // New parameter to control the layout
}) => {
  const [newData, setNewData] = useState([]);

  // Default color scheme if not provided
  const colors = useToken(
    "colors",
    (
      data?.map((ithColorVal) => ithColorVal?.color || ithColorVal?.fill) ||
      DEFAULT_COLORS
    ).every((color) => color === undefined)
      ? DEFAULT_COLORS
      : data?.map((ithColorVal) => ithColorVal?.color || ithColorVal?.fill)
  );

  // Default bar height if not provided
  const height = barHeight || "26px";
  const numDataPoints = data ? data.length : 0;

  const transformAndSortData = (data) => {
    const transformedData = sortedData(data, "label")?.reduce(
      (acc, { label, value }) => {
        acc[label] = value;
        return acc;
      },
      { name: "name" }
    );
    return transformedData;
  };

  useEffect(() => {
    setNewData([transformAndSortData(data)]);
  }, [data]);

  const getColor = (index) => {
    // Check if the color is defined in the data; if not, use the default colors
    if (data[index]?.color) {
      return data[index].color;
    }
    if (numDataPoints <= 3) {
      if (index === 0) return colors[0];
      if (index === numDataPoints - 1) return colors[2];
      return colors[1];
    } else {
      const colorIndex = index % colors.length;
      return colors[colorIndex];
    }
  };

  return (
    <Box>
      <Box width="100%" height={height} mt="3">
        <ResponsiveContainer>
          <BarChart
            margin={0}
            data={newData}
            layout="vertical"
            stackOffset="expand"
            barCategoryGap={2}
            barGap={2}
          >
            <XAxis hide type="number" />
            <YAxis type="category" dataKey="name" hide />
            <Tooltip content={<CustomTooltip data={data} />} shared={false} />
            {data?.map((key, index) => (
              <Bar
                key={`${key?.label}-${index}-bar`}
                radius={[
                  index === 0 ? 5 : 0,
                  index === data?.length - 1 ? 5 : 0,
                  index === data?.length - 1 ? 5 : 0,
                  index === 0 ? 5 : 0,
                ]}
                dataKey={key?.label}
                stackId="a"
                fill={getColor(index)}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box mt="2">
        {layout === "horizontal" ? (
          <HStack spacing="2">
            {data?.map((ithValue, index) => (
              <Box key={`legend-${ithValue.label}-${index}`}>
                <HStack fontSize="xs" color="gray.600">
                  <Box
                    borderRadius="full"
                    height="2"
                    width="2"
                    bg={getColor(index)}
                  ></Box>
                  <Text>{ithValue?.label}</Text>
                  {ithValue?.description && (
                    <FeatureFlag description={ithValue?.description} />
                  )}
                </HStack>
                {ithValue?.children}
              </Box>
            ))}
          </HStack>
        ) : (
          <Box>
            {data?.map((ithValue, index) => (
              <Box key={`legend-${ithValue.label}-${index}`}>
                <HStack fontSize="xs" color="gray.600">
                  <Box
                    borderRadius="full"
                    height="2"
                    width="2"
                    bg={getColor(index)}
                  ></Box>
                  <Text>{ithValue?.label}</Text>
                  {ithValue?.description && (
                    <FeatureFlag description={ithValue?.description} />
                  )}
                </HStack>
                {ithValue?.children}
              </Box>
            ))}
          </Box>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default HorizontalBar;

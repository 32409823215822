import { Box, Flex, HStack, Image, Text } from "@chakra-ui/react";
import { useGetMediaQuery } from "../../store/services/mediaApi";
import { useEffect } from "react";
import DataTable from "../../Table/DataTable";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate, useParams } from "react-router";
import { LoadingOverlay, LoadingSpinner } from "@saas-ui/react";
import { MediaTypeTag } from "../util/Tags";
import moment from "moment";

const Media = () => {
  const { client } = useParams();
  const { data, isLoading } = useGetMediaQuery(client);
  const columnHelper = createColumnHelper();
  const navigate = useNavigate();

  const columns = [
    columnHelper.accessor("title", {
      cell: (info) => {
        return (
          <HStack>
            {/* <Image
              width="35px"
              borderRadius="md"
              src={info.row.original?.poster_url}
              boxShadow="md"
            /> */}
            <Box>
              <Text fontSize="md" fontWeight="bold" mb="0.5">
                {info.getValue()}
              </Text>
              <Text>{info.row.original?.subtitle}</Text>
            </Box>
          </HStack>
        );
      },
    }),
    // columnHelper.accessor("subtitle", {
    //   cell: (info) => info.getValue(),
    // }),
    columnHelper.accessor("date_added", {
      header: "Date Added",
      cell: (info) => moment.utc(info.getValue()).format("LL"),
    }),
    columnHelper.accessor("media_type_id", {
      header: "Type",
      cell: (info) => <MediaTypeTag mediaType={info.getValue()} />,
    }),
  ];

  return (
    <Box height="100vh">
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {isLoading ? (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      ) : (
        <DataTable
          handleLink={(row) =>
            navigate(
              `/${client}/media/${row?.original?.media_item_id}/overview`
            )
          }
          data={data}
          title="Your Media"
          columns={columns}
          columnsToFilter={[]}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default Media;

import { Card, Flex, HStack } from "@chakra-ui/react";

import {
  TopicSizing,
  SentimentTotals,
  SentimentAreaChart,
} from "../metrics/Sentiment.js";
import { OptionButtons, useOpenEndedSource } from "../OpenEndedContext.js";
import { EmptyState } from "@saas-ui/react";

const Sentiment = ({ mediaItemData }) => {
  const { openEnded, topicSizing, isLoading } = useOpenEndedSource();

  if (!isLoading && !openEnded && !topicSizing) {
    return (
      <EmptyState
        title="The sentiment data is not available."
        description="Please contact us if you think this is an error."
      />
    );
  }

  return (
    <Flex direction="column" gap={5}>
      <OptionButtons />

      {openEnded.length > 0 && (
        <Card mb="4" w="100%">
          <HStack fontSize="md" justifyContent="space-between">
            <SentimentTotals />
            <SentimentAreaChart />
          </HStack>
        </Card>
      )}
      {topicSizing && <TopicSizing />}
    </Flex>
  );
};

export default Sentiment;

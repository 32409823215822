import {
  Flex,
  VStack,
  Text,
  HStack,
  Box,
  Button,
  Card,
} from "@chakra-ui/react";
import { EmptyState, LoadingOverlay, LoadingSpinner } from "@saas-ui/react";

import { SentimentBadge, SentimentTags } from "../metrics/Sentiment.js";
import DataTable from "../../../Table/DataTable.js";
import { createColumnHelper } from "@tanstack/react-table";
import { OptionButtons, useOpenEndedSource } from "../OpenEndedContext.js";
import { useSummarizeQuestionsMutation } from "../../../store/services/mediaApi.js";
import { useParams } from "react-router";
import { FiCloudLightning } from "react-icons/fi";

const getUniqueTopicValues = (rows) => {
  const uniqueTopics = new Set();

  rows.forEach((row) => {
    const topics = row.original.topics || [];
    topics.forEach((topic) => {
      if (topic.topic) uniqueTopics.add(topic.topic);
    });
  });

  return Array.from(uniqueTopics);
};

const TableSummarizer = ({ title, table = {} }) => {
  const [postOpenEndSummary, res] = useSummarizeQuestionsMutation();
  const { id } = useParams();

  if (!table.getFilteredRowModel) {
    return null;
  }

  const filteredRows = table.getFilteredRowModel().rows || [];

  const handleSummarize = async () => {
    const comments = filteredRows.map((row) => row.original.comment);
    postOpenEndSummary({
      media_item_id: id,
      question: "Open-ended Feedback",
      responses: comments,
      title: title?.title,
    });
  };

  return (
    <Box p={4} borderRadius="md" maxHeight="200px" overflow="auto">
      <Button
        onClick={handleSummarize}
        isLoading={res.isLoading}
        leftIcon={<FiCloudLightning />}
        variant="ghost"
        color="black"
        isDisabled={
          !filteredRows.length || res.isLoading || filteredRows.length < 2
        }
      >
        Summarize
      </Button>

      {!res.isLoading && res.data && (
        <Card p="4">
          <HStack fontSize="sm" color="purple.400" mb="3">
            <FiCloudLightning />
            <Text>Generative Summary</Text>
          </HStack>
          <Text fontSize="xs">{res?.data?.data}</Text>
        </Card>
      )}
    </Box>
  );
};
const OpenEnd = ({ mediaItemData }) => {
  const { openEnded: data, isLoading } = useOpenEndedSource();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: "Comment",
      header: "Comment",
      cell: ({ row }) => (
        <VStack justifyContent="start" alignItems="start" gap={2}>
          <Text>{row?.original?.comment}</Text>
          <SentimentTags tags={row?.original?.topics} />
        </VStack>
      ),
    }),
    columnHelper.accessor("topics", {
      header: "Topic",
      isHidden: true,
      filterFn: (row, id, filterValue) => {
        const topics = row.original.topics || [];

        return topics.some(
          (topic) =>
            topic.topic.includes(filterValue) || topic.type === filterValue
        );
      },
      getFilterValue: (rows) => getUniqueTopicValues(rows), // Get unique topic values for filtering
    }),

    columnHelper.accessor("sentiment", {
      header: "Sentiment",
      cell: (info) => <SentimentBadge sentiment={info.getValue()} />,
    }),
  ];

  if (isLoading) {
    return (
      <LoadingOverlay>
        <LoadingSpinner />
      </LoadingOverlay>
    );
  }

  return (
    <Flex direction="column" height="100%" overflowY="auto">
      {data.length > 0 ? (
        <DataTable
          data={data}
          columns={columns}
          columnsToFilter={["sentiment", "topics"]}
          isLoading={isLoading}
          tableSummary={<TableSummarizer title={mediaItemData} />}
        >
          <OptionButtons />
        </DataTable>
      ) : (
        <EmptyState
          title="No Open-ended Feedback"
          description="There are no open-ended feedback for this media item"
          actions={<OptionButtons mediaItemData={mediaItemData} />}
        />
      )}
    </Flex>
  );
};

export default OpenEnd;

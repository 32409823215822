import {
  Button,
  ListItem,
  List,
  Badge,
  Box,
  HStack,
  Text,
} from "@chakra-ui/react";
import { SearchInput } from "@saas-ui/react";
import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { publishDate, isValidISO, createDateObjects } from "../utils/date";

import { useSearchParams } from "react-router-dom";
import { SentimentBadge } from "../components/media/metrics/Sentiment";
const DATE_DISTANCES = [1, 2, 4, 7, 10, 21, 30, 60, 365];
export const FilteredValues = ({ columnsToFilter, table }) => {
  const handleDelete = (column) => {
    column.setFilterValue("");
  };

  return (
    <HStack>
      {columnsToFilter?.map((col) => {
        const column = table.getColumn(col);
        const fVal = column.getFilterValue();

        return (
          fVal && (
            <Button
              key={`${col}-f${fVal}`}
              variant="outline"
              onClick={() => handleDelete(column)}
              m={4}
              color="black"
              size="xs"
            >
              <HStack>
                <Text mr="1">{column.columnDef?.header}</Text>
                <p>is</p>
                <Text>{fVal}</Text>
                <FiX />
              </HStack>
            </Button>
          )
        );
      })}
    </HStack>
  );
};

export const ColumnFilter = ({ column, table, close = () => {} }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const firstValue = table
    ?.getPreFilteredRowModel()
    ?.flatRows[0]?.getValue(column.id);

  const isISO = isValidISO(firstValue) && column?.id !== "site";

  const handleSetFilter = (value) => {
    column.setFilterValue(value);
    close();
  };

  const sortedUniqueValues = React.useMemo(() => {
    const getFilterValueFn = column.columnDef?.getFilterValue;

    let uniqueValues;

    if (typeof getFilterValueFn === "function") {
      const allRows = table.getPreFilteredRowModel().flatRows;
      uniqueValues = getFilterValueFn(allRows);
    } else {
      uniqueValues = Array.from(column.getFacetedUniqueValues()?.keys() || []);
    }

    // Handle different data types for unique values
    if (typeof firstValue === "undefined") {
      return [];
    } else if (Array.isArray(firstValue)) {
      uniqueValues = [...new Set(uniqueValues.flat())];
    } else if (typeof firstValue === "object") {
      uniqueValues = [...new Set(uniqueValues.map((obj) => obj?.email))];
    } else if (isISO) {
      uniqueValues = createDateObjects(DATE_DISTANCES);
    }

    // Apply search filtering
    return uniqueValues
      .filter((value) => {
        const displayValue = isISO ? value?.name : value?.name || value;
        return displayValue
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      })
      .sort();
  }, [column, firstValue, isISO, searchQuery, table]);

  return (
    <Box>
      <SearchInput
        border="none"
        borderBottom="1px"
        borderBottomColor="gray.200"
        borderRadius="none"
        value={searchQuery}
        onChange={(event) => setSearchQuery(event.target.value)}
        onReset={() => setSearchQuery("")}
        placeholder="Search..."
      />

      <List p={0}>
        {sortedUniqueValues?.map((value, index) => (
          <ListItem
            key={`col_filt-${index}`}
            onClick={() => handleSetFilter(isISO ? value?.date : value)}
            _hover={{ bg: "gray.100", cursor: "pointer" }}
            py="2"
            px="1"
          >
            <Box>
              {column.id === "sentiment" ? (
                <HStack justifyContent="center">
                  <SentimentBadge sentiment={value} />
                </HStack>
              ) : (
                <Text fontSize="xs">{value?.name || value}</Text>
              )}
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export const GlobalFilter = ({ value, filterFn }) => (
  <SearchInput
    placeholder="Search"
    value={value}
    onChange={(e) => filterFn(e.target.value)}
    onReset={() => filterFn("")}
  />
);

import {
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from "@chakra-ui/react";
import {
  ErrorBoundary,
  NavItem,
  Persona,
  PersonaAvatar,
  SidebarSection,
  PersonaDetails,
  PersonaSecondaryLabel,
  useModals,
} from "@saas-ui/react";
import { AppShell } from "@saas-ui/react";
import { Sidebar } from "@saas-ui/react";
import {
  FiChevronDown,
  FiHome,
  FiLogOut,
  FiPlusCircle,
  FiRepeat,
} from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { COLOR_DEFAULTS } from "../defaults";
import { useAuth0 } from "@auth0/auth0-react";
import useUserHasRoles from "../auth/useRolesHook";
import { AddUser } from "./user";
import { NormSelectorProvider } from "./media/NormContext";
import { CompareProvider } from "./media/compare/CompareMedia";

const Portal = ({ element }) => {
  const { client } = useParams();
  const { logout } = useAuth0();

  const { roles: permissionData, isLoading, user } = useUserHasRoles();
  const { isValidUser: isSuper } = useUserHasRoles(["super"]);
  const modal = useModals();

  const [selectedWorkspace, setSelectedWorkspace] = useState();

  const navigate = useNavigate();
  const handleWorkspaceSelect = (i) => {
    setSelectedWorkspace(i);
    navigate(`/${i.client_id}/home`);
  };

  useEffect(() => {
    const clientParamFilter =
      permissionData?.client?.filter((u) => client == u?.client_id) || [];
    if (client && !isLoading && clientParamFilter.length > 0) {
      setSelectedWorkspace(clientParamFilter[0]);
    } else if (permissionData && !isLoading) {
      navigate(`/${permissionData.client[0].client_id}/home`);
      setSelectedWorkspace(permissionData.client[0]);
    } else if (permissionData?.length === 0 && !isLoading) {
      navigate("/");
      alert("You don't have permission :(");
    }
  }, [permissionData, user, isLoading]);

  return (
    <NormSelectorProvider>
      <CompareProvider>
        <Flex height="100vh" direction="column">
          <HStack
            bg="gray.900"
            py="2"
            px="8"
            boxShadow="lg"
            justifyContent="space-between"
          >
            <Box>
              <Image src="/Logo.png" height="30px" />
            </Box>
            <Menu>
              {({ isOpenMenu, isClosed }) => (
                <>
                  <MenuButton
                    px="5"
                    pb="2"
                    color="white"
                    _hover={{ cursor: "pointer" }}
                  >
                    <HStack
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Persona size="md">
                        <PersonaAvatar
                          name={selectedWorkspace?.name}
                          src={
                            selectedWorkspace?.name === "Netflix" && "/nflx.png"
                          }
                          borderRadius="md"
                          boxShadow="md"
                          size="sm"
                        />
                        <PersonaDetails>
                          <Flex direction="column" alignItems="flex-start">
                            <PersonaSecondaryLabel>
                              Workspace
                            </PersonaSecondaryLabel>
                            <Text fontWeight="medium">
                              {selectedWorkspace?.name}
                            </Text>
                          </Flex>
                        </PersonaDetails>
                      </Persona>
                      <Box alignSelf="center">
                        <FiChevronDown />
                      </Box>
                    </HStack>
                  </MenuButton>

                  <MenuList>
                    {permissionData?.client?.map((ithWorkspaceOption, idx) => (
                      <MenuItem
                        key={ithWorkspaceOption?.client_id}
                        onClick={() =>
                          handleWorkspaceSelect(ithWorkspaceOption)
                        }
                        icon={
                          <Badge
                            bg={
                              COLOR_DEFAULTS[
                                idx < COLOR_DEFAULTS.length ? idx : 0
                              ]
                            }
                            boxSize="2"
                            borderRadius="full"
                          />
                        }
                      >
                        {ithWorkspaceOption?.name}
                      </MenuItem>
                    ))}
                  </MenuList>
                </>
              )}
            </Menu>
          </HStack>
          <AppShell
            variant="static"
            flex="1"
            sidebar={
              <Sidebar
                boxShadow="inset -5px 0 10px -10px rgba(0, 0, 0, 0.1)" // Add an inner shadow to the right
                width="200px"
              >
                {!isLoading && (
                  <>
                    <SidebarSection>
                      <NavItem
                        icon={<FiHome />}
                        to={`/${client}/home`}
                        as={Link}
                      >
                        Media
                      </NavItem>
                      {/* <NavItem
                    icon={<FiPlusCircle />}
                    to={`/${client}/create-media`}
                    as={Link}
                  >
                    Create Project
                  </NavItem> */}
                      <NavItem
                        icon={<FiRepeat />}
                        to={`/${client}/compare/overview`}
                        as={Link}
                      >
                        Compare
                      </NavItem>
                    </SidebarSection>

                    <Spacer />
                    <SidebarSection>
                      <NavItem
                        icon={<FiPlusCircle />}
                        onClick={() =>
                          modal.open({ body: <AddUser client={client} /> })
                        }
                      >
                        Invite Team
                      </NavItem>
                    </SidebarSection>
                    <HStack justifyContent="space-between" px="4">
                      <Box overflowX="clip">
                        <Persona
                          size="sm"
                          name={user?.name}
                          secondaryLabel={user?.nickname}
                          // cursor="pointer"
                          // onClick={() => navigate(`/${study}/settings/details`)}
                          src={user?.picture}
                          referrerPolicy="no-referrer"
                        />
                      </Box>
                      <IconButton
                        variant="outline"
                        onClick={() =>
                          logout({
                            returnTo: window.location.origin,
                          })
                        }
                        aria-label="User Account Options"
                        icon={<FiLogOut />}
                      />
                    </HStack>
                  </>
                )}
              </Sidebar>
            }
          >
            <Box overflowY="scroll" height="100%">
              <ErrorBoundary>{element}</ErrorBoundary>
            </Box>
          </AppShell>
        </Flex>
      </CompareProvider>
    </NormSelectorProvider>
  );
};

export default Portal;

import {
  add,
  format,
  formatDistance,
  formatDistanceToNow,
  isValid,
  minutesToHours,
  parseISO,
} from "date-fns";

import { Text, Tooltip, useClipboard, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export const PublishDistance = ({ date }) => {
  var dateObj;
  if (date !== undefined) {
    dateObj = new Date(date);
  } else {
    dateObj = new Date();
  }
  const distance = formatDistanceToNow(dateObj, {
    addSuffix: true,
  });
  const formatteddate = format(dateObj, "Pp");
  const { onCopy, value, hasCopied } = useClipboard(formatteddate);
  const toast = useToast();

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: "Copied",
        description: `${value} has been copied`,
        status: "success",
      });
    }
  }, [hasCopied]);

  return (
    <Tooltip label={formatteddate}>
      <Text
        onClick={onCopy}
        cursor="pointer"
        _hover={{ textDecoration: "underline" }}
      >
        {distance}
      </Text>
    </Tooltip>
  );
};

export const publishDate = (date) => {
  try {
    var dateObj = new Date(date);
    return format(dateObj, "MM/dd/yyyy hh:mm a");
  } catch (error) {
    return format(new Date(), "HH:mm");
  }
};

export const datePublisher = ({ date, form }) => {
  try {
    var dateObj = new Date(date);
    return formatDistanceToNow(dateObj, {
      addSuffix: true,
    });
  } catch (error) {
    return "nan";
  }
};
export const distanceDate = ({ date, form }) => {
  try {
    var dateObj = new Date(date);
    return format(dateObj, form);
  } catch (error) {
    return format(new Date(), form);
  }
};

export const TableDateObj = ({ date, form }) => {
  const { onCopy, value, hasCopied } = useClipboard(
    datePublisher({ date: date, form: "MM/dd/yy HH:mm" })
  );
  const toast = useToast();

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: "Copied",
        description: `${value} has been copied`,
        status: "success",
      });
    }
  }, [hasCopied]);

  return (
    <Tooltip label={datePublisher({ date: date, form: "MM/dd/yy HH:mm" })}>
      <Text
        onClick={onCopy}
        cursor="pointer"
        _hover={{ textDecoration: "underline" }}
      >
        {datePublisher({ date: date, form: form })}
      </Text>
    </Tooltip>
  );
};

export const getCurrentDate = () => {
  return format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
};

export const publishDateTime = (date) => {
  try {
    var dateObj = new Date(date);
    return format(dateObj, "Pp");
  } catch (error) {
    return format(new Date(), "Pp");
  }
};

export const publishMinutes = (minutes) => {
  if (minutes > 60) {
    return `${minutesToHours(minutes)} hours`;
  } else {
    return `${minutes} minutes`;
  }
};

export const displayRangeFromDate = (minutesFrom, date) => {
  var dateObj = new Date(date);
  return format(add(dateObj, { minutes: minutesFrom }), "MM/dd/yyyy hh:mm");
};

export const createDateObjects = (daysAway) => {
  const dateObjects = daysAway.map((days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    const name = formatDistance(date, new Date(), { addSuffix: true });

    return {
      name: name,
      date: date,
    };
  });

  return dateObjects;
};
export function isValidISO(isoString) {
  // Try to parse the string as a date in ISO 8601 format
  try {
    const date = parseISO(isoString);

    // Check if the date is valid
    return isValid(date);
  } catch (e) {
    return false;
  }
}

import { HStack } from "@chakra-ui/react";
import { Form, FormLayout, SubmitButton, TextareaField } from "@saas-ui/react";
import { useInviteUserMutation } from "../store/services/appAPI";

export const AddUser = ({ client }) => {
  const [submitUsers, { isLoading }] = useInviteUserMutation();

  const handleSubmit = async (formValues) => {
    const usersArray = formValues.users.split(",").map((user) => user.trim());
    const values = {
      ...formValues,
      emails: usersArray,
      client: client,
    };

    submitUsers(values);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <TextareaField
        isRequired={true}
        name="users"
        label="Emails to Invite"
        placeholder="email@email.com, email2@email.com"
      />
      <HStack width="100%" justifyContent="flex-end" mt="3">
        <SubmitButton isLoading={isLoading} />
      </HStack>
    </Form>
  );
};

import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  IconButton,
  HStack,
  Text,
  Image,
  Badge,
  Flex,
} from "@chakra-ui/react";
import {
  DisplayIf,
  Field,
  FormLayout,
  createField,
  useFieldArray,
  useFormContext,
  useModals,
  Select,
  SelectButton,
  SelectList,
} from "@saas-ui/react";
import { FiImage, FiPlus, FiToggleRight, FiTrash2, FiX } from "react-icons/fi";
import {
  FileUpload,
  FileUploadDropzone,
  FileUploadTrigger,
} from "@saas-ui/file-upload";
import { useGetCastCrewQuery } from "../../store/services/mediaApi";
import { ReactMultiSelect } from "../util/AsyncSelect";
import SurveySelector from "./SurveyOptions";

export const SurveyStep = ({ PrevButton, NextButton }) => {
  const { control, register, watch, setValue } = useFormContext();

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const fieldControls = useFieldArray({
    control,
    name: "questions",
  });

  return (
    <Flex direction="row" gap="4">
      <Box>
        <SurveySelector
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
          {...fieldControls}
        />
      </Box>

      <SurveyForm
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
        {...fieldControls}
      />
    </Flex>
  );
};

export const QuestionDisplay = () => {
  const { watch } = useFormContext();

  const questions = watch("questions");

  return (
    <Box
      py="2"
      bg="white"
      borderRadius="md"
      shadow="sm"
      border="1px solid"
      borderColor="gray.200"
    >
      <HStack px="4" borderBottom={"1px solid"} borderColor={"gray.200"}>
        <Text fontSize="md" fontWeight="medium" pb="2">
          Survey Questions
        </Text>
      </HStack>

      {questions?.map((ithQuestion, i) => (
        <HStack px="4" key={`question-${i}`} justifyContent="space-between">
          <Text my="1" fontSize="xs" noOfLines={1}>
            {i + 1}. {ithQuestion?.message}
          </Text>
          <Badge fontSize="xs" borderRadius={"sm"} colorScheme="blue">
            {ithQuestion?.type}
          </Badge>
        </HStack>
      ))}
    </Box>
  );
};

const CastCrewOptions = ({ name, questionIndex, control, type }) => {
  const { watch, setValue, getFieldState } = useFormContext();
  const selected_media = watch("selected_media");

  const { data, isLoading } = useGetCastCrewQuery(selected_media, {
    skip: !selected_media,
  });

  return (
    <HStack>
      {isLoading ? (
        <Text>Loading...</Text>
      ) : (
        <ReactMultiSelect
          type="select"
          name={name}
          onChange={(i) => setValue(name, i.value)}
          options={data?.[type]?.map((i) => ({ label: i.name, value: i.name }))}
        />
      )}
    </HStack>
  );
};

export const QuestionOptions = ({
  name = "options",
  label = "Options",
  questionIndex,
  control,
  modal,
}) => {
  const [questionType, setQuestionType] = useState("text");

  const { fields, append, remove } = useFieldArray({
    control,
    name: `questions[${questionIndex}].${name}`,
  });

  return (
    <VStack width={"100%"} alignItems={"flex-start"} spacing={4}>
      <HStack width={"100%"} justifyContent={"space-between"}>
        <Text>{label}</Text>
        <HStack spacing={2}>
          <Button
            variant={"ghost"}
            leftIcon={<FiPlus />}
            onClick={() => append({ option: "" })}
          >
            Add Option
          </Button>
          <Select
            size="xs"
            value={questionType}
            onChange={(value) => setQuestionType(value)}
            defaultValue={"text"}
            options={[
              { label: "Text", value: "text" },
              { label: "Image", value: "image" },
              { label: "Scene", value: "scene" },
              { label: "Cast", value: "cast" },
              { label: "Crew", value: "crew" },
            ]}
          >
            <SelectList />
            <SelectButton />
          </Select>
        </HStack>
      </HStack>

      {fields.map((field, index) => (
        <HStack key={field.id} width={"100%"}>
          <Box flex={3}>
            {questionType === "text" && (
              <Input
                placeholder={`Option ${index + 1}`}
                {...control.register(
                  `questions[${questionIndex}].options[${index}].option`
                )}
              />
            )}

            {(questionType === "cast" || questionType === "crew") && (
              <CastCrewOptions
                name={`questions[${questionIndex}].options[${index}].option`}
                questionIndex={index}
                control={control}
                type={questionType}
              />
            )}

            {questionType === "image" && (
              <HStack width="100%">
                <Text fontSize={"xs"}>{`${index + 1}.`}</Text>
                <Box width="100%">
                  <ImageUpload
                    modal={modal}
                    name={`questions[${questionIndex}].options[${index}].image`}
                    control={control}
                    questionIndex={index}
                  />
                </Box>
              </HStack>
            )}
            {questionType === "scene" && (
              <SceneSelector
                name={`questions[${questionIndex}].options[${index}].scene`}
              />
            )}
          </Box>
          <HStack flex={1}>
            <IconButton
              aria-label="Remove option"
              icon={<FiTrash2 />}
              onClick={() => remove(index)}
              ml={2}
            />
          </HStack>
        </HStack>
      ))}
    </VStack>
  );
};

const SceneSelector = ({ name }) => {
  const { watch } = useFormContext();

  const scenes = watch("scenes");

  return (
    <>
      <Field name={name} type="select" options={scenes?.map((i) => i.name)} />
    </>
  );
};

const ImageUpload = ({
  name,
  questionIndex,
  onChange = (file) => {},
  modal,
  ...rest
}) => {
  const [preview, setPreview] = useState(null); // State to hold the image preview URL

  const handleFileChange = (files) => {
    const file = files.acceptedFiles[0];

    if (!file) return;

    onChange(file);
    const previewUrl = URL.createObjectURL(file);
    setPreview(previewUrl); // Update the preview URL state
  };

  return (
    <FileUpload
      name={name}
      maxFileSize={1024 * 1024 * 1000} // Adjusted to 1 GB size limit
      {...rest}
      maxFiles={1}
      accept="image/*"
      onFilesChange={(files) => handleFileChange(files)} // Added onChange handler to update the preview
    >
      {({ files, deleteFile }) => (
        <FileUploadDropzone padding={1} width="100%">
          {!files?.length ? (
            <FileUploadTrigger>
              <Text fontSize="xs" color={"gray.500"}>
                Drag Image
              </Text>
            </FileUploadTrigger>
          ) : (
            <HStack spacing={4}>
              <Image
                src={preview}
                alt="Uploaded Image Preview"
                boxSize="50px"
                objectFit="contain"
              />
              <IconButton
                size="xs"
                variant="ghost"
                icon={<FiX />}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteFile(files[0]);
                  setPreview(null); // Also clear the preview state
                }}
              />
            </HStack>
          )}
        </FileUploadDropzone>
      )}
    </FileUpload>
  );
};

const SurveyForm = (props) => {
  const { control, register, watch, setValue } = useFormContext();

  const { setSelectedTemplate, selectedTemplate, append, fields, remove } =
    props;

  const modal = useModals();
  const scenes = watch("scenes");

  // useEffect(() => {
  //   if (selectedTemplate) {
  //     replace([]);

  //     const questions = JSON.parse(selectedTemplate);

  //     questions.forEach((question) => {
  //       append({
  //         ...question,
  //         options: question.options?.map((option) => ({
  //           option: option.message,
  //         })),
  //       });
  //     });
  //   }
  // }, [selectedTemplate]);

  return (
    <VStack spacing={4} width={"100%"}>
      <HStack
        p="2"
        bg={"white"}
        shadow="sm"
        borderRadius="md"
        width={"100%"}
        justifyContent={"space-between"}
        border={"1px solid"}
        borderColor="gray.200"
      >
        <Text>Questions</Text>
        <HStack spacing={2}>
          <Button
            variant="ghost"
            onClick={() => {
              setValue("survey_selection_type", "");
              setSelectedTemplate({});
              setValue("questions", []);
            }}
          >
            Clear Questions
          </Button>
          <Button
            leftIcon={<FiPlus />}
            variant={"outline"}
            onClick={() => append({ question: "", type: "", options: [] })}
          >
            Add Question
          </Button>
        </HStack>
      </HStack>{" "}
      {fields.map((field, index) => (
        <Box
          width={"100%"}
          key={field.id}
          p={5}
          shadow="md"
          bg="white"
          borderWidth="1px"
          borderRadius="md"
        >
          <HStack justifyContent="space-between" alignItems="start" spacing={5}>
            <Box>{index + 1}.</Box>
            <Box flex={3}>
              <Field
                label="Question"
                isRequired
                name={`questions[${index}].message`}
              />

              <HStack
                mt="4"
                width={"75%"}
                justifyContent="space-between"
                alignItems={"start"}
              >
                <DisplayIf
                  name={`questions[${index}].type`}
                  condition={(type) => type !== "long_text"}
                >
                  <QuestionOptions
                    label="Options"
                    questionIndex={index}
                    control={control}
                    modal={modal}
                  />
                </DisplayIf>
                <DisplayIf
                  name={`questions[${index}].type`}
                  condition={(type) => type === "grid"}
                >
                  <QuestionOptions
                    name="sub_options"
                    label="Sub Options"
                    questionIndex={index}
                    control={control}
                    modal={modal}
                  />
                </DisplayIf>
              </HStack>
            </Box>

            <Box flex={1}>
              <FormLayout>
                <Field
                  isRequired
                  label="Question Type"
                  type="select"
                  name={`questions[${index}].type`}
                  options={[
                    { value: "long_text", label: "Free Response" },
                    {
                      value: "grid",
                      label: "Grid",
                    },
                    {
                      value: "multiple_choice",
                      label: "Multiple Choice",
                    },
                    { value: "single_choice", label: "Single Choice" },
                  ]}
                />
                <Field
                  isRequired
                  label="Question Placement"
                  type="select"
                  name={`questions[${index}].placement`}
                  options={[
                    { value: "before", label: "Before Video" },
                    { value: "after", label: "After Video" },
                    { value: "before_scene", label: "Before Scene" },
                    { value: "after_scene", label: "After Scene" },
                    { value: "during", label: "During Video" },
                  ]}
                />
                <DisplayIf
                  name={`questions[${index}].placement`}
                  condition={(type) =>
                    type === "before_scene" || type === "after_scene"
                  }
                >
                  <Field
                    isRequired
                    label="Scene Placement"
                    type="select"
                    options={scenes?.map((i) => ({
                      value: i.id,
                      label: i.name,
                    }))}
                    name={`questions[${index}].timestamp`}
                  />
                </DisplayIf>

                <DisplayIf
                  name={`questions[${index}].placement`}
                  condition={(type) => type === "during"}
                >
                  <Field
                    isRequired
                    label="Timestamp"
                    type="text"
                    name={`questions[${index}].timestamp`}
                  />
                </DisplayIf>
              </FormLayout>
            </Box>
          </HStack>

          <HStack justifyContent="space-between" mt="4">
            <HStack>
              <Field
                type="checkbox"
                label="Required"
                name={`questions[${index}].required`}
              />
              <Field
                type="checkbox"
                defaultChecked
                label="Randomize"
                name={`questions[${index}].randomize`}
              />
            </HStack>
            <IconButton
              type="button"
              colorScheme="red"
              icon={<FiTrash2 />}
              onClick={() => remove(index)}
            />
          </HStack>
        </Box>
      ))}
    </VStack>
  );
};

export default SurveyForm;

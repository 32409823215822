import {
  Box,
  Card,
  Flex,
  useDisclosure,
  HStack,
  Spinner,
  Text,
  SimpleGrid,
  IconButton,
  Grid,
  GridItem,
  useToken,
  VStack,
} from "@chakra-ui/react";

import {
  CommandBar,
  CommandBarContent,
  CommandBarInput,
  CommandBarList,
  CommandBarItem,
  CommandBarLoading,
  CommandBarEmpty,
} from "@saas-ui/command-bar";
import {
  EmptyState,
  Banner,
  BannerTitle,
  BannerDescription,
} from "@saas-ui/react";
import React, { createContext, useContext, useState, useEffect } from "react";
import { FiX } from "react-icons/fi";
import { useNavigate, useParams } from "react-router";
import {
  useCompareAudienceMutation,
  useCompareCompositeSentimentMutation,
  useCompareEmotionMutation,
  useCompareInterestsMutation,
  useGetMediaQuery,
} from "../../../store/services/mediaApi";
import { MdMovie } from "react-icons/md";
import { MediaTypeTag } from "../../util/Tags";
import useFormSnackbar from "../../util/FormSnackbar";
import TabManager from "../../util/TabManager";
import { AudienceBase } from "../views/Audience";
import { EmotionChart } from "../views/Emotion";
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from "recharts";
import { useNormSelector } from "../NormContext";
import { MediaOverallMetricBase } from "../MediaItemCards";
import CompareSurveys from "./CompareSurvey";

const CompareContext = createContext();
export const useCompareContext = () => useContext(CompareContext);

export const CompareProvider = ({ children }) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const { selectedNorm } = useNormSelector();

  // Load selectedValues from local storage on initial render
  useEffect(() => {
    const savedValues =
      JSON.parse(localStorage.getItem("selectedValues")) || [];
    setSelectedValues(savedValues);
  }, []);

  // Update local storage whenever selectedValues change
  useEffect(() => {
    localStorage.setItem("selectedValues", JSON.stringify(selectedValues));
  }, [selectedValues]);

  const getFormattedBody = () => {
    const values = {
      media_items: selectedValues?.map((ithMI) => ithMI?.media_item_id),
      media_item_meta: selectedValues,
      norm: selectedNorm,
    };
    return values;
  };

  const value = {
    selectedValues,
    setSelectedValues,
    getFormattedBody,
  };

  return (
    <CompareContext.Provider value={value}>{children}</CompareContext.Provider>
  );
};

const Compare = () => {
  const { client } = useParams();
  const { data, isLoading } = useGetMediaQuery(client);

  const { selectedValues, setSelectedValues } = useCompareContext();

  const [searchValue, setSearchValue] = useState("");
  const { isOpen, onClose, onToggle, onOpen } = useDisclosure();

  const onSelect = (v) => {
    setSelectedValues([v, ...selectedValues]);
    onToggle();
    setSearchValue("");
  };

  const setSearch = (v) => {
    if (v === "") {
      onClose();
    } else {
      onOpen();
    }
    setSearchValue(v);
  };
  const navigate = useNavigate();

  const tabs = [
    {
      value: `overview`,
      title: "Overview",
      content: <OverviewCompare />,
    },
    {
      value: `survey`,
      title: "Surveys",
      content: <CompareSurveys />,
    },
    {
      value: `audience`,
      title: "Audience",
      content: <CompareAudience />,
    },
  ];

  const filteredData = data?.filter(
    (item) =>
      !selectedValues.some(
        (selected) => selected.media_item_id === item.media_item_id
      )
  );

  return (
    <Flex flexDirection="row" minH="100vh">
      <Flex flex="3" direction="column">
        <Box>
          <CommandBar bg="WhiteAlpha.200">
            <CommandBarContent>
              <CommandBarInput
                size="sm"
                value={searchValue}
                onChange={(v) => setSearch(v)}
                placeholder="Type in the name of title..."
                autoFocus
              />

              {isOpen && (
                <CommandBarList>
                  {isLoading && <CommandBarLoading>Hang on…</CommandBarLoading>}

                  <CommandBarEmpty>No results found.</CommandBarEmpty>

                  {filteredData?.map((v) => {
                    return (
                      <CommandBarItem
                        key={v.media_item_id}
                        value={v.media_item_id}
                        onSelect={() => onSelect(v)}
                      >
                        <HStack py="1">
                          <MdMovie />
                          <VStack align="left" gap="0">
                            <Text fontSize={"sm"} fontWeight={"medium"}>
                              {v.title}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                              {v?.subtitle}
                            </Text>
                          </VStack>
                        </HStack>
                      </CommandBarItem>
                    );
                  })}
                </CommandBarList>
              )}
            </CommandBarContent>
          </CommandBar>
          <HStack mt="2" justifyContent="space-between">
            <HStack>
              {selectedValues?.map((ithMediaItem) => (
                <Box
                  bg="white"
                  p="2"
                  fontSize="sm"
                  borderRadius="md"
                  boxShadow="sm"
                  fontWeight="medium"
                  key={`item-${ithMediaItem?.media_item_id}`}
                  onClick={() =>
                    navigate(
                      `/${client}/media/${ithMediaItem?.media_item_id}/overview`
                    )
                  }
                  _hover={{ cursor: "pointer" }}
                >
                  <HStack>
                    <MediaTypeTag
                      mediaType={ithMediaItem?.media_type_id}
                      size="small"
                    />
                    <Text>{ithMediaItem?.subtitle}</Text>
                  </HStack>
                </Box>
              ))}
            </HStack>
            <HStack>
              {!!selectedValues?.length && (
                <IconButton
                  variant="ghost"
                  onClick={() => {
                    setSelectedValues([]);
                    setSearchValue("");
                  }}
                >
                  <FiX />
                </IconButton>
              )}
            </HStack>
          </HStack>
        </Box>

        <TabManager
          tabs={tabs}
          defaultTab={tabs[0]?.value}
          basePath={`/${client}/compare/`}
        />
      </Flex>
    </Flex>
  );
};

const CompareComposite = () => {
  const { getFormattedBody, selectedValues } = useCompareContext();
  const [postSentiment, { data: sentimentResponse, isLoading }] =
    useCompareCompositeSentimentMutation();

  useEffect(() => {
    // Ensure there are selected values before calling the API
    if (selectedValues.length > 1) {
      postSentiment(getFormattedBody());
    }
  }, [selectedValues, postSentiment, getFormattedBody]);

  return (
    <>
      {selectedValues?.map((movie, idx) => {
        const selectedData = sentimentResponse?.find(
          (v) => v?.media_item_id === movie?.media_item_id
        );

        return (
          <MediaOverallMetricBase
            key={movie?.media_item_id || idx} // Add a unique key
            sentimentComposite={selectedData}
            isLoading={isLoading}
          />
        );
      })}
    </>
  );
};

const EmotionalCompare = () => {
  const { getFormattedBody, selectedValues } = useCompareContext();
  const [postEmotion, emotionResponse] = useCompareEmotionMutation();

  useFormSnackbar(emotionResponse, "Error Fetching Emotions");

  useEffect(() => {
    // Ensure there are selected values before calling the API
    if (selectedValues.length > 1) {
      postEmotion(getFormattedBody());
    }
  }, [selectedValues, postEmotion]);

  return selectedValues?.map((movie, idx) => (
    <Card>
      <Flex height="100%" justifyContent="center" alignItems="center">
        {emotionResponse?.isLoading ? (
          <Spinner />
        ) : (
          <>
            <EmotionChart
              data={emotionResponse?.data?.data?.[movie?.media_item_id]}
              isLoading={emotionResponse?.isLoading}
              title={movie?.title}
            />
          </>
        )}
      </Flex>
    </Card>
  ));
};

const CompareInterests = () => {
  const { getFormattedBody, selectedValues } = useCompareContext();
  const [post, res] = useCompareInterestsMutation();

  useFormSnackbar(res, "Error Fetching Interests");

  useEffect(() => {
    // Ensure there are selected values before calling the API
    if (selectedValues.length > 1) {
      post(getFormattedBody());
    }
  }, [selectedValues, post]);
  const colors = useToken("colors", [
    "#264653",
    "#2a9d8f",
    "#e9c46a",
    "#f4a261",
    "#e76f51",
    "black",
  ]);

  const titles = Object.keys(res?.data?.data || {});

  return (
    <GridItem colSpan={selectedValues?.length}>
      <Card>
        <Flex p="3" height="100%" justifyContent="center" alignItems="center">
          {res?.isLoading ? (
            <Spinner />
          ) : (
            <Box height="250px" width="100%">
              <ResponsiveContainer>
                <BarChart data={res?.data?.graph_data}>
                  <XAxis dataKey="name" />
                  <Tooltip />
                  <Legend />
                  {selectedValues?.map((ithTitle, idx) => (
                    <Bar dataKey={ithTitle?.subtitle} fill={colors[idx]} />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </Flex>
      </Card>
    </GridItem>
  );
};

const CompareAudience = () => {
  const { getFormattedBody, selectedValues } = useCompareContext();
  const [post, res] = useCompareAudienceMutation();

  useFormSnackbar(res, "Error Fetching Audience");

  useEffect(() => {
    // Ensure there are selected values before calling the API
    if (selectedValues.length > 1) {
      post(getFormattedBody());
    }
  }, [selectedValues, post]);

  return (
    <SimpleGrid columns={5} gap={3}>
      {selectedValues?.map((ithAudience) => (
        <>
          <GridItem>{ithAudience?.subtitle || ithAudience?.title}</GridItem>
          <GridItem colSpan={4}>
            <AudienceBase
              audienceData={res?.data?.[ithAudience?.media_item_id]}
              isLoading={res?.isLoading}
            />
          </GridItem>
        </>
      ))}
    </SimpleGrid>
  );
};

const OverviewCompare = () => {
  const { selectedValues } = useCompareContext();

  const comparisonElements = [
    {
      key: "sentiment",
      label: "Composite Score",
      component: <CompareComposite />,
    },

    {
      key: "emotionalResponse",
      label: "Emotional Response",
      component: <EmotionalCompare />,
    },
    {
      key: "interests",
      label: "Interest Drivers",
      component: <CompareInterests />,
    },
    // Add more comparison elements as needed
  ];
  if (selectedValues?.length < 2) {
    return (
      <Flex p="10" color="gray.400">
        <EmptyState
          title={"Awaiting Selection"}
          description={
            "Please select a set of titles to begin comparing  data. Once selected, you'll be able to view and compare  results here."
          }
        />
      </Flex>
    );
  }

  return (
    <Box overflowX="auto">
      <Box my="3">
        <Banner status="info" borderRadius="md" fontSize="sm">
          <BannerTitle>Why do I only see some data?</BannerTitle>
          <BannerDescription>
            As we introduce new features, certain elements of our historical
            data may be lacking, which prevents a comprehensive comparison.
          </BannerDescription>
        </Banner>
      </Box>
      <Grid
        templateColumns={`minmax(75px, 0.5fr) repeat(${selectedValues?.length}, minmax(120px, 1fr))`}
        gap={3}
      >
        {/* Header row for movie titles, starting with an empty cell for the labels column */}
        <Box p={5} />
        {selectedValues?.map((movie) => (
          <Card key={movie} p={5}>
            <Text fontWeight="bold" fontSize={"sm"}>
              {movie?.title}
            </Text>
            <Text fontSize={"xs"} color="gray.600">
              {movie?.subtitle}
            </Text>
          </Card>
        ))}

        {/* Rows for each comparison element */}
        {comparisonElements.map((element) => (
          <React.Fragment key={element.key}>
            {/* Label for the comparison element */}
            <Card p={5}>
              <Text fontWeight="bold">{element.label}</Text>
              {element?.children}
            </Card>

            {element?.component}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};
export default Compare;

import {
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Checkbox,
  Heading,
} from "@chakra-ui/react";

import generateExcel from "zipcelx";
import {
  fuzzyFilter,
  isDateLessThanFilter,
  userFilter,
  arrayFilter,
} from "./filter";

import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { cloneElement, useState } from "react";

import {
  FiChevronDown,
  FiChevronUp,
  FiDownload,
  FiFilter,
} from "react-icons/fi";

import { EmptyState, LoadingSpinner } from "@saas-ui/react";
import { useSearchParams } from "react-router-dom";
import { ColumnFilter, FilteredValues, GlobalFilter } from "./Filters";
import { Paginator } from "./Paginator";

const DataTable = ({
  data,
  columns,
  columnsToFilter = [],
  isLoading,
  handleLink,
  title = "",
  isExportable = true,
  isSelectable = false,
  simplify = false,
  isSearchable = true,
  otherComponents,
  children,
  ...props
}) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const initialFocusRef = React.useRef();
  const [rowSelection, setRowSelection] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const table = useReactTable({
    columns: columns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: isSelectable,
    onRowSelectionChange: setRowSelection,
    initialState: { pagination: { pageSize: 20 } },
    state: { globalFilter, rowSelection },
    filterFns: {
      fuzzy: fuzzyFilter,
      dateFilter: isDateLessThanFilter,
      user: userFilter,
      array: arrayFilter,
    },
  });

  function getExcel() {
    const config = {
      filename: `export.xlsx`,
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    // review with one level nested config
    // HEADERS
    table.getHeaderGroups().forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push({ value: column?.id });
        });
      }
      dataSet.push(headerRow);
    });

    // FILTERED ROWS
    table.getRowModel().rows.forEach((row) => {
      const dataRow = [];
      Object.values(row.getVisibleCells()).forEach((cell) => {
        dataRow.push({
          value: cell.getValue(),
        });
      });
      dataSet.push(dataRow);
    });

    return generateExcel(config);
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : !!data ? (
    <Flex direction="column" height="100%" width="100%">
      {!simplify && (
        <HStack my="3" px="3" justifyContent="space-between">
          <HStack>
            {title && (
              <Heading ml="3" size="sm">
                {title}
              </Heading>
            )}
            {children}
            {columnsToFilter && (
              <>
                <Popover initialFocusRef={initialFocusRef} placement="bottom">
                  {({ isOpen, onClose }) => (
                    <Box ml={3} width="auto">
                      <PopoverTrigger>
                        <Button size="xs" variant="outline">
                          <FiFilter />
                          <Text ml="1">Filter</Text>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent width="100%">
                        <Tabs>
                          <TabList>
                            {columnsToFilter?.map((cc) => (
                              <Tab key={`tabh-${cc}`}>
                                <Text fontSize="sm">
                                  {table.getColumn(cc).columnDef?.header}
                                </Text>
                              </Tab>
                            ))}
                          </TabList>
                          <TabPanels>
                            {columnsToFilter?.map((cc) => (
                              <TabPanel key={`tab-${cc}`} p="0">
                                <ColumnFilter
                                  column={table.getColumn(cc)}
                                  table={table}
                                  close={onClose}
                                />
                              </TabPanel>
                            ))}
                          </TabPanels>
                        </Tabs>
                      </PopoverContent>
                    </Box>
                  )}
                </Popover>
              </>
            )}
          </HStack>
          <HStack>
            {isSearchable && (
              <GlobalFilter
                value={table.getState().globalFilter}
                filterFn={setGlobalFilter}
              />
            )}

            {isExportable && (
              <Button onClick={() => getExcel()} variant="outline">
                <HStack m="3">
                  <FiDownload />
                  <Text mr=".5">Export</Text>
                </HStack>
              </Button>
            )}
            {otherComponents && otherComponents}
          </HStack>
        </HStack>
      )}
      <HStack
        borderTop={simplify ? "0" : "1px"}
        color="gray.200"
        justifyContent="space-between"
      >
        <FilteredValues columnsToFilter={columnsToFilter} table={table} />
        <HStack>
          {props?.tableSummary && cloneElement(props?.tableSummary, { table })}
        </HStack>
      </HStack>
      <Box flex="auto" overflowY="auto">
        <Table size="sm" variant={simplify ? "unstyled" : "simple"}>
          <Thead borderTop={simplify ? "0" : "1px"} borderTopColor="gray.100">
            {table.getHeaderGroups()?.map((headerGroup) => (
              <Tr key={`hge-${headerGroup.id}`}>
                {headerGroup.headers?.map((header) => {
                  const meta = header.column.columnDef.meta;
                  const colName = header.column.id;
                  if (header.column.columnDef?.isHidden) return null;
                  return (
                    <Th
                      py="3"
                      key={`header-${header.id}`}
                      onClick={header.column.getToggleSortingHandler()}
                      isNumeric={meta?.isNumeric}
                    >
                      <HStack>
                        <Text fontWeight="bold">
                          {colName === "check" && isSelectable ? (
                            <Checkbox
                              isChecked={table.getIsAllRowsSelected()}
                              onChange={() => table.toggleAllRowsSelected()}
                            />
                          ) : (
                            flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )
                          )}
                        </Text>

                        {colName !== "check" && (
                          <chakra.span pl="4">
                            {header.column.getIsSorted() ? (
                              header.column.getIsSorted() === "desc" ? (
                                <FiChevronUp aria-label="sorted descending" />
                              ) : (
                                <FiChevronDown aria-label="sorted ascending" />
                              )
                            ) : null}
                          </chakra.span>
                        )}
                      </HStack>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel()?.rows?.map((row) => (
              <Tr
                key={`tr-${row.id}`}
                onClick={() => (handleLink ? handleLink(row) : {})}
                _hover={
                  handleLink && {
                    background: "gray.50",
                    cursor: "pointer",
                  }
                }
              >
                {row.getVisibleCells()?.map((cell, index) => {
                  const meta = cell.column.columnDef.meta;
                  const colName = cell.column.id;
                  if (cell.column.columnDef?.isHidden) return null;
                  return (
                    <Td
                      maxWidth={cell.column.columnDef?.maxWidth}
                      key={cell.id}
                      py={simplify ? "1" : "3"}
                      overflow="hidden"
                      isNumeric={meta?.isNumeric}
                      bg={row.getIsSelected() ? "purple.50" : "white"}
                    >
                      {colName === "check" && isSelectable ? (
                        <Checkbox
                          isChecked={row.getIsSelected()}
                          onChange={() => row.toggleSelected()}
                          disabled={!row.getCanSelect()}
                        />
                      ) : (
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      {!simplify && <Paginator table={table} />}
    </Flex>
  ) : (
    <EmptyState
      title="No Data"
      description="There is no data to display"
      actions={<>{otherComponents && otherComponents}</>}
    />
  );
};

export default DataTable;

import React from "react";
import { Skeleton, Stack } from "@chakra-ui/react";

const GhostTextLoading = ({ lineCount = 5 }) => {
  return (
    <Stack width="100%">
      {[...Array(lineCount)].map((_, index) => (
        <Skeleton key={index} height="15px" />
      ))}
    </Stack>
  );
};

export default GhostTextLoading;

export const findObjectWithHighestValue = (data, keyToCompare) => {
  if (!data || data.length === 0) {
    return null; // Return null if the array is undefined or empty
  }

  const highestObject = data.reduce((highest, current) => {
    if (
      current &&
      current[keyToCompare] !== undefined &&
      (highest[keyToCompare] === undefined ||
        current[keyToCompare] > highest[keyToCompare])
    ) {
      return current;
    } else {
      return highest;
    }
  }, data[0]);

  return highestObject;
};
export const findTopNObjects = (data, keyToCompare, n) => {
  if (!data || data.length === 0 || n <= 0) {
    return []; // Return an empty array if the input is invalid
  }
  // Filter out objects where the value at keyToCompare is 0
  const filteredData = data.filter((obj) => obj[keyToCompare] !== 0);

  // Then sort the filtered data
  const sortedData = filteredData.sort((a, b) => {
    // Ensure the key exists in both objects
    const keyAExists = a && a[keyToCompare] !== undefined;
    const keyBExists = b && b[keyToCompare] !== undefined;

    if (keyAExists && keyBExists) {
      // Compare the key values
      return b[keyToCompare] - a[keyToCompare];
    } else if (keyAExists) {
      return -1; // a should come first if only a has the key
    } else if (keyBExists) {
      return 1; // b should come first if only b has the key
    } else {
      return 0; // Neither has the key, treat as equal
    }
  });

  // Return the top N elements
  return sortedData.slice(0, n);
};

import { HStack, Tag, Text } from "@chakra-ui/react";
import { MdOutlineMovie } from "react-icons/md";

export const MediaTypeTag = ({ mediaType, size = "normal" }) => {
  return (
    <Tag
      colorScheme={mediaType === 2 ? "blue" : "red"}
      size="xs" // Set the size to "xs"
      fontSize="xs" // Set the font size to "xs"
      borderRadius="md"
      px={2}
      py={0.5}
      border="1px solid"
      fontWeight="bold"
      borderColor={mediaType === 2 ? "blue.200" : "red.200"} // Adjust the border color based on mediaType
      color={mediaType === 2 ? "blue.500" : "red.500"} // Adjust the text color based on mediaType
      bg={mediaType === 2 ? "blue.50" : "red.50"} // Adjust the background color based on mediaType
    >
      <HStack>
        <MdOutlineMovie />
        {size !== "small" && (
          <Text>{mediaType === 2 ? "TV Show" : "Movie"}</Text>
        )}
      </HStack>
    </Tag>
  );
};

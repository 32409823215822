import { useEffect } from "react";
import { useSnackbar } from "@saas-ui/react";

const useFormSnackbar = (formState, errorMessage = undefined) => {
  const toast = useSnackbar();

  useEffect(() => {
    if (formState?.isError && formState?.error) {
      toast({
        title: "Error",
        description:
          formState?.error?.message || errorMessage || "An error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  }, [
    formState?.data,
    formState?.error,
    formState?.isSuccess,
    formState?.isError,
  ]);
};

// The hook doesn't need to return anything in this case

export default useFormSnackbar;

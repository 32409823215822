import {
  Card,
  HStack,
  Text,
  UnorderedList,
  ListItem,
  Box,
  VStack,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { FiCloudLightning, FiRefreshCcw } from "react-icons/fi";
import FeatureFlag from "../../util/FeatureFlag";
import { useParams } from "react-router";
import {
  useGetMediaCommentsQuery,
  useRefreshMediaCommentsMutation,
} from "../../../store/services/mediaApi";
import { LoadingOverlay, LoadingSpinner } from "@saas-ui/react";
import GhostTextLoading from "../../util/GhostTextLoading";

export const Comments = () => {
  const { id } = useParams();
  const { data, isLoading: commentLoading } = useGetMediaCommentsQuery(id);
  const [updateComments, { isLoading: updateLoading, data: refreshData }] =
    useRefreshMediaCommentsMutation(id);

  return (
    <Card py="3" px="5" height="100%">
      <HStack fontSize="md" justifyContent="space-between">
        <HStack>
          <FiCloudLightning />
          <Text>Generative Summary</Text>
        </HStack>
        <HStack>
          <IconButton
            size="xs"
            variant="ghost"
            icon={<FiRefreshCcw />}
            isLoading={updateLoading}
            onClick={() => updateComments({ media_item_id: id })}
          />
          <FeatureFlag
            beta={true}
            description="Data from all of our feedback tool is utilized to generate this summary"
          />
        </HStack>
      </HStack>
      {commentLoading ? (
        <Flex>
          <GhostTextLoading lineCount={20} />
        </Flex>
      ) : (
        <Box py="3">
          <Box mb="3">
            <Text fontSize="md" fontWeight="medium" mb="2">
              Summary
            </Text>
            <Text fontSize="sm" color="gray.600">
              {refreshData?.summary || data?.summary}
            </Text>
          </Box>
          {Object.entries(refreshData?.other || data?.other || {}).map(
            ([key, value]) => (
              <Box mb="3" ithKey={key}>
                <Text fontSize="md" fontWeight="medium" mb="2">
                  {key}
                </Text>
                <Text fontSize="sm" color="gray.600">
                  {value}
                </Text>
              </Box>
            )
          )}
        </Box>
      )}
    </Card>
  );
};

export const Interest = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetMediaCommentsQuery(id);

  return (
    <VStack gap={2} height="100%">
      <Card py="3" px="5" flex="1" width="100%">
        <HStack fontSize="md" justifyContent="space-between">
          <HStack>
            <FiCloudLightning />
            <Text>Interest Drivers</Text>
          </HStack>
          <FeatureFlag
            beta={true}
            description="Data from all of our feedback tool is utilized to generate this summary"
          />
        </HStack>
        {isLoading ? (
          <Flex>
            <GhostTextLoading />
          </Flex>
        ) : (
          <Box py="3">
            <Box mb="3">
              <Text fontSize="sm" color="gray.600">
                {data?.excitement}
              </Text>
            </Box>
          </Box>
        )}
      </Card>
      <Card py="3" px="5" flex="1" width="100%">
        <HStack fontSize="md" justifyContent="space-between">
          <HStack>
            <FiCloudLightning />
            <Text>Deterrents</Text>
          </HStack>
          <FeatureFlag
            beta={true}
            description="Data from all of our feedback tool is utilized to generate this summary"
          />
        </HStack>
        {isLoading ? (
          <Flex>
            <GhostTextLoading />
          </Flex>
        ) : (
          <Box py="3">
            <Box mb="3">
              <Text fontSize="sm" color="gray.600" maxWidth="sm">
                {data?.deterents}
              </Text>
            </Box>
          </Box>
        )}
      </Card>
    </VStack>
  );
};

const AGE = ["<18", "18-24", "25-34", "35-44", "45-54", "55+"];
const INCOME = [
  "<25k",
  "$25k - $50k",
  "$50k - $75k",
  "$75k - $100k",
  "$100k - $150k",
  "$150k+",
];
const ETHNICITY = ["White", "Black", "Latino", "Asian", "Other"];
const SEX = ["Male", "Female"];
const RELATION = ["Single", "In Relationship", "Married"];
const HLC = ["Lover", "Casual", "Hater"];
const FANDOM = ["Big fan", "Somewhat a fan", "Not a fan", "Not aware"];

const sortByPredefinedOrder = (array, key, order) => {
  return array.sort((a, b) => {
    let indexOfA = order.indexOf(a[key]);
    let indexOfB = order.indexOf(b[key]);

    return indexOfA - indexOfB;
  });
};

const sortByAlphabetically = (array, key) => {
  return array.sort((a, b) => {
    const nameA = a?.[key]?.toUpperCase(); // case-insensitive comparison
    const nameB = b?.[key]?.toUpperCase(); // case-insensitive comparison
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const sortedData = (array, key) => {
  if (Array.isArray(array) && array.length > 0) {
    const firstVal = array?.[0]?.[key];
    const arrayCopy = [...array];

    if (AGE.includes(firstVal)) {
      return sortByPredefinedOrder(arrayCopy, key, AGE);
    } else if (INCOME.includes(firstVal)) {
      return sortByPredefinedOrder(arrayCopy, key, INCOME);
    } else if (ETHNICITY.includes(firstVal)) {
      return sortByPredefinedOrder(arrayCopy, key, ETHNICITY);
    } else if (SEX.includes(firstVal)) {
      return sortByPredefinedOrder(arrayCopy, key, SEX);
    } else if (RELATION.includes(firstVal)) {
      return sortByPredefinedOrder(arrayCopy, key, RELATION);
    } else if (HLC.includes(firstVal)) {
      return sortByPredefinedOrder(arrayCopy, key, HLC);
    } else if (FANDOM.includes(firstVal)) {
      return sortByPredefinedOrder(arrayCopy, key, FANDOM);
    } else {
      return sortByAlphabetically(arrayCopy, key);
    }
  }
  return array;
};

export const sortAlphabetically = ({ array, key }) => {
  const sortedRow = { ...array }; // Create a shallow copy of the row object
  if (sortedRow.data) {
    const firstVal = sortedRow?.data[0]?.[key];
    sortedRow.data = [...sortedRow.data]; // Create a shallow copy of the data array

    if (AGE.includes(firstVal)) {
      sortedRow.data = sortByPredefinedOrder(sortedRow.data, key, AGE);
    } else if (INCOME.includes(firstVal)) {
      sortedRow.data = sortByPredefinedOrder(sortedRow.data, key, INCOME);
    } else if (ETHNICITY.includes(firstVal)) {
      sortedRow.data = sortByPredefinedOrder(sortedRow.data, key, ETHNICITY);
    } else if (SEX.includes(firstVal)) {
      sortedRow.data = sortByPredefinedOrder(sortedRow.data, key, SEX);
    } else if (RELATION.includes(firstVal)) {
      sortedRow.data = sortByPredefinedOrder(sortedRow.data, key, RELATION);
    } else if (HLC.includes(firstVal)) {
      sortedRow.data = sortByPredefinedOrder(sortedRow.data, key, HLC);
    } else if (FANDOM.includes(firstVal)) {
      sortedRow.data = sortByPredefinedOrder(sortedRow.data, key, FANDOM);
    } else {
      sortedRow.data = sortByAlphabetically(sortedRow.data, key);
    }
  }
  return sortedRow;
};

// contexts/NormSelectorContext.js
import React, { createContext, useContext, useState } from "react";
import { useGetNormsQuery } from "../../store/services/mediaApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router";

const NormSelectorContext = createContext();

export function NormSelectorProvider({ children }) {
  const { client } = useParams();
  const { isLoading: userLoading } = useAuth0();

  const {
    data: options,
    isLoading,
    error,
  } = useGetNormsQuery(client, { skip: userLoading && !client });

  // Defaulting to configurable norm that includes all titles in CINT era.
  // Ideally we should label this "default", but that has hardcoded references.
  // TODO: figure out whether to keep the old norm option at all
  // https://linear.app/volkno/issue/VOL-237/default-to-sensiblebest-norms
  const [selectedNorm, setselectedNorm] = useState("current");

  const value = {
    options,
    selectedNorm,
    setselectedNorm,
    isLoading,
    error,
  };

  return (
    <NormSelectorContext.Provider value={value}>
      {children}
    </NormSelectorContext.Provider>
  );
}

export function useNormSelector() {
  const context = useContext(NormSelectorContext);
  if (context === undefined) {
    throw new Error(
      "useNormSelector must be used within a NormSelectorProvider"
    );
  }
  return context;
}

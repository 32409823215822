import {
  Text,
  Box,
  Card,
  HStack,
  CardHeader,
  CardBody,
} from "@chakra-ui/react";

import PivotTable from "../util/PivotTable.js";
import { useMemo } from "react";
function flattenArray(data) {
  if (!data || !Array.isArray(data)) {
    return {};
  }

  const flattenedData = [];

  data.forEach((item) => {
    const { value, data } = item;
    flattenedData.push({ ...data, value: value });
  });

  return flattenedData;
}

// const MultiPartQuestion = ({ questionData, ithQuestion }) => {
//   const displayData = Object.keys(questionData?.overall || {})
//     .map((ithQ) => ({
//       value: ithQ,
//       data: flattenArray(questionData?.overall?.[ithQ]),
//     }))
//     ?.filter((ithQ) => ithQ?.value !== "question");

//   return (
//     <Card>
//       <pre>{JSON.stringify(displayData?.[0], null, 2)}</pre>
//       <CardHeader borderBottom="1px solid" borderColor="gray.200">
//         <HStack justifyContent="space-between" alignItems="flex-start">
//           <Box maxWidth="50%">
//             <HStack pb="2">
//               <Text fontWeight="medium">{ithQuestion}</Text>
//             </HStack>
//             <Text fontSize="sm" color="gray.500">
//               {questionData?.overall?.question}
//             </Text>
//           </Box>
//         </HStack>
//       </CardHeader>
//       <CardBody>
//         <PivotTable
//           data={displayData}
//           valueKey="percentage_of_answer"
//           otherKey="value"
//           color="red"
//         />
//       </CardBody>
//     </Card>
//   );
// };

// export default MultiPartQuestion;

const MultiPartQuestion = ({ questionData, ithQuestion }) => {
  const displayData = useMemo(() => {
    const overallData = questionData?.overall || {};

    // Grouping logic: map identical answer sets to the group names
    const answerSetToGroups = {};
    const groupData = {};

    for (const [groupName, answers] of Object.entries(overallData)) {
      if (groupName === "question") continue;

      const answerArray = Array.isArray(answers) ? answers : [];
      const answerValuesSet = new Set();

      for (const answer of answerArray) {
        if (answer?.value != null) {
          answerValuesSet.add(answer.value);
        }
      }

      const key = JSON.stringify(Array.from(answerValuesSet).sort());

      if (!answerSetToGroups[key]) {
        answerSetToGroups[key] = [];
      }

      answerSetToGroups[key].push(groupName);
      groupData[groupName] = answerArray;
    }

    // Prepare display data
    return Object.entries(answerSetToGroups).map(([key, groupNames]) => ({
      answerValues: JSON.parse(key),
      groups: groupNames.map((groupName) => ({
        value: groupName,
        data: groupData[groupName].map((answer) => ({
          value: answer.value,
          ...answer.data, // Flattening the nested `data` object
        })),
      })),
    }));
  }, [questionData]);

  if (!displayData || displayData.length === 0) {
    return <Text color="gray.500">No aligned data available</Text>;
  }

  return displayData.map((grouping, index) => (
    <Card key={index} mb={6}>
      <CardHeader borderBottom="1px solid" borderColor="gray.200">
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Box maxWidth="50%">
            <HStack pb={2}>
              <Text fontWeight="medium">
                {" "}
                {questionData?.overall?.question || "No question available"}
              </Text>
            </HStack>
            <Text fontSize="sm" color="gray.500">
              Groups with answers: {grouping.answerValues.join(", ")}
            </Text>
          </Box>
        </HStack>
      </CardHeader>

      <CardBody>
        <Box mb={4}>
          <PivotTable
            data={grouping.groups}
            valueKey="percentage_of_answer"
            otherKey="value"
            color="red"
          />
        </Box>
      </CardBody>
    </Card>
  ));
};

export default MultiPartQuestion;

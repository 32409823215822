import React, { useState, useEffect } from "react";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";

const TimeIndicator = ({ duration }) => {
  const [hashMarks, setHashMarks] = useState([]);

  useEffect(() => {
    const marks = [];
    const chunks = 50; // Adjust the number of chunks if needed
    for (let i = 0; i <= chunks; i++) {
      marks.push({
        time: (duration / chunks) * i,
        type: i % 5 === 0 ? "time" : "subtick",
      });
    }
    marks[0].type = "empty"; // First tick
    marks[marks.length - 1].type = "empty"; // Last tick
    setHashMarks(marks);
  }, [duration]);

  return (
    <Flex justify="space-between" align="top" width="100%" position="relative">
      {hashMarks.map((mark, index) => (
        <Box
          key={index}
          textAlign="center"
          width={`${100 / hashMarks.length}%`}
        >
          {/* Render based on type */}
          {mark.type === "time" && (
            <VStack>
              <Box height="6px" width="2px" bg="gray.400" mx="auto" />
              <Text fontSize="xs" color="gray.500">
                {new Date(mark.time * 1000).toISOString().substr(11, 8)}
              </Text>
            </VStack>
          )}
          {mark.type === "subtick" && (
            <Box height="3px" width="2px" bg="gray.300" mx="auto" />
          )}
          {mark.type === "empty" && (
            <Box height="6px" width="2px" mx="auto" visibility="hidden" />
          )}
        </Box>
      ))}
    </Flex>
  );
};

export default TimeIndicator;

import { useMemo, useState } from "react";
import {
  Text,
  Box,
  Card,
  Heading,
  Flex,
  HStack,
  useTheme,
  Badge,
  ButtonGroup,
  Button,
  useToken,
  SimpleGrid,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { createColumnHelper } from "@tanstack/react-table";
import DataTable from "../../../Table/DataTable.js";
import FeatureFlag from "../../util/FeatureFlag.js";
import {
  LoadingOverlay,
  LoadingSpinner,
  ErrorBoundary,
  EmptyState,
} from "@saas-ui/react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from "recharts";

import {
  FaRegSmile,
  FaRegMeh,
  FaRegFrown,
  FaRegSmileBeam,
  FaRegFrownOpen,
} from "react-icons/fa";
import HorizontalBar from "../../util/HorizontalSingleBar.js";
import { startOfWeek, format } from "date-fns";
import { useOpenEndedSource } from "../OpenEndedContext.js";

export const SentimentBadge = ({ sentiment = "" }) => {
  const colors = useToken("colors", [
    "green.100", // Positive
    "red.100", // Negative
    "yellow.100", // Mixed
    "gray.100", // Neutral
  ]);
  const accentColors = useToken("colors", [
    "green.500", // Positive
    "red.500", // Negative
    "yellow.500", // Mixed
    "gray.500", // Neutral
  ]);

  // Determine the badge color based on the sentiment value
  const color =
    sentiment === "positive"
      ? colors[0]
      : sentiment === "negative"
      ? colors[1]
      : sentiment === "mixed"
      ? colors[2]
      : colors[3];

  const accentColor =
    sentiment === "positive"
      ? accentColors[0]
      : sentiment === "negative"
      ? accentColors[1]
      : sentiment === "mixed"
      ? accentColors[2]
      : accentColors[3];

  return (
    <Badge
      backgroundColor={color}
      color={accentColor}
      variant="solid"
      borderRadius="4"
      px="1"
      border={"1px solid"}
      borderColor={accentColor}
    >
      {sentiment.toUpperCase()}
    </Badge>
  );
};

const SentimentCategory = ({ positive, negative, neutral = null }) => {
  // Determine the category based on the provided sentiment values.
  let category = "";
  let Icon = FaRegMeh; // Default icon
  let iconColor = "gray.500"; // Default color

  const theme = useTheme();

  // Scale positive and negative values if neutral is not provided.
  if (neutral === null) {
    const total = positive + negative;
    positive = (positive / total) * 100;
    negative = (negative / total) * 100;
    neutral = 0;
  } else {
    neutral = Math.max(0, 100 - (positive + negative));
  }

  // Determine the sentiment category, corresponding icon, and color.
  if (positive >= 60) {
    category = "Very Positive";
    Icon = FaRegSmileBeam; // A big smile for very positive sentiment
    iconColor = theme.colors.green[500];
  } else if (positive >= 40) {
    category = "Positive Sentiment";
    Icon = FaRegSmile; // A smile for positive sentiment
    iconColor = theme.colors.green[500];
  } else if (neutral >= 20) {
    category = "Mixed";
    Icon = FaRegMeh; // A neutral face for mixed sentiment
    iconColor = theme.colors.yellow[500];
  } else if (neutral >= 35) {
    category = "Very Mixed";
    Icon = FaRegFrownOpen; // A slightly open frown for very mixed sentiment
    iconColor = theme.colors.orange[500];
  } else if (negative >= 60) {
    category = "Very Negative";
    Icon = FaRegFrown; // A big frown for very negative sentiment
    iconColor = theme.colors.red[500];
  } else {
    category = "Negative Sentiment";
    Icon = FaRegFrown; // A frown for negative sentiment
    iconColor = theme.colors.red[500];
  }

  return (
    <HStack>
      <Icon size={15} color={iconColor} />
      <Text noOfLines={1}>{category}</Text>
    </HStack>
  );
};
// SentimentBar Component// SentimentBar Component
const SentimentBar = ({ positive, negative, neutral = null }) => {
  const data = useMemo(() => {
    const t = positive + negative + (neutral || 0);
    const p = (positive / t) * 100;
    const n = (negative / t) * 100;
    const neu = neutral ? (neutral / t) * 100 : 0;
    return [
      {
        name: "Sentiment",
        Positive: p,
        Negative: n,
        Neutral: neu,
      },
    ];
  }, [positive, negative, neutral]);

  const theme = useTheme();
  return (
    <>
      <ResponsiveContainer width="100%" height={17}>
        <BarChart
          data={data}
          layout="vertical"
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis type="number" hide domain={[0, 100]} />
          <YAxis type="category" dataKey="name" hide />
          <Bar
            dataKey="Negative"
            stackId="a"
            fill={theme.colors.red[500]}
            radius={7}
            style={{ stroke: "#fff", strokeWidth: 2 }}
          />
          {neutral > 0 && (
            <Bar
              dataKey="Neutral"
              stackId="a"
              fill={theme.colors.yellow[400]}
              radius={7}
              style={{ stroke: "#fff", strokeWidth: 2 }}
            />
          )}
          <Bar
            dataKey="Positive"
            stackId="a"
            fill={theme.colors.green[500]}
            style={{ stroke: "#fff", strokeWidth: 2 }}
            radius={7}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export const TopicSizing = () => {
  const { topicSizing, isLoading } = useOpenEndedSource();

  const columnHelper = createColumnHelper();

  // Define Columns
  const columns = [
    columnHelper.accessor("topic", {
      header: "Topic",
      cell: (info) => (
        <VStack justifyContent="start" alignItems="start" gap={2}>
          <Text fontWeight="bold">{info.getValue()}</Text>
          <Text noOfLines={2}>{info.row.original?.description}</Text>
        </VStack>
      ),
    }),

    columnHelper.accessor("positive_percentage", {
      header: "Positive",
      cell: (info) => `${info.getValue()}%`,
    }),

    columnHelper.accessor("negative_percentage", {
      header: "Negative",
      cell: (info) => `${info.getValue()}%`,
    }),

    columnHelper.display({
      id: "sentiment_breakdown",
      header: "Sentiment Breakdown",
      cell: ({ row }) => (
        <SentimentBar
          positive={row.original.positive_percentage}
          negative={row.original.negative_percentage}
          // neutral={row.original.neutral_percentage} // Uncomment if Neutral is available
        />
      ),
      size: 150,
    }),
  ];

  if (isLoading) {
    return (
      <LoadingOverlay>
        <LoadingSpinner />
      </LoadingOverlay>
    );
  }

  if (!topicSizing.length && !isLoading) {
    return (
      <EmptyState
        title="No Sentiment or Topic Sizing Data"
        description="There are no sentiment or topic sizing data for this media item. Please request it to be added."
      />
    );
  }

  return (
    <Card p={4}>
      <HStack justifyContent="space-between">
        <Heading p="3" size="sm">
          Open Ended Topic Sizing
        </Heading>

        <HStack>
          <FeatureFlag description="The most frequent topics mentioned across all open-ended feedback are listed here, depending on whether they were mentioned positively or negatively" />
        </HStack>
      </HStack>

      <Flex width="100%">
        <DataTable
          data={topicSizing}
          columns={columns}
          columnsToFilter={[]}
          isLoading={isLoading}
          simplify={true}
        />
      </Flex>
    </Card>
  );
};

export const SentimentTags = ({ tags = [] }) => {
  return (
    <Wrap spacing="8px">
      {tags.map((tag) => (
        <WrapItem key={tag.topic}>
          <Box>
            <Badge
              size="sm"
              colorScheme={
                tag?.type === "positive"
                  ? "green"
                  : tag?.type === "negative"
                  ? "red"
                  : tag?.type === "mixed"
                  ? "yellow"
                  : "blue" // Default to "blue" for neutral
              }
            >
              {tag?.topic}
            </Badge>
          </Box>
        </WrapItem>
      ))}
    </Wrap>
  );
};
export const SentimentTotals = () => {
  const { openEnded: data, isLoading } = useOpenEndedSource();

  const sentimentPercentages = useMemo(() => {
    if (!data || data.length === 0) {
      return { positive: 0, negative: 0, mixed: 0, neutral: 0 };
    }

    // Calculate total number of comments
    const totalComments = data.length;

    // Count occurrences of each sentiment
    const sentimentCounts = data.reduce(
      (acc, { sentiment }) => {
        if (acc[sentiment] !== undefined) {
          acc[sentiment] += 1;
        }
        return acc;
      },
      { positive: 0, negative: 0, mixed: 0, neutral: 0 }
    );

    // Calculate percentages
    return {
      positive: (sentimentCounts.positive / totalComments) * 100,
      negative: (sentimentCounts.negative / totalComments) * 100,
      mixed: (sentimentCounts.mixed / totalComments) * 100,
      neutral: (sentimentCounts.neutral / totalComments) * 100,
    };
  }, [data]);

  const colors = useToken("colors", [
    "green.400",
    "red.400",
    "yellow.400",
    "gray.400",
  ]);

  const barData = useMemo(
    () => [
      {
        label: "Positive",
        value: sentimentPercentages.positive,
        color: colors[0],
      },
      {
        label: "Negative",
        value: sentimentPercentages.negative,
        color: colors[1],
      },
      {
        label: "Mixed",
        value: sentimentPercentages.mixed,
        color: colors[2],
      },
      {
        label: "Neutral",
        value: sentimentPercentages.neutral,
        color: colors[3],
      },
    ],
    [sentimentPercentages]
  );
  if (!data || isLoading) {
    return null;
  }

  return (
    <Box p={4} flex={2}>
      <ErrorBoundary>
        <Box>
          <Box>
            <Text fontSize="xs" textTransform="uppercase" color="gray.600">
              Sentiment Totals
            </Text>
            <Text fontSize="md" mb="2" fontWeight="bold" color="gray.900">
              <SentimentCategory
                positive={sentimentPercentages.positive}
                negative={sentimentPercentages.negative}
                neutral={
                  sentimentPercentages.neutral + sentimentPercentages.mixed
                }
              />
            </Text>
          </Box>
          <HorizontalBar
            layout="horizontal"
            data={barData.map((item) => ({
              label: item.label,
              value: item.value,
              color: item.color,
              children: (
                <HStack
                  alignItems="center"
                  mt="1"
                  ml="4"
                  fontSize="sm"
                  fontWeight="bold"
                  color="gray.600"
                >
                  <Text>
                    {item.label}: {item.value.toFixed(2)}%
                  </Text>
                </HStack>
              ),
            }))}
          />
        </Box>
      </ErrorBoundary>
    </Box>
  );
};
export const SentimentAreaChart = () => {
  // Fetch data
  const { openEnded: data, isLoading } = useOpenEndedSource();

  // State to switch between 'daily' and 'weekly' views
  const [timeframe, setTimeframe] = useState("daily");

  // Function to group data by day or week and calculate percentages
  const chartData = useMemo(() => {
    if (!data || data.length === 0) return [];

    const counts = {};

    data.forEach(({ date_added, sentiment }) => {
      // Get the appropriate date key (daily or weekly)
      const dateKey =
        timeframe === "weekly"
          ? format(startOfWeek(new Date(date_added)), "yyyy-MM-dd")
          : new Date(date_added).toISOString().split("T")[0];

      if (!counts[dateKey]) {
        counts[dateKey] = {
          date: dateKey,
          positive: 0,
          negative: 0,
        };
      }

      // Increment the sentiment count for the given dateKey if it is positive or negative
      if (sentiment === "positive" || sentiment === "negative") {
        counts[dateKey][sentiment] += 1;
      }
    });

    // Calculate percentages for each dateKey and filter out entries with no total counts
    return Object.values(counts)
      .map((entry) => {
        const total = entry.positive + entry.negative;
        if (total === 0) return null; // Skip days with no data
        return {
          date: entry.date,
          positive: (entry.positive / total) * 100,
          negative: (entry.negative / total) * 100,
        };
      })
      .filter(Boolean); // Remove any null values (days with no valid data)
  }, [data, timeframe]);

  // Calculate average sentiment for the subheader
  const averageSentiment = useMemo(() => {
    if (chartData.length === 0) return { positive: 0, negative: 0 };

    const totalPeriods = chartData.length;

    const totals = chartData.reduce(
      (acc, period) => {
        acc.positive += period.positive;
        acc.negative += period.negative;
        return acc;
      },
      { positive: 0, negative: 0 }
    );

    return {
      positive: (totals.positive / totalPeriods).toFixed(2),
      negative: (totals.negative / totalPeriods).toFixed(2),
    };
  }, [chartData]);

  // Custom Tooltip to show the date correctly in MM/DD/YYYY format
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          bg="white"
          p={2}
          border="1px solid #ccc"
          borderRadius="md"
          overflow={"auto"}
        >
          <Text fontSize="sm" fontWeight="bold">
            Date: {payload[0].payload.date}
          </Text>
          <Text fontSize="sm" color="green.600">
            Positive: {payload[0].value.toFixed(2)}%
          </Text>
          <Text fontSize="sm" color="red.600">
            Negative: {payload[1].value.toFixed(2)}%
          </Text>
        </Box>
      );
    }
    return null;
  };

  const colors = useToken("colors", ["green.500", "red.500"]);

  if (!data || isLoading) {
    return null;
  }

  return (
    <Box p={4} flex={2}>
      <ErrorBoundary>
        <Box mb={4}>
          <HStack justifyContent="space-between">
            <Box>
              <Text fontSize="xs" textTransform="uppercase" color="gray.600">
                Sentiment Trend
              </Text>
              <Text fontSize="md" mb="2" fontWeight="bold" color="gray.900">
                {averageSentiment.positive}% Positive
              </Text>
            </Box>
            <ButtonGroup isAttached variant="outline" mb="4" size="xs">
              <Button
                onClick={() => setTimeframe("daily")}
                isActive={timeframe === "daily"}
              >
                Daily
              </Button>
              <Button
                onClick={() => setTimeframe("weekly")}
                isActive={timeframe === "weekly"}
              >
                Weekly
              </Button>
            </ButtonGroup>
          </HStack>

          <ResponsiveContainer width="100%" height={100}>
            <AreaChart
              data={chartData}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient
                  id="positiveGradient"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor={colors[0]} stopOpacity={0.7} />
                  <stop offset="80%" stopColor={colors[0]} stopOpacity={0.05} />
                </linearGradient>
                <linearGradient
                  id="negativeGradient"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor={colors[1]} stopOpacity={0.7} />
                  <stop offset="80%" stopColor={colors[1]} stopOpacity={0.05} />
                </linearGradient>
              </defs>
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="positive"
                stackId="1"
                stroke="#4CAF50"
                strokeWidth="3"
                fill="url(#positiveGradient)"
                name="Positive"
              />
              <Area
                type="monotone"
                dataKey="negative"
                stackId="1"
                stroke="#F44336"
                strokeWidth="0"
                fill="url(#negativeGradient)"
                name="Negative"
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </ErrorBoundary>
    </Box>
  );
};

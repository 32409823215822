// src/components/ImdbSearchField.js
import React, { useEffect } from "react";

import { useGetMediaByIMDBQuery } from "../../store/services/mediaApi";
import { Field, useController, useFormContext } from "@saas-ui/react";
import { FaFilm, FaTv } from "react-icons/fa";
import { RadioCard } from "./IconSelector";
import { Box, HStack, useRadioGroup } from "@chakra-ui/react";
import Media from "../media/Media";

// Media Options Component
export function MediaOptions({ movieResults, tvResults }) {
  const { control } = useFormContext();
  const {
    field: { onChange, value, ref },
  } = useController({
    name: "selected_media",
    control,
    defaultValue: "", // Default value
  });

  const options = [
    ...movieResults.map((movie) => ({
      ...movie,
      value: `movie-${movie.id}`,
      label: `${movie.title} (${movie.release_date.split("-")[0]})`,
      icon: FaFilm,
    })),
    ...tvResults.map((tv) => ({
      ...tv,
      value: `tv-${tv.id}`,
      label: `${tv.name} (${tv.first_air_date.split("-")[0]})`,
      icon: FaTv,
    })),
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "mediaType",
    onChange, // Connect the RadioGroup to React Hook Form
    value,
  });

  const group = getRootProps();

  return (
    <Box mt="3">
      <HStack {...group} wrap="wrap">
        {options?.map((option) => (
          <RadioCard
            key={option.value}
            {...getRadioProps({ value: option.value })}
            icon={option.icon}
          >
            {option.label}
          </RadioCard>
        ))}
      </HStack>
    </Box>
  );
}

const ImdbSearchField = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  const imdbId = watch("imdb_id"); // Watching the input field for IMDb ID changes
  // Use the RTK Query hook to fetch data, skipping if the IMDb ID isn't exactly 9 characters
  const { data, error, isFetching } = useGetMediaByIMDBQuery(imdbId, {
    skip: !imdbId && imdbId?.length < 9,
  });

  return (
    <Box>
      <Field label="IMDb ID" name="imdb_id" type="text" />
      {errors.imdbId && <p>{errors.imdbId.message}</p>}
      {isFetching && <p>Loading...</p>}
      {error && <p>Error fetching data.</p>}
      {data && (
        <>
          <MediaOptions
            movieResults={data?.movie_results}
            tvResults={data?.tv_results}
          />
        </>
      )}
    </Box>
  );
};

export default ImdbSearchField;

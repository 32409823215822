import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../auth/security";

export const turk = createApi({
  reducerPath: "turk",
  tagTypes: ["turk"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) {
        headers.set("Authorization", `Bearer ${access_token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // make a subject
    makeHIT: builder.mutation({
      query: (data) => ({
        url: "/turk/hit/create",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Hits"],
    }),
    getTurkData: builder.query({
      query: (value) => ({
        url: `/turk/${value}`,
        method: "GET",
      }),
      providesTags: ["Hits"],
    }),
    getTurkStage: builder.query({
      query: (value) => ({
        url: `/turk/stage`,
        method: "GET",
      }),
      providesTags: ["Hits"],
    }),
    getHitInfo: builder.query({
      query: ({ id, type }) => ({
        url: `/turk/hit/${type}/${id}`,
        method: "GET",
      }),
    }),
    approveAssignment: builder.mutation({
      query: (data) => ({
        url: `/turk/approveAssigment`,
        method: "POST",
        body: data,
      }),
    }),
    rejectAssignment: builder.mutation({
      query: (data) => ({
        url: `/turk/rejectAssignment`,
        method: "POST",
        body: data,
      }),
    }),
    approveAllAssigments: builder.mutation({
      query: (id) => ({
        url: `/turk/hit/approveAssigments/${id}`,
        method: "POST",
        body: { data: id },
      }),
    }),
    getHitAssingments: builder.query({
      query: (id) => ({
        url: `/turk/hit/assignments/${id}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response.Assignments,
    }),
  }),
});
// export elements
export const {
  useGetTurkStageQuery,
  useGetHitAssingmentsQuery,
  useRejectAssignmentMutation,
  useGetHitInfoQuery,
  useMakeHITMutation,
  useApproveAssignmentMutation,
  useGetTurkDataQuery,
  useApproveAllAssigmentsMutation,
} = turk;

const assignLetterGrade = (score) => {
  if (score >= 0.9) {
    return "A";
  } else if (score >= 0.8) {
    return "B";
  } else if (score >= 0.7) {
    return "C";
  } else if (score >= 0.6) {
    return "D";
  } else {
    return "F";
  }
};

const assignPlusMinus = (score) => {
  if (score >= 0.9) {
    return "-";
  } else if (score >= 0.7) {
    return "+";
  } else {
    return "";
  }
};

export const assignGrade = (score) => {
  if (score < 0 || score > 1) {
    return "NaN";
  }

  const letterGrade = assignLetterGrade(score);
  const plusMinus = assignPlusMinus(score);

  return letterGrade + plusMinus;
};

export const round = (number, decimals) => {
  if (isNaN(number) || isNaN(decimals)) {
    return 0;
  }

  const multiplier = Math.pow(10, decimals);
  const roundedNumber = Math.round(number * multiplier) / multiplier;

  return roundedNumber;
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Flex,
  useDisclosure,
  Collapse,
  VStack,
  Text,
  HStack,
  IconButton,
  Slider,
  SliderTrack,
  SliderThumb,
  SliderFilledTrack,
  Textarea,
} from "@chakra-ui/react";
import {
  FiBookmark,
  FiChevronLeft,
  FiChevronRight,
  FiEdit,
  FiEdit2,
  FiInfo,
  FiPlus,
  FiTrash,
} from "react-icons/fi";

import { RiHomeFill } from "react-icons/ri";
import {
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from "@chakra-ui/react";
import ReactPlayer from "react-player";
import { MdMovie } from "react-icons/md";
import {
  Banner,
  BannerContent,
  BannerDescription,
  BannerIcon,
  BannerTitle,
  ChevronDownIcon,
  Controller,
  useFieldArray,
  useFormContext,
} from "@saas-ui/react";
import { BsPentagonFill } from "react-icons/bs";
import TimeIndicator from "../util/TimeIndicator";
import debounce from "lodash.debounce";
import { duration } from "moment";

const SceneUpdate = ({ index, fields, update, remove, duration }) => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });

  const handleChange = (field, value) => {
    update(index, { ...fields[index], [field]: value });
  };

  const handleSave = () => {
    onToggle();
  };
  const start_ts = new Date(parseInt(fields[index]?.range[0] * 1000))
    .toISOString()
    .substr(14, 5);

  const end_ts = new Date(parseInt(fields[index]?.range[1] * 1000))
    .toISOString()
    .substr(14, 5);

  return (
    <Box
      align="start"
      width="100%"
      p="2"
      justifyContent="space-between"
      borderBottom={"1px solid"}
      borderColor={"gray.200"}
    >
      <HStack justifyContent="space-between" onClick={onToggle}>
        <HStack fontSize="xs">
          <Text>{index + 1}</Text>
          <Text noOfLines={4}>{fields[index]?.name}</Text>
          <Text>{`(${start_ts} - ${end_ts})`}</Text>
        </HStack>
        <HStack>
          <IconButton
            variant="ghost"
            onClick={onToggle}
            icon={<FiEdit2 />}
            size="xs"
          />
          <IconButton
            variant="ghost"
            size="xs"
            onClick={() => remove(index)}
            icon={<FiTrash />}
          />
        </HStack>
      </HStack>

      <Collapse in={isOpen} animateOpacity width="100%">
        <Box>
          <FormControl>
            <FormLabel>Scene Name</FormLabel>
            <Input
              size={"xs"}
              value={fields[index]?.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </FormControl>

          <Button mt={4} colorScheme="green" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Collapse>
    </Box>
  );
};

const SceneItem = ({ index, fields, update, duration, currentTime }) => {
  const handleChange = (field, value) => {
    update(index, { ...fields[index], [field]: value });
  };

  return (
    <HStack width="100%" mt={3}>
      <Box width={"100%"}>
        <RangeSlider
          width="100%"
          min={0}
          max={duration}
          step={0.2}
          onChange={(value) => handleChange("range", value)}
          values={fields[index]?.range}
          defaultValue={fields[index]?.range}
          aria-label={["Start time", "End time"]}
        >
          <RangeSliderTrack height="30px" bg="white" borderRadius="md">
            <RangeSliderFilledTrack bg="red.100" borderRadius="md">
              <HStack
                ml="2"
                spacing={2}
                fontSize={"xs"}
                justifyContent={"start"}
                height={"100%"}
                color={"red.600"}
                fontWeight={"semibold"}
              >
                <Box p="1" bg="white" borderRadius="full">
                  <MdMovie />
                </Box>
                <Text noOfLines={1}>{fields[index]?.name}</Text>
              </HStack>
            </RangeSliderFilledTrack>
          </RangeSliderTrack>
        </RangeSlider>
      </Box>
    </HStack>
  );
};

const VideoLabeler = ({ videoId }) => {
  const [duration, setDuration] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [currentTime, setCurrentTime] = useState(0);

  const playerRef = useRef(null);

  const { watch, setValue, control, register } = useFormContext();
  register("duration");

  const { fields, append, update, remove } = useFieldArray({
    name: "scenes",
    control,
  });

  const video_id = watch("yotube_video_id");
  const video = watch("video");
  const media_type = watch("media_type");

  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds);
  };

  const handleDuration = (dur) => {
    setDuration(dur);
  };

  useEffect(() => {
    setValue("duration", duration);
  }, [duration]);

  useEffect(() => {
    if (
      video &&
      (media_type === "custom_media" || media_type === "imdb_media")
    ) {
      setVideoUrl(URL.createObjectURL(video));
    } else if (video_id && media_type !== "custom_media") {
      setVideoUrl(
        `https://www.youtube.com/watch?v=${video_id?.value || video_id}`
      );
    }
  }, [video, videoId]);

  const debouncedSeek = useCallback(
    debounce((val) => {
      if (playerRef.current) {
        playerRef.current.seekTo(val, "seconds");
      }
    }, 100), // 250 milliseconds delay
    [] // dependencies array, it's empty because debounce function doesn't need to change
  );

  const handleSliderChange = (val) => {
    setCurrentTime(val);
    debouncedSeek(val);
  };

  return (
    <Box width="100%">
      <Banner my="2" borderRadius="md">
        <BannerIcon>
          <FiInfo />
        </BannerIcon>
        <BannerTitle>Why label scenes?</BannerTitle>

        <BannerContent fontSize="sm">
          Labeled scenes will allow you to ask questions about the scenes in
          surveys and will provide you with insights specific to each scene.
        </BannerContent>
      </Banner>
      <HStack spacing="3" height="100%" alignItems="top">
        <Box flex={1} bg="white" shadow="md" borderRadius="md">
          <HStack
            justifyContent={"space-between"}
            p={"3"}
            borderBottom={"1px solid"}
            borderColor={"gray.200"}
          >
            <Text fontSize="sm" fontWeight="bold">
              Scenes
            </Text>
            <Button
              onClick={() => {
                append({
                  name: "",
                  description: "",
                  range: [currentTime, currentTime + 10],
                });
              }}
              leftIcon={<FiPlus />}
              aria-label="Add Scene"
              size="xs"
              variant={"outline"}
            >
              Add Scene
            </Button>
          </HStack>
          {fields.map((scene, index) => (
            <SceneUpdate
              key={`scene-update-${index}`}
              index={index}
              fields={fields}
              update={update}
              remove={remove}
              duration={duration}
            />
          ))}
        </Box>

        <Box
          flex="2"
          bg="white"
          shadow="md"
          borderRadius="xl"
          overflow={"hidden"}
        >
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            onDuration={handleDuration}
            onProgress={handleProgress}
            controls
            volume={0}
            width="100%"
          />
        </Box>
      </HStack>

      <Box
        marginY="4"
        spacing={0}
        bg="white"
        borderRadius="md"
        borderWidth="1px"
        borderColor="gray.200"
        shadow={"sm"}
        maxH="500px"
        overflowY="scroll"
        overflowX="hidden"
      >
        <Box width="100%" position={"relative"} pb="5">
          <TimeIndicator duration={duration} />
          <Box>
            <Slider
              aria-label="video-timeline"
              min={0}
              my="0"
              m="0"
              max={duration}
              value={currentTime}
              onChange={handleSliderChange}
              width="100%"
            >
              <SliderTrack h={"2px"}>
                <SliderFilledTrack bg="red.400" />
              </SliderTrack>
              <SliderThumb
                color="red.400"
                as={RiHomeFill}
                borderRadius="none"
                width="20px"
                height="20px"
                shadow="none"
                zIndex={3}
              />
            </Slider>
          </Box>
          <Box
            position="absolute"
            left={`calc(${(currentTime / duration) * 100}% + 10px)`} // Assuming the boxSize is in pixels and `5px` wide
            top="0"
            bottom="0"
            width="1px"
            bg="red.400"
            zIndex="2"
            height="100%"
          />

          {fields.map((scene, index) => (
            <SceneItem
              key={index}
              index={index}
              update={update}
              duration={duration}
              fields={fields}
              currentTime={currentTime}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
export default VideoLabeler;

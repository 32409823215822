import { useAuth0 } from "@auth0/auth0-react";
import { useGetPermissionsQuery } from "../store/services/appAPI";
import { useEffect, useState } from "react";

const useUserHasRoles = (requiredRoles = []) => {
  const { isLoading: userAuthLoading, user, logout } = useAuth0();
  const { data: roles, isLoading: rolesLoading } = useGetPermissionsQuery({
    skip: userAuthLoading,
  });

  const [isValidUser, setIsValidUser] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userAuthLoading || rolesLoading) {
      setIsLoading(true);
    } else if (!requiredRoles.length) {
      // Check if user has all the required roles
      setIsValidUser(true);
      setIsLoading(false);
    } else if (roles) {
      const hasRoles = requiredRoles?.some((role) =>
        roles?.roles?.includes(role)
      );
      setIsValidUser(hasRoles);
      setIsLoading(false);
    }
  }, [userAuthLoading, rolesLoading, roles, requiredRoles]);

  return { isValidUser, isLoading, roles, user, logout };
};

export default useUserHasRoles;

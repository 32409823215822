import { Auth0Provider } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { H } from "highlight.run";
import { useAuth0 } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children, ...props }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://dashboard-api",
        scope: "openid profile email",
      }}
      useRefreshTokens={true}
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

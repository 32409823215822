import { HStack, IconButton, Text } from "@chakra-ui/react";
import { Button } from "@saas-ui/react";

import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";

const PAGE_COUNTS = [15, 25, 30, 40, 50];

export const MiniPaginator = ({ table }) => (
  <HStack>
    <IconButton
      size="xs"
      variant="outline"
      onClick={() => table.previousPage()}
      disabled={!table.getCanPreviousPage()}
    >
      <FiChevronLeft />
    </IconButton>

    <IconButton
      size="xs"
      variant="outline"
      onClick={() => table.nextPage()}
      disabled={!table.getCanNextPage()}
    >
      <FiChevronRight />
    </IconButton>
  </HStack>
);

export const Paginator = ({ table }) => (
  <HStack
    justifyContent="space-between"
    p="3"
    borderTop="1px"
    borderColor="gray.200"
    width="100%"
    height="50px"
    flex="0"
    display="flex"
  >
    <HStack>
      <IconButton
        size="xs"
        variant="outline"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        <FiChevronsLeft />
      </IconButton>
      <IconButton
        size="xs"
        variant="outline"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <FiChevronLeft />
      </IconButton>
    </HStack>
    <HStack>
      <Text fontSize="sm">
        Page {table.getState().pagination.pageIndex + 1} of{" "}
        {table.getPageCount()}
      </Text>

      <select
        value={table.getState().pagination.pageSize}
        onChange={(e) => {
          table.setPageSize(Number(e.target.value));
        }}
      >
        {PAGE_COUNTS.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </HStack>
    <HStack>
      <IconButton
        size="xs"
        variant="outline"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <FiChevronRight />
      </IconButton>
      <IconButton
        size="xs"
        variant="outline"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        <FiChevronsRight />
      </IconButton>
    </HStack>
  </HStack>
);

import {
  Text,
  Box,
  Card,
  Flex,
  SimpleGrid,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { useGetMediaSurveysQuery } from "../../../store/services/mediaApi";
import { useParams } from "react-router";
import { NProgress, Select, SelectButton, SelectList } from "@saas-ui/react";

import { useEffect, useState } from "react";

import OpenEnded from "../../surveys/OpenEnded.js";
import MultiPartQuestion from "../../surveys/MultiPart.js";
import QuestionDisplay from "../../surveys/QuestionDisplay.js";

const Surveys = ({ mediaItemData }) => {
  const { id } = useParams();
  const { data, isLoading } = useGetMediaSurveysQuery(id);
  const [selectedSurvey, setSelectedSurvey] = useState({});

  useEffect(() => {
    if (data) {
      setSelectedSurvey(data?.surveys[0]);
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NProgress colorScheme="red" isAnimating position="absolute" />
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Flex width="100%" flexDirection="column">
          <HStack width="100%" justifyContent="space-between">
            <Text>Survey Results</Text>
            <Box>
              <Select
                name="country"
                value={selectedSurvey}
                onChange={(i) => setSelectedSurvey(i)}
                options={data?.surveys?.map((ithSurvey) => ({
                  value: ithSurvey,
                  label: ithSurvey,
                }))}
              >
                <SelectButton />
                <SelectList />
              </Select>
            </Box>
          </HStack>

          <SimpleGrid columns={2} gap={5} mt={4}>
            {data?.[selectedSurvey]?.questions?.map((ithQuestion, idx) => (
              <>
                {data?.[selectedSurvey]?.[ithQuestion]?.type !== "multipart" ? (
                  <QuestionDisplay
                    questionData={data?.[selectedSurvey]?.[ithQuestion]}
                    ithQuestion={ithQuestion}
                    key={idx}
                    idx={idx}
                  />
                ) : (
                  <>
                    <MultiPartQuestion
                      questionData={data?.[selectedSurvey]?.[ithQuestion]}
                      questions={data?.[selectedSurvey]?.[ithQuestion]}
                      ithQuestion={ithQuestion}
                    />
                  </>
                )}
              </>
            ))}
            {selectedSurvey === "Open Ended" && (
              <>
                {Object.entries(data?.[selectedSurvey] || {}).map(
                  ([question, answers]) => (
                    <Card key={question} p="3">
                      <OpenEnded
                        question={question}
                        answers={answers}
                        mediaItemdId={id}
                        mediaItemData={mediaItemData}
                      />
                    </Card>
                  )
                )}
              </>
            )}
          </SimpleGrid>
        </Flex>
      )}
    </>
  );
};
export default Surveys;

import { Box, Text } from "@chakra-ui/react";
import { Field, FormLayout } from "@saas-ui/react";

const CampaignDetails = () => {
  return (
    <Box
      p="4"
      bg="white"
      borderRadius="md"
      border="1px solid"
      borderColor="gray.200"
      boxShadow="sm"
      my="4"
    >
      <Text fontWeight="bold">Campaign Details</Text>
      <FormLayout>
        <Field
          label="Target Number of Respondents"
          name="n_respondents"
          type="number"
          isRequired
        />

        <Field
          type="select"
          name="respondent_target"
          label="Target Audience"
          isRequired
          options={[{ value: "census", label: "USA Census" }]}
        />

        <Field
          type="checkbox"
          label="Require NDA for protected content"
          name="protected_content"
        />

        <Field
          type="checkbox"
          label="Get user content preferences"
          name="user_preferences"
          isDisabled={true}
        />
      </FormLayout>
    </Box>
  );
};
export default CampaignDetails;


import {
  Box,
  Grid,
  GridItem,
  HStack,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import { useParams } from "react-router";

import { useGetCompositeSentimentQuery } from "../../store/services/mediaApi";
import { Comments, Interest } from "./views/Comments";
import { useNormSelector } from "./NormContext";
import MetricCard from "./metrics/MetricCard";
import { useMemo } from "react";
import {
  Banner,
  BannerCloseButton,
  BannerDescription,
  BannerTitle,
} from "@saas-ui/react";

const METRIC_KEY_PREFIXES = {
  Ratings: "avg_overall_rating",
  Intent: "intent_ratio",
  Demand: "avg_rating",
};
const MetricDescription = {
  Ratings:
    "We ask users to provide a rating between 1 and 10. This value is normalized across all titles " +
    "in the selected norm between a value of 0 and 100.",
  Intent:
    "This metric shows the balance of interest in an asset by calculating the percentage of users " +
    "that have expressed interest in the asset, and those that have expressed no interest. This " +
    "value is normalized across all titles in the selected norm between a value of 0 and 100.",
  Composite:
    "The overall composite score is a composite of the metrics below. The intent is weighted at (75%) than the overall and demand each at (12.5%).",
  Demand:
    "We ask users to provide a rating between 1 and 5 on their demand preference. This value is normalized across all titles ",
};

const InfoBannerMediaMetric = () => {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Banner
      isOpen={isOpen}
      onClose={onClose}
      w="100%"
      borderRadius={"md"}
      title="Composite Score"
      motionPreset="slideOutBottom"
      fontSize="xs"
    >
      The value in the circle is the percentile (0-100) of the associated
      metric.
      <BannerCloseButton />
    </Banner>
  );
};

export const MediaOverallMetricBase = ({ sentimentComposite, isLoading }) => {
  const compositeScore = useMemo(() => {
    return (
      sentimentComposite?.composite * 100 ||
      sentimentComposite?.avg_overall_rating_percentile * 100 ||
      sentimentComposite?.intent_ratio_percentile * 100 ||
      null
    );
  }, [sentimentComposite]);
  const metricTitle = useMemo(() => {
    if (sentimentComposite?.composite) {
      return "Composite Score";
    } else if (sentimentComposite?.avg_overall_rating_percentile) {
      return "Overall Rating";
    } else if (sentimentComposite?.intent_ratio_percentile) {
      return "Intent";
    }
  }, [sentimentComposite]);

  const metricDescription = useMemo(() => {
    if (sentimentComposite?.composite) {
      return MetricDescription.Composite;
    } else if (sentimentComposite?.avg_overall_rating_percentile) {
      return MetricDescription.Ratings;
    } else if (sentimentComposite?.intent_ratio_percentile) {
      return MetricDescription.Intent;
    } else {
      return "";
    }
  }, [sentimentComposite]);

  const subMetrics = useMemo(() => {
    const metrics = [
      sentimentComposite?.[METRIC_KEY_PREFIXES.Ratings] &&
        sentimentComposite?.composite && {
          name: "Overall Rating",
          value: sentimentComposite?.avg_overall_rating_percentile * 100,
          rawValue: sentimentComposite?.avg_overall_rating,
          description: MetricDescription.Ratings,
        },
      sentimentComposite?.[METRIC_KEY_PREFIXES.Demand] &&
        sentimentComposite?.composite && {
          name: "Demand",
          value: sentimentComposite?.avg_rating_percentile * 100,
          rawValue: sentimentComposite?.avg_rating,
          description: MetricDescription.Demand,
        },
      sentimentComposite?.[METRIC_KEY_PREFIXES.Intent] &&
        sentimentComposite?.composite && {
          name: "Intent",
          value: sentimentComposite?.intent_ratio_percentile * 100,
          description: MetricDescription.Intent,
        },
    ];
    return metrics.filter(Boolean);
  }, [sentimentComposite]);

  return (
    <MetricCard
      metricTitle={metricTitle}
      metricValue={compositeScore}
      metricDescription={metricDescription}
      isLoading={isLoading}
      subMetrics={subMetrics}
      InfoBanner={InfoBannerMediaMetric}
    />
  );
};

const MediaOverallMetric = () => {
  const { id } = useParams();
  const { selectedNorm } = useNormSelector();

  const { data: sentimentComposite, isLoading: sentimentLoading } =
    useGetCompositeSentimentQuery({ value: id, norm: selectedNorm });

  return (
    <Box mt="3" mb="5" w="100%">
      <MediaOverallMetricBase
        sentimentComposite={sentimentComposite}
        isLoading={sentimentLoading}
      />
    </Box>
  );
};

const MediaItemCards = ({ mediaItemData }) => {
  return (
    <Grid templateColumns="repeat(8, 1fr)" gap={2}>
      <GridItem colSpan={5}>
        <Comments />
        <HStack colSpan={2}></HStack>
      </GridItem>

      <GridItem colSpan={3}>
        <VStack>
          <MediaOverallMetric />
          <Interest />
        </VStack>
      </GridItem>
    </Grid>
  );
};

export default MediaItemCards;

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Flex,
  Box,
  Tabs,
} from "@chakra-ui/react";
import { ErrorBoundary } from "@saas-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

const TabManager = ({ tabs, defaultTab, basePath }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { tab } = useParams();
  const navigate = useNavigate();

  const toggle = (index) => {
    setTabIndex(index);
    navigate({ pathname: `${basePath}${tabs[index]?.value}` });
  };

  useEffect(() => {
    if (tab) {
      const ti = tabs.findIndex((i) => {
        return i.value === tab;
      });
      if (ti > -1) {
        setTabIndex(ti);
      } else {
        navigate({ pathname: `${basePath}${defaultTab}` });
      }
    }
  }, []);

  return (
    <Tabs
      colorScheme="primary"
      isLazy
      onChange={(index) => toggle(index)}
      index={tabIndex}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <TabList px="3">
        {tabs.map((tab, index) => (
          <Tab
            color="gray.500"
            fontWeight="medium"
            key={index}
            _selected={{
              color: "red.500",
              borderBottom: "2px solid",
            }}
          >
            {tab.title}
          </Tab>
        ))}
      </TabList>

      <TabPanels m="0" p="0" flexGrow="1">
        {tabs.map((tab, index) => (
          <TabPanel
            bg="gray.50"
            height="100%"
            m="0"
            key={`${tab.value}-${index}`}
            padding={tab?.padding || "4"}
          >
            <ErrorBoundary>{tab.content}</ErrorBoundary>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default TabManager;

import {
  Text,
  Box,
  Card,
  Heading,
  Flex,
  HStack,
  Tag,
  Spinner,
  CardHeader,
  Select,
  useToken,
  Grid,
  useDisclosure,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import CircularProgressBar from "../../util/CircularProgressBar.tsx";
import {
  useGetAudienceGenreQuery,
  useGetMediaAudienceQuery,
  useGetMediaItemPostersQuery,
  useGetMediaItemRatingsQuery,
  useGetRawAudienceCommentsQuery,
} from "../../../store/services/mediaApi";
import { useNavigate, useParams } from "react-router";
import {
  ErrorBoundary,
  LoadingOverlay,
  LoadingSpinner,
  NProgress,
  Property,
  PropertyList,
} from "@saas-ui/react";
import {
  FiBarChart,
  FiUser,
  FiDollarSign,
  FiChevronDown,
} from "react-icons/fi";

import {
  BsEmojiLaughing,
  BsEmojiAngry,
  BsEmojiExpressionless,
} from "react-icons/bs";

import { PiGenderMaleBold } from "react-icons/pi";
import PercentageIndicator from "../../util/PercentageIndicator.js";
import {
  findObjectWithHighestValue,
  findTopNObjects,
} from "../../../utils/filter";
import { useEffect, useState } from "react";

import HorizontalBar from "../../util/HorizontalSingleBar.js";
import { round } from "../../../utils/letterGrade";

const Posters = ({ mediaItemData }) => {
  const { id } = useParams();
  const { data: posterData, isLoading } = useGetMediaItemPostersQuery(id);

  return (
    <>
      {isLoading ? (
        <Flex
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <NProgress colorScheme="red" isAnimating position="absolute" />
          <Spinner size="lg" />
        </Flex>
      ) : (
        <SimpleGrid columns={4} gap={4}>
          {posterData?.map((ithPoster, idx) => {
            return (
              <Card key={ithPoster?.poster_url} p="5">
                <HStack justifyContent="space-between">
                  <Image
                    src={ithPoster?.poster_url}
                    height="180px"
                    borderRadius="md"
                    boxShadow="md"
                  />
                  <Box width="100%">
                    <Text fontSize="xl" fontWeight="bold" mb="3">
                      Poster {idx + 1}
                    </Text>
                    <Box>
                      <Text fontSize="sm" color="gray.500">
                        Total Interactions
                      </Text>
                      <Text fontWeight="bold">{ithPoster?.total_count}</Text>
                      <Text fontSize="sm" color="gray.500">
                        Overall Rating
                      </Text>

                      <Text fontWeight="bold">
                        {round(ithPoster?.average_rating, 2)}
                      </Text>
                    </Box>
                  </Box>
                </HStack>
              </Card>
            );
          })}
        </SimpleGrid>
      )}
    </>
  );
};
export default Posters;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { sec } from "../../auth/security";

export const appApi = createApi({
  reducerPath: "appApi",
  tagTypes: ["app"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()();
      if (access_token) {
        headers.set("Authorization", `Bearer ${access_token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPermissions: builder.query({
      query: () => ({
        url: `/permission`,
        method: "GET",
      }),
      providesTags: ["Permissions"],
    }),
    inviteUser: builder.mutation({
      query: (data) => ({
        url: `/manage/user`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
// export elements
export const { useGetPermissionsQuery, useInviteUserMutation } = appApi;

import {
  Box,
  Radio,
  RadioGroup,
  Stack,
  forwardRef,
  useRadioGroup,
} from "@chakra-ui/react";
import {
  SearchInput,
  createField,
  useController,
  useFormContext,
} from "@saas-ui/react";
import { Select as ChakraReactSelect } from "chakra-react-select";
import { useMemo, useState } from "react";

// export const DateTimeSelector = ({ props }) => {
//   const { register, setValue } = useFormContext();
//   return <DateTimeInput {...register("date_enrolled")} />;
// };

export const ReactMultiSelect = createField(ChakraReactSelect, {
  isControlled: true,
});

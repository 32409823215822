import { HStack, Text, Tag } from "@chakra-ui/react";
import {
  FiArrowDownLeft,
  FiArrowUpRight,
  FiBarChart,
  FiUser,
  FiDollarSign,
} from "react-icons/fi";

const PercentageIndicator = ({
  number,
  text = "",
  type = "simple",
  pt = "3",
  color = { positve: "green.500", negative: "red.500" },
}) => {
  const roundedAmount = Math.round(number * 100) / 100;

  return type === "simple" ? (
    <HStack
      color={roundedAmount > 0 ? color.positve : color.negative}
      fontSize="sm"
      pt={pt}
    >
      {roundedAmount > 0 ? <FiArrowUpRight /> : <FiArrowDownLeft />}
      <Text>
        {roundedAmount}% {text}
      </Text>
    </HStack>
  ) : (
    <Tag m="1">
      <HStack>
        <Text>{text}</Text>
        <Text color={roundedAmount > 0 ? color.positve : color.negative}>
          {roundedAmount}%
        </Text>
      </HStack>
    </Tag>
  );
};

export default PercentageIndicator;

import {
  Box,
  Card,
  Heading,
  SimpleGrid,
  HStack,
  CardHeader,
  CardBody,
  Text,
  useToken,
} from "@chakra-ui/react";
import { useGetMediaEmotionQuery } from "../../../store/services/mediaApi.js";
import { useParams } from "react-router";
import {
  LoadingOverlay,
  LoadingSpinner,
  Property,
  PropertyLabel,
  PropertyList,
  PropertyValue,
} from "@saas-ui/react";

import PercentageIndicator from "../../util/PercentageIndicator.js";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  PolarRadiusAxis,
  Legend,
  Cell,
  Label,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";

import FeatureFlag from "../../util/FeatureFlag.js";
import { useNormSelector } from "../NormContext.js";
import { round } from "../../../utils/letterGrade.js";

export const EMOTION_COLOR_MAP = {
  Excited: "#264653",
  Happy: "#2a9d8f",
  Surprised: "#e9c46a",
  Angry: "#f4a261",
  Sad: "#e76f51",
  Scared: "black",
};

const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="15"
      >
        {labelText}
      </text>
      <text
        x={cx}
        y={cy + 20}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#0088FE"
        fontSize="26"
        fontWeight="600"
      >
        {value}
      </text>
    </g>
  );
};
export const NetEmotions = ({ data }) => {
  const barData = data?.data?.map((ithData) => ({
    label: ithData.value,
    count: ithData.overall.count,
    value: ithData.overall.percentage_of_emotion,
  }));

  return (
    <Box>
      <Box height="300px" width="300px">
        <ResponsiveContainer>
          <PieChart width={730} height={250}>
            <Tooltip />
            <Pie
              data={barData}
              dataKey="count"
              nameKey="label"
              innerRadius={80}
              outerRadius={100}
            >
              {barData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={EMOTION_COLOR_MAP?.[entry?.label]}
                />
              ))}
              <Label
                content={
                  <CustomLabel
                    labelText="Total Reactions"
                    value={data?.total_emotions}
                  />
                }
                position="center"
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </Box>
      <Box p="4">
        {barData?.map((ithValue, index) => (
          <Box key={`legent-${ithValue}-${index}`}>
            <HStack fontSize="xs" color="gray.600">
              <Box
                borderRadius="full"
                height="2"
                width="2"
                bg={EMOTION_COLOR_MAP?.[ithValue?.label]}
              ></Box>
              <Text>
                {ithValue?.label} {round(ithValue?.value, 2)}%
              </Text>
            </HStack>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export const EmotionChart = ({
  data,
  title,
  isLoading,
  height = "300px",
  width = "500px",
}) => {
  return (
    <Box height={height} width={width} margin={4}>
      {isLoading ? (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      ) : (
        <ResponsiveContainer>
          <RadarChart
            outerRadius={100}
            data={data
              ?.map((ithData) => ({
                subject: ithData?.value,
                global: ithData?.overall?.global_percentage_of_emotion,
                emotion: ithData?.overall?.percentage_of_emotion,
                fullMark: 100,
              }))
              ?.sort((a, b) => a.subject.localeCompare(b.subject))}
          >
            <PolarGrid />
            <PolarAngleAxis dataKey="subject" />
            <PolarRadiusAxis angle={30} />
            <Radar
              name="Volkno"
              dataKey="global"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.6}
            />
            <Radar name={title} dataKey="emotion" fillOpacity={0.6} />

            <Legend />
          </RadarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export const TotalEmotionality = ({ mediaItemData }) => {
  const { id } = useParams();
  const { selectedNorm } = useNormSelector();

  const { data, isLoading } = useGetMediaEmotionQuery({
    value: id,
    norm: selectedNorm,
  });

  return (
    <Card>
      <CardHeader borderBottom="1px solid" borderColor="gray.200">
        <HStack justifyContent="space-between">
          <Heading size="md">Total Emotionality</Heading>
          <FeatureFlag description="Shows the breakdown of all emotions that are expressed over the course of the video. Percentages describe the % that an emotion over-indexes compared to the VolKno average." />
        </HStack>
      </CardHeader>

      <CardBody>
        <PropertyList>
          <SimpleGrid columns={2} autoRows>
            <Box>
              {data?.data?.map(
                (percentIth) =>
                  ["Happy", "Excited", "Surprised"].includes(
                    percentIth.value
                  ) && (
                    <Property key={`propert-${percentIth.value}`}>
                      <PropertyLabel fontSize="xs">
                        {percentIth.value}
                      </PropertyLabel>
                      <PropertyValue>
                        <PercentageIndicator
                          number={
                            selectedNorm === "default"
                              ? percentIth.overall.emotion_percent_delta
                              : percentIth.overall.emotion_agg_percent_delta
                          }
                        />
                      </PropertyValue>
                    </Property>
                  )
              )}
            </Box>
            <Box>
              {data?.data?.map(
                (percentIth) =>
                  ["Sad", "Angry", "Scared"].includes(percentIth.value) && (
                    <Property key={`propert-${percentIth.value}`}>
                      <PropertyLabel fontSize="xs">
                        {percentIth.value}
                      </PropertyLabel>
                      <PropertyValue>
                        <PercentageIndicator
                          number={
                            selectedNorm === "default"
                              ? percentIth.overall.emotion_percent_delta
                              : percentIth.overall.emotion_agg_percent_delta
                          }
                        />
                      </PropertyValue>
                    </Property>
                  )
              )}
            </Box>
          </SimpleGrid>
        </PropertyList>

        <EmotionChart
          data={data?.data}
          isLoading={isLoading}
          title={mediaItemData?.title}
        />
      </CardBody>
    </Card>
  );
};

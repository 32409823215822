import {
  Box,
  Flex,
  HStack,
  Text,
  Skeleton,
  Image,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import {
  useClearMediaItemCacheMutation,
  useGetMediaItemMetaQuery,
} from "../../store/services/mediaApi";
import { useParams } from "react-router";
import TabManager from "../util/TabManager";
import { GenreTags } from "./utils/tags";
import { FiCalendar, FiRefreshCw } from "react-icons/fi";
import MediaItemCards from "./MediaItemCards";
import moment from "moment";
import Audience, { TotalAudienceCard } from "./views/Audience";
import Creative from "./views/Creative";
import Surveys from "./views/Surveys";
import Video from "./views/Video";
import { TotalEmotionality } from "./views/Emotion";
import { Link } from "react-router-dom";
import {
  ChevronLeftIcon,
  EmptyState,
  ErrorBoundary,
  SelectButton,
  SelectList,
  Select,
  SelectOption,
  Persona,
} from "@saas-ui/react";
import Posters from "./views/Posters";
import { useNormSelector } from "./NormContext";
import Sentiment from "./views/Sentiment";
import OpenEnd from "./views/OpenEnd";
import { OpenEndedSourceProvider } from "./OpenEndedContext";
import { useAuth0 } from "@auth0/auth0-react";

const MediaItemHeader = ({ data, isLoading }) => {
  const { client, id } = useParams();
  const {
    options,
    setselectedNorm,
    selectedNorm,
    isLoading: normLoading,
  } = useNormSelector();

  const [clearMediaCache, { isLoading: cacheLoading }] =
    useClearMediaItemCacheMutation();

  return isLoading ? (
    <Box width="100%">
      <HStack
        justifyContent="space-between"
        p="3"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Link to={`/${client}/media`}>
          <HStack fontSize="xs">
            <ChevronLeftIcon />
            <Text>Back</Text>
          </HStack>
        </Link>
      </HStack>
      <HStack p="3" width="100%" justifyContent="space-between">
        <HStack>
          <Skeleton width="40px" height="60px" borderRadius="10" />
          <Box>
            <Skeleton width="80px" height="15px" />
            <HStack mt="3">
              <Skeleton width="20px" height="10px" />
              <Skeleton width="20px" height="10px" />
            </HStack>
            <HStack mt="3">
              <Skeleton width="20px" height="10px" />
              <Skeleton width="20px" height="10px" />
              <Skeleton width="20px" height="10px" />
            </HStack>
          </Box>
        </HStack>
      </HStack>
    </Box>
  ) : (
    <Box width="100%">
      <HStack
        justifyContent="space-between"
        p="2"
        borderBottom="1px solid"
        borderColor="gray.200"
      >
        <Link to={`/${client}/media`}>
          <HStack fontSize="xs">
            <ChevronLeftIcon />
            <Text>Back</Text>
          </HStack>
        </Link>

        <Tooltip label="Refresh Cache">
          <IconButton
            size="xs"
            icon={<FiRefreshCw />}
            variant={"ghost"}
            isLoading={cacheLoading}
            onClick={() => clearMediaCache(id)}
          />
        </Tooltip>
      </HStack>
      <HStack p="3" justifyContent="space-between">
        <Box>
          <HStack>
            <Image height="20" borderRadius="10" src={data?.posters[0]} />
            <Box>
              <Text fontWeight="bold">{data?.title}</Text>
              <HStack fontSize="sm" color="gray.600" mb="2">
                <HStack>
                  <FiCalendar />
                  <Text>
                    Added on {moment(data?.date_added).format("MMM D, YYYY")}
                  </Text>
                </HStack>
              </HStack>
              <Box>
                {data?.genres?.map((ithGenre, idx) => (
                  <GenreTags idx={idx} genre={ithGenre} key={ithGenre} />
                ))}
              </Box>
            </Box>
          </HStack>
        </Box>

        <Box>
          {normLoading ? (
            <Skeleton height="40px" width="100%" />
          ) : (
            <>
              <Text fontWeight="semibold" fontSize={"sm"} mb="1">
                Norm
              </Text>
              <Select onChange={(i) => setselectedNorm(i)} value={selectedNorm}>
                <SelectList maxWidth={"300px"}>
                  {options?.map((i) => (
                    <SelectOption key={i?.norm_id} value={i?.norm_id}>
                      <HStack>
                        <Persona size="xs" name={i?.norm_id} hideDetails />
                        <Box>
                          <Text fontSize="sm" fontWeight="semibold">
                            {i?.norm_id}
                          </Text>
                          <Text
                            fontSize={"xs"}
                            color={"gray.600"}
                            noOfLines={2}
                          >
                            {i?.norm_description}
                          </Text>
                        </Box>
                      </HStack>
                    </SelectOption>
                  ))}
                </SelectList>
                <SelectButton />
              </Select>
            </>
          )}
        </Box>
      </HStack>
    </Box>
  );
};

const MediaItem = () => {
  const { id, client } = useParams();
  const { isLoading: userLoading } = useAuth0();

  const { data: mediaItemData, isLoading: isMediaLoading } =
    useGetMediaItemMetaQuery(id, {
      skip: userLoading,
    });

  const tabs = [
    {
      value: `overview`,
      title: "Overview",
      content: (
        <Flex direction="column" gap="4" maxWidth="100vw">
          <MediaItemCards mediaItemData={mediaItemData} />
          <Flex gap="4">
            <TotalEmotionality mediaItemData={mediaItemData} />
            <TotalAudienceCard mediaItemData={mediaItemData} />
          </Flex>
        </Flex>
      ),
    },
    {
      value: `audience`,
      title: "Audience",
      content: <Audience mediaItemData={mediaItemData} />,
    },
    {
      value: `creative`,
      title: "Creative",
      content: <Creative mediaItemData={mediaItemData} />,
    },
    {
      value: `survey`,
      title: "Surveys",
      content: <Surveys mediaItemData={mediaItemData} />,
    },
    {
      value: `posters`,
      title: "Key Art",
      content: <Posters mediaItemData={mediaItemData} />,
    },
    {
      value: `video`,
      title: "Video",
      content: <Video mediaItemData={mediaItemData} />,
    },
    {
      value: `sentiment`,
      title: "Sentiment",
      content: <Sentiment mediaItemData={mediaItemData} />,
    },
    {
      value: `open-ended`,
      title: "Open Ended",
      content: <OpenEnd mediaItemData={mediaItemData} />,
      padding: "0 !important",
    },
  ];

  return (
    <Flex flexDirection="row" minHeight="100vh">
      <Flex flex="3" direction="column">
        <Flex alignItems="center">
          <MediaItemHeader isLoading={isMediaLoading} data={mediaItemData} />
        </Flex>
        <ErrorBoundary
          fallback={<EmptyState title="There seems to be an error here" />}
        >
          <OpenEndedSourceProvider id={id}>
            <TabManager
              tabs={tabs}
              defaultTab={tabs[0]?.value}
              basePath={`/${client}/media/${id}/`}
            />
          </OpenEndedSourceProvider>
        </ErrorBoundary>
      </Flex>
    </Flex>
  );
};

export default MediaItem;

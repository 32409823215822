import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Skeleton,
  VStack,
  Text,
} from "@chakra-ui/react";
import {
  MetricLister,
  MetricBar,
  MetricProperty,
  MetricCircle,
} from "./MetricComponents";
import FeatureFlag from "../../util/FeatureFlag";

const MetricCard = ({
  metricTitle,
  metricValue,
  metricDescription,
  isLoading,
  subMetrics = [],
  subMetricsTitle = "Sub Metrics",
  ...props
}) => {
  return (
    <Card {...props}>
      <CardHeader>
        <VStack align={"start"} w="100%">
          <Skeleton isLoaded={!isLoading} width="100%">
            <HStack>
              <Heading size="md">{metricTitle}</Heading>
              <MetricCircle
                value={metricValue}
                description={metricDescription}
              />
              {metricDescription && (
                <FeatureFlag
                  description={metricDescription}
                  beta={props?.beta}
                />
              )}
            </HStack>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} width="100%">
            {metricValue ? (
              <MetricBar value={metricValue} description={metricDescription} />
            ) : (
              <Text color="gray.500" fontSize="sm">
                Not enough data available for {metricTitle || "metrics."}
              </Text>
            )}
          </Skeleton>
        </VStack>
      </CardHeader>
      {!!subMetrics?.length && !isLoading && (
        <CardBody>
          <MetricLister name={subMetricsTitle}>
            {subMetrics?.map((ithMetric) => (
              <MetricProperty
                key={`${ithMetric?.name}-key-${metricTitle}`}
                {...ithMetric}
              />
            ))}
          </MetricLister>
          {props?.InfoBanner && (
            <Box mt="4">
              <props.InfoBanner />
            </Box>
          )}
        </CardBody>
      )}
    </Card>
  );
};

export default MetricCard;

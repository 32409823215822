import { Box, Tooltip, HStack, Tag } from "@chakra-ui/react";
import { FiHelpCircle } from "react-icons/fi";

const FeatureFlag = ({ beta, description }) => {
  return (
    <HStack spacing={2}>
      {beta && (
        <Tag
          color="orange.500"
          bg="orange.100"
          borderRadius="md"
          px={2}
          py={0.5}
          border="1px solid"
          borderColor="orange.200"
          size="xs"
          fontSize="xs"
        >
          Beta
        </Tag>
      )}
      <Tooltip label={description} hasArrow>
        <Box p={2} _hover={{ bg: "gray.200", cursor: "help" }}>
          <FiHelpCircle />
        </Box>
      </Tooltip>
    </HStack>
  );
};

export default FeatureFlag;
